export const getProjectAttributes = (data: any[]) => {
  if (!data)
    return {
      attributeNames: [],
      attributeDetails: [],
    };
  const filteredValues = data.filter(
    (item) => item.attribute_value === true && item.attribute.name.toLowerCase() !== 'nature'
  );
  const attributeNames = filteredValues.map((item) => item?.attribute?.display_name || '');
  const attributeDetails = filteredValues.map((item) => ({
    ...item.attribute,
    attribute_extra_content: item.attribute_extra_content,
  }));

  return {
    attributeNames,
    attributeDetails,
  };
};

export const formatBigNumber = (value) => {
  if (isNaN(value)) {
    return '-';
  }
  const number = Number(value);
  if (number < 1000) {
    return number.toLocaleString();
  } else if (number >= 1000 && number < 1000000) {
    return (number / 1000).toFixed(1) + 'K';
  } else if (number >= 1000000 && number < 1000000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else {
    return (number / 1000000000).toFixed(1) + 'B';
  }
};

export const formatNumberWithCommas = (number: number) => {
  if (isNaN(number)) {
    return '-';
  }
  return number.toLocaleString();
};
