import { useRecoilValue } from 'recoil';

import { userState } from '../../stores/auth';

export const useFetchUserLevel = () => {
  const currentUserInfo = useRecoilValue(userState);
  let userLevel: 1 | 2 | 3 = 1;
  switch (currentUserInfo?.project_access_type) {
    case 'l1_regular':
      userLevel = 1;
      break;
    case 'l1_demo_limited':
      userLevel = 1;
      break;
    case 'l1_demo_all':
      userLevel = 1;
      break;
    case 'l2_regular':
      userLevel = 2;
      break;
    case 'l2_demo_limited':
      userLevel = 2;
      break;
    case 'l2_demo_all':
      userLevel = 2;
      break;
    case 'l3_regular':
      userLevel = 3;
      break;
    case 'l3_demo_limited':
      userLevel = 3;
      break;
    case 'l3_demo_v2':
      userLevel = 3;
      break;
    case 'l3_demo_all':
      userLevel = 3;
      break;
    case 'l3_south_pole':
      userLevel = 3;
      break;
  }
  return { userLevel };
};
