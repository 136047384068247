import React, { useEffect, useMemo, useState } from 'react';

import { Button, Card, Divider, Grid, List, Stack, Typography } from '@mui/joy';

import { FilterDetails } from '../../utils/hooks/useFilters/types';

import FilterList from './FilterList';
import FilterSelctedCount from './FilterSelctedCount';
import { StyledListItem } from './styles';
import { Section } from './types';

export type FilterPopUpProps = {
  sections: Section[];
  hasReset?: boolean;
  onClose: () => void;
  filterDetails: FilterDetails;
};

const FilterPopUp: React.FC<FilterPopUpProps> = ({
  sections,
  hasReset,
  onClose,
  filterDetails,
}) => {
  const [selectedSection, setSelectedSection] = useState<string>('');

  const {
    updateFilter,
    isSelected,
    isAnyChildFilterChecked,
    clearFilter,
    getFilterValue,
    updateTextFilter,
    setFilters,
    getFilterCount,
    getTotalFilterCount,
  } = filterDetails;

  const sectionKeyMap = useMemo(
    () => sections.reduce((acc, section) => ({ ...acc, [section.name]: section }), {}),
    [sections]
  );

  useEffect(() => {
    setSelectedSection(sections[0].name);
  }, [sections]);

  const handleSelectSection = (key: string) => {
    setSelectedSection(key);
  };

  return (
    <Card
      variant='outlined'
      sx={{
        height: '720px',
        overflow: 'hidden',
        p: 0,
        width: '800px',
        background: (theme) => theme.palette.primary.outlinedColor,
      }}
    >
      <Grid container sx={{ height: 1 }}>
        <Grid xs={4} sx={{ overflow: 'auto', height: 1 }}>
          <List sx={{ p: 0 }}>
            {sections.map(({ display_name, name }, index) => (
              <StyledListItem
                key={name}
                isActive={selectedSection === name}
                onClick={() => handleSelectSection(name)}
                sx={{ borderTopLeftRadius: index === 0 ? (theme) => theme.radius.md : 0 }}
              >
                <Typography level='body-md' sx={{ mr: 'auto' }} noWrap>
                  {display_name}
                </Typography>
                <FilterSelctedCount
                  getFilterCount={getFilterCount}
                  keyName={name}
                  postFix='Selected'
                />
              </StyledListItem>
            ))}
          </List>
        </Grid>
        <Divider orientation='horizontal' sx={{ height: 1, width: '1px' }} />
        <Grid xs={7.9} sx={{ height: 1, pl: 1 }}>
          <Grid container direction={'column'} sx={{ height: 1 }}>
            <Grid sx={{ height: 'calc(100% - 73px)', overflowY: 'auto', pb: 1 }}>
              {sectionKeyMap[selectedSection] && (
                <FilterList
                  sections={sectionKeyMap[selectedSection].filters}
                  parentFilter={sectionKeyMap[selectedSection]}
                  updateFilter={updateFilter}
                  isAnyChildFilterChecked={isAnyChildFilterChecked}
                  clearFilter={clearFilter}
                  isSelected={isSelected}
                  getFilterValue={getFilterValue}
                  updateTextFilter={updateTextFilter}
                  getTotalFilterCount={getTotalFilterCount}
                  onChange={setFilters}
                />
              )}
            </Grid>
            {hasReset && (
              <Grid xs={3}>
                <Divider />
                <Stack
                  direction={'row'}
                  justifyContent={'end'}
                  gap={2}
                  sx={{ p: 2, background: (theme) => theme.palette.background.surface }}
                >
                  <Button
                    variant='outlined'
                    onClick={() => clearFilter()}
                    sx={{ width: '160px', height: '40px' }}
                  >
                    Clear All Filters
                  </Button>
                  <Button variant='solid' onClick={onClose} sx={{ width: '160px', height: '40px' }}>
                    Apply
                  </Button>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default FilterPopUp;
