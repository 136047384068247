import React from 'react';

import { Modal, ModalOverflow, ModalDialog, Typography, ModalClose } from '@mui/joy';
import HubspotForm from 'react-hubspot-form';

import { PORTAL_ID } from '../../commons/constants';
import useThemedFrame from '../../commons/utils/hooks/useThemedFrame';

interface RequestEsrReportProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
}

const FORM_ID = 'fb0cea44-4283-40f0-837a-9df219d2769c';

const RequestEsrReport: React.FC<RequestEsrReportProps> = ({ setIsModalOpen, isModalOpen }) => {
  const { updateIframeTheme } = useThemedFrame();

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <ModalOverflow>
        <ModalDialog layout={'center'} sx={{ width: '55rem' }}>
          <ModalClose sx={{ top: '36px', right: '26px' }} />
          <Typography level='h1'>Request an ESR Report</Typography>
          <Typography level='h4'>Report pricing and timelines</Typography>
          <Typography level='body-md'>
            ESR Reports can be generated for any project that has been rated by Calyx Global. The
            cost is $3,500 per report and can be delivered within 2-4 weeks. Once you place a
            request, we will get back to you with a precise timeframe.
          </Typography>
          <HubspotForm
            region='na1'
            portalId={PORTAL_ID}
            formId={FORM_ID}
            onReady={updateIframeTheme} // Assuming this is the function passed as prop
            loading={<div>Loading...</div>}
          />
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default RequestEsrReport;
