import React from 'react';

import { Grid, Typography, Divider, Card, styled } from '@mui/joy';

import { dateFormat } from '../../../../commons/utils/dateFormat';
import { NormaliseCSSWrapper } from '../../../project/ghg_rating/components/HTMLRenderer';

interface props {
  data: any;
  isLoading: boolean;
}

const Tbody = styled('div')(({ theme }) => ({
  color: theme.vars.palette.text.primary,
  fontFamily: theme?.fontFamily?.body,
  fontSize: theme.vars.fontSize.md,
  fontStyle: 'normal',
  fontWeight: theme.vars.fontWeight.sm,
  lineHeight: theme.vars.lineHeight.md,
  ul: {
    paddingLeft: theme.spacing(4),
    li: {
      listStyle: 'disc',
    },
  },
  '.RichText3-paragraph': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.sm,
    lineHeight: theme.vars.lineHeight.md,
    '& > a': {
      color: theme.vars.palette.text.primary,
      textDecoration: 'underline',
      fontFamily: theme?.fontFamily?.body,
      fontSize: theme.vars.fontSize.md,
      fontStyle: 'normal',
      fontWeight: theme.vars.fontWeight.sm,
      lineHeight: theme.vars.lineHeight.md,
    },
    '& > img': {
      objectFit: 'contain',
      maxWidth: '750px',
      borderRadius: '24px',
    },
  },
  '& > hr': {
    height: '1px',
    width: '100%',
    backgroundColor: '#4d4d4d',
  },
  table: {
    width: '100%',
    td: {
      border: '1px solid #4d4d4d',
      padding: '12px 24px',
      //   paddingRight: '0px',
    },
  },
  '& > img': {
    objectFit: 'contain',
    maxWidth: '750px',
    borderRadius: '24px',
  },
  '& > h5': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.xl,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.xl,
    lineHeight: theme.vars.lineHeight.md,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  '& > p': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.md,
    lineHeight: theme.vars.lineHeight.md,
    marginBottom: theme.spacing(1),
    '& > a': {
      color: theme.vars.palette.text.primary,
      textDecoration: 'underline',
      fontFamily: theme?.fontFamily?.body,
      fontSize: theme.vars.fontSize.md,
      fontStyle: 'normal',
      fontWeight: theme.vars.fontWeight.sm,
      lineHeight: theme.vars.lineHeight.md,
    },
    '& > img': {
      objectFit: 'contain',
      maxWidth: '750px',
      borderRadius: '24px',
    },
    '& > i': {
      fontStyle: 'italic',
    },
  },
  '& > a': {
    color: theme.vars.palette.text.primary,
    textDecoration: 'underline',
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.sm,
    lineHeight: theme.vars.lineHeight.md,
  },
  '& > div': {
    '& > table': {
      ' & > tr': {
        '& > th': {
          padding: theme.spacing(2),
          border: '1px solid #4D4D4D',
        },
        '& > td': {
          padding: theme.spacing(2),
          border: '1px solid #4D4D4D',
        },
      },
    },
  },
  '& > ol': {
    listStyle: 'auto',
    paddingLeft: theme.spacing(4),
  },
}));

const DonnasDescription = ({ data }: props) => {
  return (
    <Card
      sx={{
        padding: 4,
        height: '100%',
        borderRadius: 'sm',
      }}
      variant='outlined'
    >
      <Grid
        xs={12}
        container
        gap={1}
        justifyContent={'flex-start'}
        direction={'column'}
        alignItems={'flex-start'}
        sx={{ height: '100%', minHeight: '100vh', width: '100%' }}
      >
        {data?.map((notes, index: number) => {
          return (
            <React.Fragment key={index}>
              <Grid xs={12} justifyContent={'flex-start'}>
                <Typography level='h2' fontSize={'xl2'}>
                  {dateFormat(notes?.date).mmddyyyy}
                </Typography>
              </Grid>
              <Grid xs={12} marginTop={1} className='donnas-notes-content'>
                <NormaliseCSSWrapper>
                  <Tbody dangerouslySetInnerHTML={{ __html: notes?.content }} />
                </NormaliseCSSWrapper>
              </Grid>
              <Divider
                sx={{
                  marginY: 2,
                }}
              />
            </React.Fragment>
          );
        })}
      </Grid>
    </Card>
  );
};
export default DonnasDescription;
