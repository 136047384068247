import { Box, Divider, styled, Typography } from '@mui/joy';

import Image from '../../../../../commons/components/Image';
import useIsLargeScreen from '../../../../../commons/utils/hooks/useIsLargeScreen';
import { HTMLRenderer } from '../HTMLRenderer';

const CustomImage = styled(Image)(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: '50% 50%',
}));

interface PropTypes {
  src: string;
  alt: string;
  description: string;
}

const ImageContainer = ({ alt, description, src }: PropTypes) => {
  const isLg = useIsLargeScreen();
  return (
    <Box
      display={'grid'}
      gridTemplateColumns={isLg ? '4fr 0fr 3fr' : '1fr'}
      width={'100%'}
      gap={isLg ? 6 : 2}
    >
      <CustomImage src={src} alt={alt} />
      {!!description ? (
        <>
          <Divider orientation='vertical' />
          <Typography fontSize={'md'} color='neutral'>
            <HTMLRenderer colorType='secondary' dangerouslySetInnerHTML={{ __html: description }} />
          </Typography>
        </>
      ) : null}
    </Box>
  );
};

export default ImageContainer;
