import { Card, Skeleton, Stack, Table, Tooltip, Typography, styled } from '@mui/joy';
import { MdLock } from 'react-icons/md';
import { RxLinkBreak2 } from 'react-icons/rx';

import { formatNumberWithCommas } from '../utils/misc';

import { RatingCircle } from './RatingCircle';

type TableConfig = Array<{
  header: string;
  accessorKey: string;
  dataType: 'string' | 'number' | 'ghgRating' | 'sdgRating';
  cellWidth?: string;
  prefixContent?: (data?: any) => React.ReactNode;
  suffixContent?: React.ReactNode;
  considerPermission?: boolean;
}>;

interface PropTypes {
  tableConfig: TableConfig;
  data: Array<any>;
  loading?: boolean;
  onRowClick?: (rowData: any, tableData: any) => void;
}

interface TableHeaderProps {
  cellWidth: string;
}

const TableHeaderItem = styled('th')<TableHeaderProps>(({ cellWidth, theme }) => ({
  background: `${theme.palette.primary.outlinedColor} !important`,
  width: `${cellWidth} !important`,
  borderBottom: `1px solid ${theme.palette.primary.outlinedBorder} !important`,
  color: `${theme.palette.text.primary} !important`,
  fontWeight: `${theme.fontWeight.xl} !important`,
  fontSize: `${theme.fontSize.md} !important`,
  padding: '8px 12px !important',
}));

const TableRow = styled('tr')<{ isClickable: boolean }>(({ isClickable, theme }) => ({
  cursor: isClickable ? 'pointer' : 'default',
  borderBottom: `1px solid ${theme.palette.primary.outlinedBorder} !important`,
}));

const TableDataItem = styled('td')(() => ({
  verticalAlign: 'middle',
}));

const TableDataItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  alignItems: 'center',
}));

const LOCK_TOOLTIP_TEXT = 'Demo: Restricted Access';

const DataTable = ({ data, tableConfig, loading = false, onRowClick }: PropTypes) => {
  return loading ? (
    <Table borderAxis='none'>
      <tbody>
        {Array(5)
          .fill('')
          .map(() => {
            return (
              <tr>
                {tableConfig.map(({ cellWidth }) => {
                  return (
                    <TableHeaderItem cellWidth={cellWidth ?? 'auto'}>
                      <Skeleton animation='wave' variant='text' level='h2' />
                    </TableHeaderItem>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </Table>
  ) : data?.length ? (
    <Table
      borderAxis='xBetween'
      size='md'
      sx={(theme) => {
        return {
          border: `1px solid ${theme.palette.primary.outlinedBorder} !important`,
          borderRadius: '4px',
        };
      }}
    >
      <thead>
        <tr>
          {tableConfig?.map(({ header, cellWidth }) => {
            return <TableHeaderItem cellWidth={cellWidth ?? 'auto'}>{header}</TableHeaderItem>;
          })}
        </tr>
      </thead>
      <tbody>
        {data?.map((item: any, index: number) => {
          return (
            <TableRow
              key={index}
              onClick={onRowClick && item?.hasPermission ? () => onRowClick(item, data) : undefined}
              isClickable={!!onRowClick && item?.hasPermission}
            >
              {tableConfig?.map(
                ({
                  accessorKey,
                  prefixContent,
                  suffixContent,
                  dataType,
                  considerPermission = false,
                }) => {
                  return considerPermission && !item?.hasPermission ? (
                    <TableDataItem>
                      <TableDataItemWrapper>
                        <Tooltip
                          title={LOCK_TOOLTIP_TEXT}
                          variant='solid'
                          placement='top'
                          arrow
                          sx={{ cursor: 'pointer' }}
                        >
                          <Card
                            sx={{
                              padding: '10px',
                              aspectRatio: '1/1',
                              width: '36px',
                              height: '36px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              color='neutral'
                              fontSize={'md'}
                              position={'relative'}
                              top={'2px'}
                            >
                              <MdLock />
                            </Typography>
                          </Card>
                        </Tooltip>
                      </TableDataItemWrapper>
                    </TableDataItem>
                  ) : (
                    <TableDataItem>
                      <TableDataItemWrapper>
                        {prefixContent ? prefixContent(item) : null}
                        {dataType === 'string' ? (
                          <Typography level='body-md' fontSize={'md'} color='primary'>
                            {item?.[accessorKey]}
                          </Typography>
                        ) : null}
                        {dataType === 'number' ? (
                          <Typography level='body-md' fontSize={'md'} color='primary'>
                            {formatNumberWithCommas(Number(item?.[accessorKey]))}
                          </Typography>
                        ) : null}
                        {dataType === 'ghgRating' ? (
                          <RatingCircle
                            type='ghg'
                            rating={item?.[accessorKey]?.grade}
                            percentage={item?.[accessorKey]?.percentage}
                            size='sm'
                          />
                        ) : null}
                        {dataType === 'sdgRating' ? (
                          <RatingCircle
                            type='sdg'
                            rating={item?.[accessorKey]?.grade}
                            percentage={item?.[accessorKey]?.percentage}
                            size='sm'
                          />
                        ) : null}
                        {suffixContent ?? null}
                      </TableDataItemWrapper>
                    </TableDataItem>
                  );
                }
              )}
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  ) : (
    <Stack alignItems={'center'} justifyContent={'center'} spacing={0.5} height={'280px'}>
      <Typography color='neutral' fontWeight={500}>
        <RxLinkBreak2 size={28} />
      </Typography>
      <Typography color='neutral' fontWeight={500}>
        No data found
      </Typography>
    </Stack>
  );
};

export { DataTable };
export type { TableConfig };
