import { useMemo } from 'react';

import { Stack, Typography } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import { RESOURCE_TYPE } from '../../../commons/constants';
import { projectState } from '../../../commons/stores/project';
import ListItem from '../../insights/components/List/Index';

const RelatedContent = () => {
  const { blogs, resources } = useRecoilValue(projectState);
  const items = useMemo(
    () => [
      ...(blogs || []).map((item) => ({
        ...item,
        page: 'blog-detail',
      })),
      ...(resources || []).map((item) => ({
        ...item,
        page: 'resource-detail',
        type: RESOURCE_TYPE,
      })),
    ],
    [blogs, resources]
  );

  if ((items || []).length === 0) return null;

  return (
    <Stack gap={3} alignContent={'flex-start'} sx={{ mx: 'auto', maxWidth: 'lg', pb: 10 }}>
      <Typography level='h2' fontWeight={'xl'}>
        Related Content
      </Typography>
      <ListItem data={items} isLoading={false} page={''} selectedTag={null} />
    </Stack>
  );
};

export default RelatedContent;
