import { useLayoutEffect, useState } from 'react';

import { Button, FormControl, FormLabel, Input, Stack, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { InferType, object, string } from 'yup';

import { ErrorBox, ErrorBoxMessageTypes } from '../../../../commons/components/ErrorBox';
import { PasswordInput } from '../../../../commons/components/PasswordInput';
import { MFA_EMAIL, MFA_NEEDED, MFA_USER_ID } from '../../../../commons/constants/localStorage';
import { authState, redirectionURLState } from '../../../../commons/stores/auth';
import { makePostRequest } from '../../../../services/axios';
import { login } from '../../../../services/axios/endpoints';
import { MFAForm } from '../mfaForm';

const loginFormSchema = object({
  useremail: string().required('Please enter your email and password'),
  password: string().required('Please enter your email and password'),
});

type FormDataTypes = InferType<typeof loginFormSchema>;

const LoginForm = () => {
  const setAuth = useSetRecoilState(authState);
  const redirectionUrl = useRecoilValue(redirectionURLState);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormDataTypes>({ useremail: '', password: '' });
  const [error, setError] = useState<ErrorBoxMessageTypes>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMFA, setShowMFA] = useState<boolean>(false);

  const handleFormDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value?.trim() };
    });
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    try {
      setLoading(true);
      setError(null);
      const payloadData = await loginFormSchema.validate(formData);
      const apiResponse = await makePostRequest(login, payloadData);
      if (apiResponse?.data?.response?.mfa_needed) {
        setShowMFA(true);
        localStorage.setItem(MFA_NEEDED, 'true');
        localStorage.setItem(MFA_EMAIL, payloadData.useremail);
        localStorage.setItem(MFA_USER_ID, `${apiResponse?.data?.response?.user_id}`);
      } else {
        const access_token = apiResponse?.data?.response?.access_token;
        const refresh_token = apiResponse?.data?.response?.refresh_token;
        setAuth({
          access_token,
          refresh_token,
        });
        navigate(redirectionUrl);
      }
    } catch (err: any) {
      setError({
        title: !!err?.code
          ? Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message?.join('\n')
            : err?.response?.data?.message
          : err?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigate('/login/forgot-password');
  };

  useLayoutEffect(() => {
    if (localStorage.getItem(MFA_NEEDED) === 'true') {
      setShowMFA(true);
    }
  }, []);

  const handleShowLoginForm = () => {
    setShowMFA(false);
  };

  return showMFA ? (
    <MFAForm handleShowLoginForm={handleShowLoginForm} />
  ) : (
    <Stack width={'500px'} spacing={3}>
      <Stack>
        <Typography level='h1' fontSize={28} fontWeight={'sm'}>
          Welcome to the Calyx Global Platform
        </Typography>
        <Typography>To get started, please sign in</Typography>
      </Stack>
      <ErrorBox handleClose={handleCloseError} error={error} />
      <FormControl>
        <form onSubmit={handleLogin}>
          <FormLabel>Email</FormLabel>
          <Input
            variant='outlined'
            fullWidth
            sx={{ padding: 'spacing(0 ,1)', height: '42px' }}
            type='email'
            name='useremail'
            value={formData.useremail}
            onChange={handleFormDataChange}
          />
          <FormLabel sx={{ marginTop: 3 }}>Password</FormLabel>
          <PasswordInput
            value={formData.password}
            onChangeHandler={handleFormDataChange}
            name='password'
          />
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            marginTop={3}
          >
            <Button size='lg' loading={loading} type='submit'>
              Sign in
            </Button>
            <Button onClick={handleForgotPassword} sx={{ padding: 0, margin: 0 }} variant='plain'>
              <Typography sx={{ textDecoration: 'underline' }} fontWeight={'sm'}>
                Forgot your password?
              </Typography>
            </Button>
          </Stack>
        </form>
      </FormControl>
    </Stack>
  );
};

export { LoginForm };
