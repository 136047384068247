import { useEffect, useMemo, useState } from 'react';

import { Skeleton, Stack, useColorScheme } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import ReactEcharts from 'echarts-for-react';
import { useParams } from 'react-router-dom';

import { BreakdownByTypes, ChartTypes } from '../..';
import { makeAuthenticatedGetRequest } from '../../../../../../services/axios';
import { retireeBreakdownTimeseriesChart } from '../../../../../../services/axios/endpoints';
import { getChartDataItemColor } from '../../utils';
import { sortChartItems } from '../utils';

export default function BarChart({
  breakdown_type,
  chartType,
  include_non_rated_projects,
}: {
  breakdown_type: BreakdownByTypes;
  chartType: ChartTypes;
  include_non_rated_projects: boolean;
}) {
  const { mode } = useColorScheme();
  const { retiree } = useParams();
  const [forceReload, setForceReload] = useState<boolean>(false);

  const { data, isLoading } = useQuery({
    queryKey: [
      'retiree-breakdown-stats-timeseries-data',
      retiree,
      breakdown_type,
      chartType,
      include_non_rated_projects,
    ],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(retireeBreakdownTimeseriesChart, {
        params: {
          retiree_name: retiree,
          breakdown_type,
          include_non_rated_projects,
          metric: chartType,
        },
      }),
    select: (data) => data?.data?.data,
  });

  const option = useMemo(() => {
    const shouldOrderByLabel = ['ghg_rating', 'sdg_rating'].includes(breakdown_type);
    return {
      grid:
        chartType === 'credits'
          ? {
              left: 120,
            }
          : {},
      backgroundColor: 'transparent',
      legend: {
        top: 'bottom',
        icon: 'circle',
      },
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        data: data?.map(({ display_name }: any) => display_name),
        type: 'category',
      },
      yAxis: {
        name: chartType === 'credits' ? 'Credits Retired (tCO2e)' : 'Number of Projects Retired',
        nameLocation: 'middle',
        nameGap: chartType === 'credits' ? 100 : 50,
        axisLabel: {
          margin: 10,
        },
        type: 'value',
      },
      series: data?.[0]?.data
        .sort((a, b) => a?.key - b?.key)
        ?.map((item, index) => {
          return {
            ...item,
            color: getChartDataItemColor(breakdown_type, item?.key, mode === 'dark', index),
          };
        })
        .sort(sortChartItems(shouldOrderByLabel, breakdown_type))
        ?.map(({ name, color }: any) => {
          return {
            data: data?.map(({ data: itemData }: any) => {
              return Number(itemData?.find(({ name: itemName }: any) => name === itemName)?.value);
            }),
            type: 'bar',
            stack: 'x',
            name,
            barWidth: 18,
            color,
          };
        }),
    };
  }, [data, mode, chartType, breakdown_type, include_non_rated_projects]);

  useEffect(() => {
    setForceReload(true);
    const timerID = setTimeout(() => {
      setForceReload(false);
    }, 100);
    return () => clearTimeout(timerID);
  }, [chartType, breakdown_type, include_non_rated_projects]);

  return isLoading || forceReload ? (
    <Stack
      padding={[2, 6]}
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'flex-end'}
      minHeight={`400px`}
    >
      {[4, 6, 10, 4, 3, 6, 5, 8].map((height) => {
        return <Skeleton height={`${28 * height}px`} width={'36px'} variant='rectangular' />;
      })}
    </Stack>
  ) : (
    <ReactEcharts option={option} style={{ height: `400px` }} theme={mode} />
  );
}
