import { useEffect, useRef } from 'react';

import * as maptilersdk from '@maptiler/sdk';
import { Box, Divider, Stack, Typography } from '@mui/joy';

import '@maptiler/sdk/dist/maptiler-sdk.css';

import useIsLargeScreen from '../../../../../commons/utils/hooks/useIsLargeScreen';
import { HTMLRenderer } from '../HTMLRenderer';

interface PropTypes {
  description: string;
  maptilerLink: string;
  maptilerLayers: Array<any>;
  maptilerViewState: any;
}

export const Map = ({
  description,
  maptilerLayers,
  maptilerLink,
  maptilerViewState,
}: PropTypes) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<maptilersdk.Map | null>(null);

  maptilersdk.config.apiKey = process.env.MAPTILER_API_KEY ?? '';

  useEffect(() => {
    map.current = new maptilersdk.Map({
      container: mapContainer.current ?? '',
      style: maptilerLink,
      center: [maptilerViewState?.longitude, maptilerViewState?.latitude],
      zoom: maptilerViewState?.zoom,
    });

    map.current.on('load', function () {
      map.current?.resize();
      const hideLayers = maptilerLayers
        .filter((l) => l.layout && l.layout.visibility === 'none')
        .map((l) => l.id);
      hideLayers.forEach((l) => {
        if (map.current?.getLayer(l)) {
          map.current?.setLayoutProperty(l, 'visibility', 'none');
        }
      });
    });

    map.current.on('idle', function () {
      map.current?.resize();
    });
  }, []);

  const isLg = useIsLargeScreen();

  return (
    <Box
      display={'grid'}
      gridTemplateColumns={isLg ? '3.5fr 0fr 2fr' : '1fr'}
      gap={isLg ? 6 : 2}
      paddingBottom={6}
    >
      <Box>
        <Box position={'relative'} width={'100%'} height={'400px'}>
          <div ref={mapContainer} style={{ width: '100%', height: '100%', position: 'absolute' }} />
        </Box>
        <Stack direction={'row'} gap={3} marginTop={2} alignItems={'center'} flexWrap={'wrap'}>
          {maptilerLayers
            ?.filter(({ layout }) => layout?.visibility === 'visible')
            ?.map((layer: any) => {
              return layer?.legend
                ?.filter(({ name }) => !!name)
                ?.map((legend: any, index: number) => {
                  return (
                    <Stack direction={'row'} spacing={1} alignItems={'center'} key={index}>
                      <Box
                        height={'18px'}
                        width={'18px'}
                        borderRadius={'100%'}
                        sx={{
                          backgroundColor: legend?.style?.[`background-color`],
                          outlineColor: legend?.style?.[`outline-color`],
                          outlineStyle: legend?.style?.[`outline-style`],
                          outlineWidth: legend?.style?.[`outline-width`],
                        }}
                      />
                      <Typography fontSize={'sm'} fontWeight={'xl'} color='neutral'>
                        {legend?.name}
                      </Typography>
                    </Stack>
                  );
                });
            })}
        </Stack>
      </Box>
      <Divider orientation='vertical' />
      <Typography fontSize={'md'} color='neutral'>
        <HTMLRenderer colorType='secondary' dangerouslySetInnerHTML={{ __html: description }} />
      </Typography>
    </Box>
  );
};
