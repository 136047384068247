import { useMemo, useState } from 'react';

import { Box, Button, ButtonGroup, Card, Stack, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../commons/stores/project';
import { numberWithCommas } from '../../../commons/utils/index';
import { track } from '../../../services/analytics';
import {
  EVENT_PROJECT_COMPARISON_PAGE,
  EVNTAPP_activity_year_click,
  EVNTAPP_vintage_click,
} from '../../../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import {
  projectCreditSummary,
  projectRetirementComparison,
} from '../../../services/axios/endpoints';
import {
  projectComparisonYears,
  tranformAnnualCreditData,
  transformRetirementData,
} from '../constants';
import ProjectComparison from '../executive_summary/ProjectComparison';

import AnnualCreditGraph from './AnnualCreditGraph';
import InfoGrid from './InfoGrid';
import { ProjectRetirees } from './ProjectRetirees';
import RetirementComparisonGraph from './RetirementComparisonGraph';

const ProjectComparisonPage = () => {
  const location = useLocation();
  const { projectData } = useRecoilValue(projectState);
  const [selectedValue, setSelectedValue] = useState<'activity' | 'vintage'>('activity');
  const [params] = useSearchParams();
  const ghg_standard_code = params.get('standard');
  const project_id = params.get('id');
  const showForcast = useMemo(() => {
    if (projectData?.code) {
      return (
        projectData.code.toLowerCase().startsWith('gs') ||
        projectData.code.toLowerCase().startsWith('vcs')
      );
    }
    return false;
  }, [projectData]);

  const { data } = useQuery({
    queryKey: [`project-annual-credit-summary`],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectCreditSummary, {
        params: { project_id, ghg_standard_code },
      }),
    select: (data) => tranformAnnualCreditData(data?.data?.graph),
  });

  const { data: retirementGraph } = useQuery({
    queryKey: [`project-retirement-comparison`],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectRetirementComparison, {
        params: { project_id, ghg_standard_code },
      }),
    select: (data: any) => transformRetirementData(data?.data?.graph),
  });

  const handleSelectCreditDataType = (id: 'activity' | 'vintage') => {
    track(
      id === 'vintage' ? EVNTAPP_vintage_click : EVNTAPP_activity_year_click,
      EVENT_PROJECT_COMPARISON_PAGE,
      {
        project_id: projectData?.projectId,
        page_url: `${location.pathname}${location.search}`,
      }
    );
    setSelectedValue(id);
  };

  return (
    <Stack sx={{ mx: 'auto', maxWidth: 'lg' }} gap={4}>
      {data && (
        <Card>
          <Typography level='h4' fontWeight={'xl'}>
            Annual credit data
          </Typography>
          <Typography level='body-md' color='neutral'>
            Shows issued, and retired credits in the years when the verification, issuance, or
            retirement took place.
          </Typography>
          <Stack direction={'row'} justifyContent={'flex-end'} sx={{ px: 3, mb: 2 }}>
            <ButtonGroup color='primary' size='md'>
              {projectComparisonYears.map(({ id, label }) => {
                const isSelected = id === selectedValue;
                return (
                  <Button
                    variant={isSelected ? 'solid' : 'outlined'}
                    onClick={() => handleSelectCreditDataType(id as 'activity' | 'vintage')}
                  >
                    {label}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Stack>
          <AnnualCreditGraph data={data} selectedValue={selectedValue} showForcast={showForcast} />
        </Card>
      )}
      <ProjectRetirees ghgStandardCode={ghg_standard_code} projectId={project_id} />
      <InfoGrid data={data} />
      <Stack direction={{ xs: 'column', lg: 'row' }} gap={3} width={1}>
        <ProjectComparison hasToggle />
        {(retirementGraph?.graph || []).length > 0 ? (
          <Card sx={{ flex: 1 }}>
            <Typography level='h4'>
              Retirements ({numberWithCommas(retirementGraph?.totalRetired || 0)} tCO2e, last 12
              months)
            </Typography>
            <RetirementComparisonGraph data={retirementGraph?.graph} />
          </Card>
        ) : (
          <Box sx={{ flex: 1 }} />
        )}
      </Stack>
    </Stack>
  );
};
export default ProjectComparisonPage;
