import { Option, Select } from '@mui/joy';
import { FaChevronDown } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import { capitalizeFirstLetter } from '../../../../commons/components/Captialize';
import { track } from '../../../../services/analytics';
import {
  EVENT_BLOG_PAGE,
  EVENT_RESOURCE_PAGE,
  EVNTAPP_tag_company_news,
  EVNTAPP_tag_events,
  EVNTAPP_tag_insights,
  EVNTAPP_tag_product,
  EVNTAPP_tag_reports,
} from '../../../../services/analytics/events';

interface tag {
  id: string;
  tag: string;
}
interface props {
  value: string | null;
  tags: tag[];
  changeTag: (a: string | null) => void;
  isResources?: boolean;
}

const Tags = ({ value, tags, changeTag, isResources = false }: props) => {
  const location = useLocation();
  function tagHandler(tag: string | null) {
    let event = '';
    switch (tag) {
      case '2':
        event = isResources ? EVNTAPP_tag_insights : EVNTAPP_tag_company_news;
        break;
      case '4':
        event = EVNTAPP_tag_events;
        break;
      case '4':
        event = EVNTAPP_tag_events;
        break;
      case '1':
        event = isResources ? EVNTAPP_tag_reports : EVNTAPP_tag_insights;
        break;
      case '7':
        event = EVNTAPP_tag_product;
        break;
    }
    if (event !== '') {
      track(event, isResources ? EVENT_RESOURCE_PAGE : EVENT_BLOG_PAGE, {
        page_url: `${location.pathname}${location.search}`,
      });
    }
    changeTag(tag);
  }
  return (
    <Select
      endDecorator={<FaChevronDown />}
      sx={{
        fontSize: 'lg',
        maxWidth: '15.875rem',
        padding: '0.4rem 1rem',
        fontWeight: 'md',
      }}
      onChange={(_e, value: string | null) => {
        tagHandler(value);
      }}
      defaultValue={value}
    >
      <Option value={null}>All Posts</Option>
      {(tags || []).map((tag: tag, index: number) => {
        return (
          <Option key={index} value={`${tag?.id}`}>
            {capitalizeFirstLetter(tag?.tag)}
          </Option>
        );
      })}
    </Select>
  );
};

export default Tags;
