import { useState } from 'react';

import { Box, List, ListItem, ListItemButton, Switch, Typography } from '@mui/joy';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { appState } from '../../commons/stores/app';
import { userState } from '../../commons/stores/auth';
import queryClient from '../../services/react-query';
import { Contact } from '../contact';

import Account from './Account';
import ChangePassword from './ChangePassword';

const Profile = () => {
  const [activeSection, setActiveSection] = useState('account');
  const setAppState = useSetRecoilState(appState);
  const appStateData = useRecoilValue(appState);
  const userData = useRecoilValue(userState);
  const handleEnvToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAppState({ isStaging: e.target.checked });
    queryClient.removeQueries();
  };

  return (
    <Box sx={{ display: 'flex', padding: 15, justifyContent: 'space-between' }}>
      <List sx={{ maxWidth: '15rem' }}>
        <ListItemButton
          variant={activeSection === 'account' ? 'soft' : 'plain'}
          onClick={() => setActiveSection('account')}
        >
          Account
        </ListItemButton>
        <ListItemButton
          variant={activeSection === 'changePassword' ? 'soft' : 'plain'}
          onClick={() => setActiveSection('changePassword')}
        >
          Change Password
        </ListItemButton>
        <ListItemButton
          variant={activeSection === 'changePassword' ? 'soft' : 'plain'}
          onClick={() => setActiveSection('contactUs')}
        >
          Contact Us
        </ListItemButton>
        {userData?.calyx_team ? (
          <>
            <br />
            <Typography level='body-sm' sx={{ my: 2 }}>
              Calyx Only Panel
            </Typography>
            <ListItem>
              <Typography color={!appStateData.isStaging ? 'success' : 'primary'}>
                Production
              </Typography>
              <Switch defaultChecked={appStateData.isStaging} onChange={handleEnvToggle} />
              <Typography color={appStateData.isStaging ? 'success' : 'primary'}>
                Staging
              </Typography>
            </ListItem>
          </>
        ) : null}
        <Typography level='body-sm' sx={{ my: 2 }}>
          App Version {process.env.GITHUB_VERSION_NUMBER}
        </Typography>
      </List>
      <Box sx={{ m: 0, flex: 1, ml: 4 }}>
        {activeSection === 'account' && <Account />}
        {activeSection === 'changePassword' && <ChangePassword />}
        {activeSection === 'contactUs' && <Contact isOnProfilePage />}
      </Box>
    </Box>
  );
};

export default Profile;
