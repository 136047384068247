import React from 'react';

import { Stack, Card, Typography, Button, styled, Chip, Box } from '@mui/joy';
import { FiArrowRight } from 'react-icons/fi';
import { TbPinnedFilled } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import { capitalizeFirstLetter } from '../../../../commons/components/Captialize';
import Image from '../../../../commons/components/Image';
import { dateFormat } from '../../../../commons/utils/dateFormat';
import { getAssetURL } from '../../../../services/axios/endpoints';
import { theme } from '../../../../theme';

interface CardType {
  id?: string;
  title: string;
  pinnedPostLink?: string;
  link?: string;
  post?: string;
  access_level?: string;
  date: string;
  author?: string;
  visual: string;
  state?: string;
  tags?: { tag_info: { id: string; tag: string } }[];
  isPinned?: boolean;
}
interface props {
  data: CardType;
  type?: string;
  page?: string;
}
const StyledImage = styled(Image)(() => ({
  width: '100%',
  objectFit: 'contain',
}));

const CardComponent = ({ data, type, page }: props) => {
  const { title, visual, date, tags, id, isPinned = false, pinnedPostLink, link } = data;
  const navigate = useNavigate();

  const handleClick = () => {
    if (!!link) {
      window.open(link, '_blank');
    } else {
      navigate(isPinned ? `${pinnedPostLink}` : `/${page}?tag=${id}`);
    }
  };

  return (
    <React.Fragment>
      <Card
        sx={{
          padding: 0,
          height: '100%',
          justifyContent: 'space-between',
          cursor: 'pointer',
          borderRadius: 'none',
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
          overflow: 'hidden',
        }}
        onClick={handleClick}
      >
        <Stack
          sx={{
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Stack gap={2}>
            <Box>
              <StyledImage src={getAssetURL(visual, type)} alt='' />
            </Box>
            <Stack gap={2} paddingX={3}>
              {isPinned ? (
                <Typography fontWeight={'xl'} endDecorator={<TbPinnedFilled />}>
                  Pinned Post
                </Typography>
              ) : (
                <Stack direction={'row'} gap={1}>
                  {tags?.map((tag, index) => {
                    return tag?.tag_info?.tag ? (
                      <Chip variant='outlined' color='primary' size='lg' key={index}>
                        <Typography fontWeight={'md'} fontSize={'sm'}>
                          {capitalizeFirstLetter(tag?.tag_info?.tag)}
                        </Typography>
                      </Chip>
                    ) : null;
                  })}
                </Stack>
              )}
              <Stack spacing={1}>
                <Typography level='h3' fontWeight={'sm'}>
                  {title}
                </Typography>
                <Typography level='title-sm'>{dateFormat(date).dayddmmyyyy}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack justifyContent={'flex-end'} direction={'row'} padding={3}>
            <Button
              variant='outlined'
              size='sm'
              endDecorator={<FiArrowRight style={{ fontSize: theme.vars.fontSize.xl2 }} />}
            >
              Show More
            </Button>
          </Stack>
        </Stack>
      </Card>
    </React.Fragment>
  );
};

export default CardComponent;
