import { useEffect, useState } from 'react';

import { CircularProgress, Sheet } from '@mui/joy';
import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { makeAuthenticatedGetRequest } from '../../services/axios';
import { getUserInfo } from '../../services/axios/endpoints';
import { redirectionURLState, userState } from '../stores/auth';

import { Layout } from './Layout';

interface PropTypes {
  hideLayout?: boolean;
}

const FullScreenSpinner = () => {
  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <CircularProgress size='lg' />
    </Sheet>
  );
};

const ProtectedRoute: React.FC<PropTypes> = ({ hideLayout = false }) => {
  const setRedirectionUrl = useSetRecoilState(redirectionURLState);
  const [userInfo, setUserInfo] = useRecoilState(userState);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        setIsLoading(true);
        const apiResponse = await makeAuthenticatedGetRequest(getUserInfo);
        setUserInfo(apiResponse.data);
        return apiResponse.data;
      } catch (err) {
        setUserInfo(null);
        return;
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserInfo();
  }, [setIsLoading, setUserInfo]);

  if (isLoading) return <FullScreenSpinner />;

  if (!!userInfo?.user_id)
    return hideLayout ? (
      <Outlet />
    ) : (
      <Layout>
        <Outlet />
      </Layout>
    );

  if (window.location.pathname !== '/login')
    setRedirectionUrl(`${window.location.pathname}${window.location.search}`);

  return <Navigate to='/login' />;
};

export { ProtectedRoute };
