import { useState } from 'react';

import { Button, Card, Stack, Typography } from '@mui/joy';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { userState } from '../../../../commons/stores/auth';
import { snackbarState } from '../../../../commons/stores/snackerbar';

import { sendUpgradeRequest } from './service';

const REQUEST_UPGRADE = 'REQUEST_UPGRADE';
const SUBMIT_REQUEST = 'SUBMIT_REQUEST';
const THANKYOU_MESSAGE = 'THANKYOU_MESSAGE';

const RequestUpgradeBody = ({ changeView }: { changeView: (view: string) => void }) => {
  const handleRequest = () => {
    changeView(SUBMIT_REQUEST);
  };

  return (
    <>
      <Typography level='h1' fontWeight={'lg'} textAlign={'center'} fontSize={'36px'}>
        Upgrade to get the why behind a rating
      </Typography>
      <Typography textAlign={'center'} fontSize={'md'} color='neutral'>
        Richer project-level data and insights are available to subscribers with a Standard or
        Expert subscription. To upgrade your subscription, fill out the form and our customer
        success team will contact you soon.
      </Typography>
      <Button color='primary' onClick={handleRequest}>
        Request upgrade
      </Button>
    </>
  );
};

const SubmitRequestBody = ({ changeView }: { changeView: (view: string) => void }) => {
  const userInfo = useRecoilValue(userState);
  const setSnackbar = useSetRecoilState(snackbarState);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoading(true);
    sendUpgradeRequest()
      .then(() => {
        changeView(THANKYOU_MESSAGE);
      })
      .catch(() => {
        setSnackbar({ message: 'Something went wrong. Please try again.', color: 'danger' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    changeView(REQUEST_UPGRADE);
  };

  return (
    <>
      <Typography level='h1' fontWeight={'lg'} textAlign={'center'} fontSize={'36px'}>
        Submit your request
      </Typography>
      <Typography textAlign={'center'} fontSize={'md'} color='neutral'>
        Your upgrade request will be sent to our team. We will reach out to you shortly at{' '}
        {userInfo?.email}.
      </Typography>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Button onClick={handleCancel} variant='outlined'>
          Cancel
        </Button>
        <Button color='primary' onClick={handleSubmit} disabled={loading}>
          Submit
        </Button>
      </Stack>
    </>
  );
};

const ThankyouMessageBody = () => {
  return (
    <>
      <Typography level='h1' fontWeight={'lg'} textAlign={'center'} fontSize={'36px'}>
        Thank you! Your request is on its way
      </Typography>
      <Typography textAlign={'center'} fontSize={'md'} color='neutral'>
        Your request has been successfully submitted. Our team will reach out to you shortly.
      </Typography>
    </>
  );
};

const renderView = (view: string, changeView: (view: string) => void) => {
  switch (view) {
    case REQUEST_UPGRADE:
      return <RequestUpgradeBody changeView={changeView} />;
    case SUBMIT_REQUEST:
      return <SubmitRequestBody changeView={changeView} />;
    case THANKYOU_MESSAGE:
      return <ThankyouMessageBody />;
  }
};

export const ProjectPaywallModal = () => {
  const [currentView, setCurrentView] = useState<string>(REQUEST_UPGRADE);
  const changeView = (view: string) => setCurrentView(view);
  return (
    <Card
      sx={(theme) => {
        return {
          width: '700px',
          border: `1px solid ${theme.palette.primary.outlinedActiveBg}`,
          position: 'absolute',
          top: '200px',
          left: '50%',
          transform: 'translateX(-50%)',
          padding: '24px',
          zIndex: 9,
          background: theme.palette.primary.outlinedColor,
        };
      }}
    >
      <Stack alignItems={'center'} spacing={2}>
        {renderView(currentView, changeView)}
      </Stack>
    </Card>
  );
};
