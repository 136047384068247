import { useEffect } from 'react';

import { Box, Grid, Stack, Typography, styled, useColorScheme } from '@mui/joy';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { CalyxLogo, CalyxLogoDark } from '../../assets/brand';
import { loginBanner } from '../../assets/images';
import Image from '../../commons/components/Image';
import { userState } from '../../commons/stores/auth';

const BannerImage = styled(Image)(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: '50% 50%',
}));

const Login = () => {
  const { mode } = useColorScheme();
  const authInfo = useRecoilValue(userState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!authInfo?.user_id) {
      navigate('/');
    }
  }, [navigate, authInfo]);

  return (
    <Grid container spacing={2} margin={0} overflow={'hidden'} flexGrow={1} height={'100vh'}>
      <Grid xs={8} padding={3} position={'relative'} height={'100vh'}>
        <Stack direction='row' justifyContent='flex-start' alignItems='center'>
          {mode === 'light' ? <CalyxLogo /> : <CalyxLogoDark />}
        </Stack>
        <Box
          position={'absolute'}
          top={'50%'}
          left={'50%'}
          sx={{ transform: 'translate(-50%,-50%)' }}
        >
          <Outlet />
        </Box>
        <Typography
          position={'absolute'}
          bottom={'24px'}
          padding={'10px'}
          left={'50%'}
          sx={{ transform: 'translateX(-50%)' }}
          fontSize={'sm'}
        >
          &copy; Calyx Global 2024
        </Typography>
      </Grid>
      <Grid xs={4} padding={0} height={'100vh'}>
        <BannerImage src={loginBanner} alt='' />
      </Grid>
    </Grid>
  );
};

export { Login };
