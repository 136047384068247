import { useRef, useState } from 'react';

import { Filter, FilterObject, FilterType, Section } from '../../../components/Filter/types';
import { sortStringsAscending } from '../../index';
import useDebounce from '../useDebounce';
import useDebouncedInput from '../useDebouncedInput';
import useFilterParams from '../useFilterParams';
import usePagination from '../usePagination';

import { AppliedFilterItem, FilterDetails, FlattenedResult } from './types';

const findByKey = (set, targetKey, value) => {
  const removedItem = null;
  for (const item of set) {
    if (item[targetKey] === value) {
      return item;
    }
  }
  return removedItem;
};

const recursiveFlatten = (
  sections: Section[],
  topLevelParentKey: string | null = null,
  parentKey: string | null = null
): FlattenedResult[] => {
  const flattened: FlattenedResult[] = [];

  const helper = (
    filters: (Section | Filter)[],
    currentTopLevelParentKey: string | null,
    currentParentKey: string | null
  ) => {
    filters.forEach((filter) => {
      const { name, type, display_name, filters } = filter;
      const newTopLevelParentKey = currentTopLevelParentKey || name; // Highest level parentKey
      const newParentKey = currentParentKey || name; // One level up parentKey
      flattened.push({
        name,
        type,
        display_name,
        parentKey: newParentKey,
        topLevelParentKey: newTopLevelParentKey,
      });

      if (filters && filters.length > 0) {
        helper(filters, newTopLevelParentKey, name);
      }
    });
  };

  helper(sections, topLevelParentKey, parentKey);

  return flattened;
};

// Function to create a map from the flattened structure
const flattenSectionsToMap = (sections: Section[]): Map<string, FlattenedResult> => {
  const map = new Map<string, FlattenedResult>();
  const flattenedFilters = recursiveFlatten(sections);

  flattenedFilters.forEach((filter) => {
    map.set(`${filter.topLevelParentKey}_${filter.name}`, filter);
  });

  return map;
};

const useFilters = (
  initialFilters: FilterObject = {},
  isPaginated?: boolean,
  shouldReplace?: boolean
): FilterDetails => {
  const [allFilters, setAllFilters] = useState(new Map());
  const [appliedFilters, setAppliedFilters] = useState<Set<AppliedFilterItem>>(new Set());
  const [filters, setFilters] = useState<FilterObject>(initialFilters);
  const [allAppliedFilterCount, setAllAppliedFilterCount] = useState<number>(0);
  const filterMap = useRef(new Map());
  const allFilterData = useRef<any>(null);
  const filterParentMap = useRef(new Map());
  const selectedCountMap = useRef(new Map());
  const allFilterCountMap = useRef(new Map());
  const [enablePagination, setEnablePagination] = useState(false);
  const { setFiltersFromQuery, updateQueryParams, searchParams, setSearchParams } = useFilterParams(
    initialFilters,
    (updatedFilters) => {
      if (updatedFilters['search_text']) setSearch(updatedFilters['search_text']);
      setter(updatedFilters, undefined, false);
    },
    shouldReplace
  );
  const paginationState = usePagination({
    initialFilters,
    setter: (paginateState) => {
      setFilters((prev) => {
        const updated = { ...prev, ...paginateState };

        updateQueryParams(updated);
        return updated;
      });
    },
    searchParams,
    enabled: enablePagination,
  });
  const debouncedUpdateQuery = useDebounce(updateQueryParams, 100);
  const [search, handleSearch, setSearch] = useDebouncedInput('', (value) => {
    setFilters((prevFilter) => {
      const updatedFilters = { ...prevFilter, search_text: value };
      debouncedUpdateQuery(updatedFilters);
      return updatedFilters;
    });
  });

  const setter = (
    filters: FilterObject,
    prevFilter?: FilterObject,
    shouldUpdateSearchParams?: boolean
  ) => {
    const isUpdateSearchParams =
      typeof shouldUpdateSearchParams === 'boolean' ? shouldUpdateSearchParams : true;
    const isReset = !prevFilter;
    const updatedFilters = isReset ? filters : { ...prevFilter, ...filters };
    const { limit, page, sort, search_text, ...otherUpdatedFilters } = updatedFilters;
    const otherFilters = otherUpdatedFilters || {};
    const updatedPage = prevFilter?.page !== updatedFilters?.page ? updatedFilters?.page : 1;
    if (otherFilters) {
      setAllFilters((prev) => {
        const updatedAllFilters = new Map(isReset ? [] : prev);
        Object.keys(otherFilters).forEach((key) => {
          if (Array.isArray(otherFilters[key]))
            updatedAllFilters.set(key, new Set(otherFilters[key] as Array<string>));
          else {
            updatedAllFilters.set(key, otherFilters[key]);
          }
        });
        return updatedAllFilters;
      });
      setAppliedFilters(() => {
        const updatedAppliedFilters: Set<AppliedFilterItem> = new Set();
        const keys = Object.keys(otherFilters).reduce(
          (acc: { key: string; topLevelParentKey: string }[], key) => {
            return [
              ...acc,
              ...(otherFilters[key] || ([] as any)).map((valueKey) => ({
                key: valueKey,
                topLevelParentKey: key,
              })),
            ];
          },
          []
        );
        keys.forEach(({ key, topLevelParentKey }) => {
          const valueFilter = filterMap.current.get(getFiltersMapKeyName(topLevelParentKey, key));
          if (!valueFilter) return;
          const parentFilter = filterMap.current.get(
            getFiltersMapKeyName(topLevelParentKey, valueFilter?.parentKey || '')
          );
          const topFilter = filterMap.current.get(
            getFiltersMapKeyName(topLevelParentKey, valueFilter?.topLevelParentKey || '')
          );
          updatedAppliedFilters.add(
            getAppliedFilterName(
              parentFilter?.display_name,
              valueFilter?.display_name,
              key,
              parentFilter?.name,
              topFilter?.name
            )
          );
        });
        selectedCountMap.current = getSelectedCountMap(updatedAppliedFilters);
        return clubChildFilters(updatedAppliedFilters);
      });
    }
    if (isUpdateSearchParams) debouncedUpdateQuery({ ...updatedFilters, page: updatedPage });
    if (isReset) {
      setFilters({ ...updatedFilters, page: updatedPage });
      return {};
    } else {
      return { ...updatedFilters, page: updatedPage };
    }
  };

  const getAppliedFilterName = (
    keyName: string,
    valueName: string,
    key: string,
    parentKey: string,
    topLevelParentKey: string,
    childKeys?: string[]
  ) => {
    return { keyName, valueName, key, parentKey, topLevelParentKey, childKeys: childKeys || [] };
  };

  const getFiltersMapKeyName = (topLevelKey: string, key: string) => {
    return `${topLevelKey}_${key}`;
  };

  const clubChildFilters = (updatedAppliedFilters) => {
    const groupedFilters = new Map();

    updatedAppliedFilters.forEach((filter) => {
      const { parentKey } = filter;
      if (!groupedFilters.has(parentKey)) {
        groupedFilters.set(parentKey, []);
      }
      groupedFilters.get(parentKey).push(filter);
    });
    const result: any = new Set(updatedAppliedFilters);
    groupedFilters.forEach((filters, parentKey) => {
      const topLevelParentKey = filters[0]?.topLevelParentKey || '';
      const allKeys = sortStringsAscending(filters.map((filter) => filter.key)).join(',');
      const allKeysExist = filterParentMap.current.has(parentKey)
        ? filterParentMap.current.get(parentKey) === allKeys
        : false;
      if (allKeysExist) {
        const childKeys: string[] = [];
        for (const item of result) {
          if (item.parentKey === parentKey) {
            childKeys.push(item.key);
            result.delete(item);
          }
        }
        // Add the parent key to the result
        const data = filterMap.current.get(getFiltersMapKeyName(topLevelParentKey, parentKey));
        const parent = filterMap.current.get(
          getFiltersMapKeyName(topLevelParentKey, data.parentKey)
        );
        const topParent = filterMap.current.get(
          getFiltersMapKeyName(topLevelParentKey, data.topLevelParentKey)
        );
        result.add(
          getAppliedFilterName(
            parent.display_name,
            data.display_name,
            data.name,
            parent.name,
            topParent.name,
            childKeys
          )
        );
      }
    });
    return result;
  };

  const removeClubbedAppliedFilter = (item: any) => {
    const parentKey = item?.topLevelParentKey;
    const childKeys = item?.childKeys || [];
    setFilters((prevFilters) => {
      let keys = prevFilters[parentKey] || [];
      if (Array.isArray(keys)) {
        childKeys.forEach((childKey) => {
          keys = (keys as string[]).filter((key) => key !== childKey);
        });
      }
      const updatedFilters = { [parentKey]: keys };
      return setter(updatedFilters, prevFilters);
    });
  };

  const createFilterParentMap = (filters: Filter[]) => {
    const filterParentMap: Map<string, string> = new Map();

    const traverseFilters = (filters, parentKey = null) => {
      filters.forEach((filter) => {
        const { name, filters: childFilters } = filter;

        if (parentKey) {
          if (childFilters && childFilters.length > 0) {
            const childNames = sortStringsAscending(childFilters.map((child) => child.name)).join(
              ','
            );
            filterParentMap.set(name, childNames);
          }
        }

        if (childFilters && childFilters.length > 0) {
          traverseFilters(childFilters, name);
        }
      });
    };

    traverseFilters(filters);
    return filterParentMap;
  };

  const updateFilterMap = (filterData: Section[]) => {
    allFilterData.current = filterData;
    filterMap.current = flattenSectionsToMap(filterData);
    filterParentMap.current = createFilterParentMap(filterData);
    allFilterCountMap.current = createAllFilterCountMap(filterData);
    setFiltersFromQuery();
    if (isPaginated) setEnablePagination(true);
  };

  const removeAppliedFilter = (valueKey: string) => {
    const removedItem = findByKey(appliedFilters, 'key', valueKey);
    if (removedItem) {
      const isClubbedKey = (removedItem?.childKeys || []).length > 0;
      const parentKey = removedItem?.topLevelParentKey;
      const valueKey = removedItem?.key;
      if (isClubbedKey) {
        removeClubbedAppliedFilter(removedItem);
        return;
      }
      setFilters((prevFilter) => {
        const updatedFilter = { ...prevFilter };
        if (isClubbedKey) {
        }
        if (parentKey in prevFilter) {
          if (Array.isArray(prevFilter[parentKey])) {
            const updated = (prevFilter[parentKey] || ([] as any)).filter(
              (key) => key !== valueKey
            );
            updatedFilter[parentKey] = updated;
          } else {
            delete updatedFilter[parentKey];
          }
        }
        return setter(updatedFilter, prevFilter);
      });
    }
  };
  // this is only valod for muti-select filter type
  const isSelected = (parent: Filter, value: Filter): boolean => {
    const { name: parentKey, type } = parent;
    const { name: valueKey, filters } = value;
    let result = true;
    if (filters.length > 0) {
      filters.forEach((filter) => {
        const selected = isSelected(parent, filter);
        if (!selected) {
          result = false;
        }
      });
      return result;
    }
    switch (type) {
      case FilterType.MULTI_SELECT: {
        const allFilterValueSet = allFilters.has(parentKey) ? allFilters.get(parentKey) : null;
        return allFilterValueSet ? allFilterValueSet.has(valueKey) : false;
      }
      default:
        return false;
    }
  };

  // this is only valid for multi-select filter type
  const isAnyChildFilterChecked = (parent: Filter, value?: Filter) => {
    const { type, name, filters: parentFilters } = parent;
    const valueFilters = value?.filters || [];

    const flattenFilters = (filters: Filter[]): string[] => {
      return filters.reduce((acc: string[], filter: Filter) => {
        acc.push(filter.name);

        if (filter.filters && filter.filters.length > 0) {
          acc.push(...flattenFilters(filter.filters));
        }

        return acc;
      }, []);
    };
    const keys = flattenFilters(value ? valueFilters : parentFilters);
    if (name in filters) {
      const data = allFilters.get(name);
      switch (type) {
        case FilterType.MULTI_SELECT: {
          if (data && data.size > 0) {
            const found = keys.find((key) => data.has(key));
            if (found) return true;
          }
          break;
        }
      }
    }
    return false;
  };

  const clearFilter = (parentKey?: string) => {
    // clear all
    if (!parentKey) {
      setFilters({});
      setAllFilters(new Map());
      setAppliedFilters(new Set());
      selectedCountMap.current = new Map();
      updateQueryParams({});
      setAllAppliedFilterCount(0);
    } else {
      setFilters((prevFilter) => {
        if (parentKey in prevFilter) {
          delete prevFilter[parentKey];
        }
        return setter(filters, prevFilter);
      });
    }
  };

  const getFilterValue = (parentKey: string) => {
    if (allFilters.has(parentKey)) return allFilters.get(parentKey);
    return null;
  };

  const updateFilter = (parentFilter: Filter, valueFilter: Filter, isChecked?: boolean) => {
    const { name: key, type } = parentFilter;
    const { name: value } = valueFilter;
    const isParentFilter = valueFilter.filters.length > 0;

    if (isParentFilter) {
      valueFilter.filters.forEach((value) => {
        updateFilter(parentFilter, value, isChecked);
      });
    } else {
      switch (type) {
        case FilterType.MULTI_SELECT: {
          setFilters((prevFilter: any) => {
            let prevKeyValues = Array.isArray(prevFilter[key]) ? prevFilter[key] : [];
            const allFilterValueSet = allFilters.has(key) ? allFilters.get(key) : null;
            const doesValueExistInKey = allFilterValueSet ? allFilterValueSet.has(value) : false;
            const hasChecked = isChecked !== undefined ? isChecked : !doesValueExistInKey;

            if (!hasChecked) {
              prevKeyValues = prevKeyValues.filter((k) => k !== value);
            } else if (!doesValueExistInKey) {
              prevKeyValues.push(value);
            }
            return setter({ [key]: prevKeyValues }, prevFilter);
          });
          return true;
        }
        default:
          return false;
      }
    }
  };

  const updateTextFilter = (key: string, value: string) => {
    setFilters((prevFilter: any) => {
      let prevKeyValue = Array.isArray(prevFilter[key]) ? prevFilter[key] : '';
      setAllFilters((prevAll) => {
        const updatedAllFilters = new Map(prevAll);
        updatedAllFilters.set(key, value);
        return prevAll;
      });
      prevKeyValue = value;
      return { ...prevFilter, [key]: prevKeyValue };
    });
    return true;
  };

  const toggleSort = (key: string) => {
    setFilters((prevFilter) => {
      const prevSort: Record<string, string> = (prevFilter['sort'] as any) || {};
      let newSort;
      if (prevSort[key] === 'ASC') {
        newSort = { [key]: 'DESC' };
      } else if (prevSort[key] === 'DESC') {
        newSort = { [key]: 'ASC' };
      } else {
        newSort = { [key]: 'DESC' };
      }
      return { ...prevFilter, sort: newSort };
    });
  };

  const getSortValue = (key: string) => {
    const sort: any = filters['sort'];
    if (sort && key in sort) {
      return sort[key];
    }
    return '';
  };

  const getSelectedCountMap = (appliedFilters: Set<AppliedFilterItem>) => {
    const countMap = new Map();
    let count = 0;
    appliedFilters.forEach((filter) => {
      const { topLevelParentKey, childKeys } = filter;
      if (!countMap.has(topLevelParentKey)) {
        countMap.set(topLevelParentKey, 0);
      }
      // count all applied filters
      if (childKeys && childKeys.length > 0) {
        count = count + childKeys.length;
      } else {
        count++;
      }
      countMap.set(topLevelParentKey, countMap.get(topLevelParentKey) + 1);
    });
    setAllAppliedFilterCount(count);
    return countMap;
  };
  const createAllFilterCountMap = (filterData: Filter[]) => {
    const childCountMap = new Map<string, number>();

    const processFilters = (filters: Filter[], parentKey?: string): number => {
      return filters.reduce((count, filter) => {
        processFilters(filter.filters, filter.name);
        if (filter.filters.length === 0) {
          // This is a leaf node
          if (parentKey) {
            childCountMap.set(parentKey, (childCountMap.get(parentKey) || 0) + 1);
          }
        }

        return (
          count + (filter.filters.length === 0 ? 1 : processFilters(filter.filters, parentKey))
        );
      }, 0);
    };

    filterData.forEach((filter) => {
      const leafNodeCount = processFilters(filter.filters, filter.name);
      if (leafNodeCount > 0) {
        childCountMap.set(filter.name, leafNodeCount);
      }
    });

    return childCountMap;
  };

  const getFilterCount = (parentKey: string): number => {
    if (selectedCountMap.current.has(parentKey)) {
      const count = selectedCountMap.current.get(parentKey);
      return count;
    }
    return 0;
  };

  const getTotalFilterCount = (parentKey: string): number => {
    if (allFilterCountMap.current.has(parentKey)) {
      const count = allFilterCountMap.current.get(parentKey);
      return count;
    }
    return 0;
  };

  return {
    filters,
    search,
    allAppliedFilterCount,
    updateFilter,
    isSelected,
    isAnyChildFilterChecked,
    clearFilter,
    updateTextFilter,
    setFilters,
    toggleSort,
    getSortValue,
    handleSearch,
    updateFilterMap,
    getFilterValue,
    removeAppliedFilter,
    getFilterCount,
    appliedFilters: Array.from(appliedFilters),
    setter,
    searchParams,
    setSearchParams,
    getTotalFilterCount,
    paginationState,
  };
};

export default useFilters;
