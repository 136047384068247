import { useEffect, useMemo, useState } from 'react';

import { Box, Card, CircularProgress, Typography, useColorScheme } from '@mui/joy';
import ReactEcharts from 'echarts-for-react';

import { groupObjectsByCommonKey } from '../../../../commons/utils/arrays';
import { addBarBorderRadius } from '../../../../commons/utils/charts';
import { getProjectTypeColor, GHG } from '../../../home/components/ratingsDistribution/constants';
import { getProjectGroupDetails, ghgRatingsArray, sdgRatingsArray } from '../../constants';

interface PropTypes {
  type: 'ghg' | 'sdg';
  rowData: any[];
  isLoading: boolean;
}

const DistributionGraph = ({ type, rowData, isLoading }: PropTypes) => {
  const { mode } = useColorScheme();
  const [showChart, setShowChart] = useState<boolean>(true);
  const { groups, details } = useMemo(() => getProjectGroupDetails(rowData), [rowData]);
  const data = useMemo(
    () =>
      Object.entries(groupObjectsByCommonKey(rowData, type === GHG ? 'ghgRating' : 'sdgRating'))
        .filter(([key]: any) => key !== 'N/A' && key?.toLowerCase() !== 'no cert')
        .map(([key, value]: any) => {
          return {
            grade: key,
            data: Object.entries(groupObjectsByCommonKey(value, 'projectGroupName')).map(
              ([itemKey, itemValue]: any) => {
                return {
                  name: itemKey,
                  total_projects: itemValue?.length,
                };
              }
            ),
          };
        })
        .sort((a: any, b: any) => {
          return type === GHG
            ? ghgRatingsArray.indexOf(a?.grade) - ghgRatingsArray.indexOf(b?.grade)
            : sdgRatingsArray.indexOf(a?.grade) - sdgRatingsArray.indexOf(b?.grade);
        }),
    [rowData, type]
  );

  const option = useMemo(() => {
    return addBarBorderRadius({
      backgroundColor: 'transparent',
      legend: {
        top: 'bottom',
        icon: 'circle',
        textStyle: {
          fontFamily: "'Inter Variable', sans-serif",
          fontSize: '11px',
        },
        itemGap: 15,
      },
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        data: [...data]
          .sort((a: any, b: any) => {
            return type === GHG
              ? ghgRatingsArray.indexOf(a?.grade) - ghgRatingsArray.indexOf(b?.grade)
              : sdgRatingsArray.indexOf(a?.grade) - sdgRatingsArray.indexOf(b?.grade);
          })
          ?.map(({ grade }: any) => grade),
        axisLabel: {
          textStyle: {
            fontFamily: "'Inter Variable', sans-serif",
            fontSize: '12px',
          },
        },
      },
      yAxis: {
        name: '# Ratings',
        nameLocation: 'middle',
        nameGap: 50,
        axisLabel: {
          textStyle: {
            fontFamily: "'Inter Variable', sans-serif",
            fontSize: '12px',
          },
        },
      },
      series: groups
        ?.filter((type) => {
          let isIncluded = false;
          data?.map(({ data }) => {
            data?.map(({ name }) => {
              if (name === type) {
                isIncluded = true;
              }
            });
          });
          return isIncluded;
        })
        ?.map((name: string) => {
          return {
            data: data?.map(({ data: itemData }: any) => {
              return (
                itemData?.find(({ name: itemName }: any) => name === itemName)?.total_projects ?? 0
              );
            }),
            type: 'bar',
            stack: 'x',
            name: details[name],
            barWidth: 18,
            color: getProjectTypeColor(name, mode === 'dark'),
          };
        }),
    });
  }, [data, ghgRatingsArray, sdgRatingsArray, mode]);

  useEffect(() => {
    let timerId: NodeJS.Timeout | string | number | undefined = undefined;
    setShowChart(false);
    timerId = setTimeout(() => {
      setShowChart(true);
    }, 500);
    return () => clearTimeout(timerId);
  }, [option]);

  return (
    <Card>
      <Typography fontWeight={600} fontSize={'lg'}>
        {type?.toUpperCase()} Rating Distribution
      </Typography>
      {!isLoading && showChart ? (
        <ReactEcharts option={option} style={{ height: '400px' }} theme={mode} />
      ) : (
        <Box height={'400px'} width={'100%'} position={'relative'}>
          <CircularProgress
            color='primary'
            sx={{
              backgroundColor: 'transparent',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
            size='lg'
          />
        </Box>
      )}
    </Card>
  );
};

export { DistributionGraph };
