import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import posthog from './services/analytics/posthog';
import 'reset-css';
import './services/sentry';

function loadLocaleData(locale: string) {
  switch (locale) {
    default:
      return import('../lang/compiled/en.json');
  }
}

(async () => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  let messages: any = null;

  if (process.env.NODE_ENV === 'production') {
    messages = await loadLocaleData('en');
  }

  root.render(
    <PostHogProvider client={posthog}>
      <App messages={messages} />
    </PostHogProvider>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
