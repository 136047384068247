import { useState } from 'react';

import { Button, ButtonGroup, Card, Stack, Typography } from '@mui/joy';

import { track } from '../../../../services/analytics';
import {
  EVENT_DASHBOARD_PAGE,
  EVNTAPP_GHG_btn_click,
  EVNTAPP_SDG_btn_click,
} from '../../../../services/analytics/events';

import { DistributionGraph } from './components/distributionGraph';
import { GHG, SDG } from './constants';

interface RatingType {
  label: string;
  value: 'ghg' | 'sdg';
  event: string;
}

const buttonsConfig: Array<RatingType> = [
  {
    label: 'GHG',
    value: GHG,
    event: EVNTAPP_GHG_btn_click,
  },
  {
    label: 'SDG',
    value: SDG,
    event: EVNTAPP_SDG_btn_click,
  },
];

const RatingsDistribution = () => {
  const [selectedType, setSelectedType] = useState<RatingType>(buttonsConfig[0]);

  const handleToggleChartType = (selectedType: RatingType) => {
    track(selectedType.event, EVENT_DASHBOARD_PAGE);
    setSelectedType(selectedType);
  };

  return (
    <Card sx={{ height: 'min-content' }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'} mb={-6.5}>
        <Stack spacing={1}>
          <Typography level='title-lg'>Project ratings distribution</Typography>
          <Typography
            level='body-md'
            fontSize={'md'}
            sx={(theme) => {
              return { color: theme.palette.text.secondary };
            }}
          >
            Distribution of {selectedType.label} ratings by Calyx Global
          </Typography>
        </Stack>
        <ButtonGroup color='primary'>
          {buttonsConfig.map((ratingType) => {
            const { label, value } = ratingType;
            const isSelected = selectedType.value === value;
            return (
              <Button
                variant={isSelected ? 'solid' : 'outlined'}
                onClick={() => handleToggleChartType(ratingType)}
              >
                {label}
              </Button>
            );
          })}
        </ButtonGroup>
      </Stack>
      <DistributionGraph type={selectedType.value} />
    </Card>
  );
};

export { RatingsDistribution };
