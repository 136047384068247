import { Box, Card, CircularProgress, Skeleton, Stack, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { numberWithCommas } from '../../../commons/utils/index';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { projectIssuaranceData } from '../../../services/axios/endpoints';

const CreditDonator = ({
  title,
  value,
  isActive,
}: {
  title: string;
  value: string;
  isActive: boolean;
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <Box
        sx={(theme) => ({
          width: '8px',
          borderRadius: 6,
          background: 'red',
          height: '36px',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          backgroundColor: isActive ? theme.palette.SDG['3'] : theme.palette.border.border,
        })}
      ></Box>
      <Box>
        <Typography level='body-sm'>{title}</Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography level='body-md'>{value}</Typography>
          <Typography level='body-md' component={'span'}>
            tCO2e
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
const Crediting = () => {
  const [params] = useSearchParams();
  const ghg_standard_code = params.get('standard');
  const project_id = params.get('id');
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: [`project-crediting`],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectIssuaranceData, {
        params: { project_id, ghg_standard_code },
      }),
    select: (data) => data?.data.issuanceData,
  });

  if (!data) return null;

  return (
    <Card sx={{ width: 1, height: 1, p: (theme) => theme.spacing(3), gap: 0 }}>
      <Typography fontSize={20} fontWeight={'xl'} color='primary'>
        Crediting
      </Typography>
      <Typography
        level='body-md'
        sx={{ color: (theme) => theme.palette.text.secondary }}
        mt={(theme) => theme.spacing(2)}
      >
        Based on registry data, excluding verified credits
      </Typography>
      {isLoading || isRefetching ? (
        <Box sx={{ position: 'relative', minHeight: '200px', p: 2 }}>
          <Skeleton width={'95%'} height={'90%'} />
        </Box>
      ) : (
        <>
          <Stack
            direction={'row'}
            gap={(theme) => theme.spacing(4)}
            mt={(theme) => theme.spacing(5)}
          >
            <CircularProgress
              value={data?.percentage || 0}
              determinate
              component={'div'}
              size='lg'
              sx={{
                '--CircularProgress-size': '140px',
                '--CircularProgress-progressColor': (theme) => theme.palette['SDG']['3'],
              }}
            >
              <Typography
                fontSize={'sm'}
                fontWeight={'lg'}
                textAlign={'center'}
                sx={{ width: '80px' }}
              >
                Issuances retired
              </Typography>
            </CircularProgress>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center' }}
            >
              <CreditDonator
                title='Total retired'
                value={`${numberWithCommas(data?.totalRetired || 0) || ''}`}
                isActive
              />
              <CreditDonator
                title='Total issued'
                value={`${numberWithCommas(data?.totalIssued || 0) || ''}`}
                isActive={false}
              />
            </Box>
          </Stack>
        </>
      )}
    </Card>
  );
};

export default Crediting;
