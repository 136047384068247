import { styled } from '@mui/joy';

const Wrapper = styled('div')(({ theme }) => ({
  a: {
    color: theme.palette.text.primary,
  },
}));

export const GlobalCustomCSSProvider = ({ children }: { children: React.ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};
