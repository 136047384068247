import { useRef } from 'react';

import { Avatar, Box, CircularProgress, Input, Typography, styled } from '@mui/joy';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';

import Image from '../../commons/components/Image';
import { UserStateTypes, userState } from '../../commons/stores/auth';
import { snackbarState } from '../../commons/stores/snackerbar';
import { makeAuthenticatedPostRequest } from '../../services/axios';
import { assetBaseUrl, profileImage } from '../../services/axios/endpoints';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const Overlay = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.palette.background.level1,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'translate("50%","50%")',
  opacity: 0.4,
  display: 'flex',
}));

const ImgContainer = styled(Box)({
  width: '100%',
  height: '100%',
  borderRadius: 4,
  overflow: 'hidden',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

const Account = () => {
  const profileData = getRecoil(userState);
  const setUserState = useSetRecoilState(userState);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const setSnackbar = useSetRecoilState(snackbarState);

  const uploadCall: MutationFunction<unknown> = async (file: any) => {
    try {
      const form = new FormData();
      form.append('file', file);
      const { data } = await makeAuthenticatedPostRequest<unknown>(profileImage, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error.message);
    }
  };

  const { mutate: uploadImage, isPending } = useMutation<unknown, Error>({
    mutationFn: uploadCall,
    onSuccess: (res: any) => {
      setUserState({ ...profileData, profile_image: res?.profile_image || '' } as UserStateTypes);
      setSnackbar({
        message: `Image uploaded successfully !`,
        color: 'success',
      });
    },
    onError: (error) => {
      setSnackbar({ message: error.message || 'Something went wrong', color: 'danger' });
    },
  });

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files[0]) uploadImage(files[0]);
  };

  const onDragOver = (e) => {
    const event = e as Event;
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragEnter = (e) => {
    const event = e as Event;
    event.preventDefault();
    event.stopPropagation();
  };

  const onFileDrop = (e) => {
    const event = e as Event;
    event.preventDefault();
    event.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (!(file?.type || '').startsWith('image/')) {
      setSnackbar({ message: 'Only Images are accepted !', color: 'warning' });
      return;
    }
    uploadImage(file);
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: 2 }}>
        <Typography level='h4'>Account</Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Input
            type='text'
            placeholder='first name'
            disabled
            value={profileData?.name}
            sx={{ flex: 1 }}
          />
          <Input
            type='text'
            placeholder='last name'
            disabled
            value={profileData?.surname}
            sx={{ flex: 1 }}
          />
        </Box>
        <Typography>Cover</Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'dashed',
            flexFlow: 'column',
            height: '150px',
            cursor: 'pointer',
            position: 'relative',
          }}
          onClick={handleFileUpload}
          onDragEnter={onDragEnter}
          onDragOver={onDragOver}
          onDrop={onFileDrop}
        >
          {isPending && (
            <Overlay>
              <CircularProgress size='lg' />
            </Overlay>
          )}
          {profileData?.profile_image ? (
            <ImgContainer>
              <Image src={`${assetBaseUrl}/${profileData?.profile_image || ''}`} alt='' />
            </ImgContainer>
          ) : (
            <>
              <Avatar />
              <Typography>or drag and drop</Typography>
            </>
          )}
          <VisuallyHiddenInput
            ref={fileInputRef}
            type='file'
            accept='image/*'
            onChange={handleFileChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default Account;
