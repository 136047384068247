import { Card, Grid, Typography } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../commons/stores/project';
import { numberWithCommas } from '../../../commons/utils/index';

const InfoGrid = ({ data }: { data: any }) => {
  const { projectData } = useRecoilValue(projectState);

  if (!data) return null;

  return (
    <Card>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ width: '100%' }}>
        <Grid xs={6}>
          <Card variant='soft'>
            <Typography level='body-sm' fontWeight={'xl'}>
              PROJECT FORECAST
            </Typography>
            <Typography level='h3'>
              {numberWithCommas(data?.forecastValue || 0)}{' '}
              <Typography level='body-md' component={'span'} fontWeight={'sm'}>
                tCO2e/year
              </Typography>
            </Typography>
          </Card>
        </Grid>
        <Grid xs={6}>
          <Card variant='soft'>
            <Typography level='body-sm' fontWeight={'xl'}>
              PROJECT DURATION
            </Typography>
            <Typography level='h3'>{projectData?.duration || ''}</Typography>
          </Card>
        </Grid>
        <Grid xs={6}>
          <Card variant='soft'>
            <Typography level='body-sm' fontWeight={'xl'}>
              TOTAL ISSUED
            </Typography>
            <Typography level='h3'>
              {numberWithCommas(data?.issuedTotal || 0)}{' '}
              <Typography level='body-md' component={'span'} fontWeight={'sm'}>
                tCO2e/year
              </Typography>
            </Typography>
          </Card>
        </Grid>
        <Grid xs={6}>
          <Card variant='soft'>
            <Typography level='body-sm' fontWeight={'xl'}>
              TOTAL RETIRED
            </Typography>
            <Typography level='h3'>
              {numberWithCommas(data?.retiredTotal || 0)}{' '}
              <Typography level='body-md' component={'span'} fontWeight={'sm'}>
                tCO2e/year
              </Typography>
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
};
export default InfoGrid;
