function sum(a, b) {
  return a + b;
}

function median(values) {
  values.sort(function (a, b) {
    return a - b;
  });

  const half = Math.floor(values.length / 2);

  if (values.length % 2 === 0) {
    return (values[half - 1] + values[half]) / 2;
  } else {
    return values[half];
  }
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function moveToLatLng(element, pointerElement, longitude, latitude) {
  // Assuming equirectangular projection and a full world map
  const mapWidth = element.width;
  const mapHeight = element.height;

  // Convert longitude and latitude into pixel coordinates
  let x = (longitude + 180) * (mapWidth / 360);
  let y = (90 - latitude) * (mapHeight / 180);

  // Move the pointer to the calculated position
  const pointer = pointerElement;

  const mapPointerWidth = pointerElement.width;
  const mapPointerHeight = pointerElement.height;

  x = x - mapPointerWidth / 2;
  y = y - mapPointerHeight;

  pointer.style.left = x + 'px';
  pointer.style.top = y + 'px';
}

function numFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);
  return item ? (num / item.value).toFixed(2).replace(regexp, '').concat(item.symbol) : '0';
}

const getSDGImpactRatingDetail = (rating) => {
  switch (rating) {
    case 1:
      return 'Low impact';
    case 2:
      return 'Low - medium impact';
    case 3:
      return 'Medium impact';
    case 4:
      return 'Medium - high impact';
    case 5:
      return 'High impact';
    default:
      return '';
  }
};

const arrayToEnglishString = (arr) => {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];

  // Join all elements except the last one with commas
  const allExceptLast = arr.slice(0, -1).join(', ');

  // Combine with 'and' for the last element
  return `${allExceptLast}, and ${arr[arr.length - 1]}`;
};

const hasBaseUrl = (src) => {
  // Regular expression to check if the src starts with a scheme (e.g., http://, https://, ftp://, etc.)
  const urlPattern = /^[a-zA-Z][a-zA-Z\d+\-.]*:\/\//;
  return urlPattern.test(src);
};

function isObjectUpdated(original, current) {
  if (original === current) {
    return false;
  }

  if (
    typeof original !== 'object' ||
    original === null ||
    typeof current !== 'object' ||
    current === null
  ) {
    return original !== current;
  }

  const originalKeys = Object.keys(original);
  const currentKeys = Object.keys(current);

  if (originalKeys.length !== currentKeys.length) {
    return true;
  }

  for (const key of originalKeys) {
    if (!currentKeys.includes(key) || isObjectUpdated(original[key], current[key])) {
      return true;
    }
  }

  return false;
}

const sortStringsAscending = (arr) => {
  if (!Array.isArray(arr)) {
    throw new Error('Input must be an array');
  }

  return arr.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
};

export {
  sum,
  median,
  numberWithCommas,
  moveToLatLng,
  numFormatter,
  getSDGImpactRatingDetail,
  arrayToEnglishString,
  hasBaseUrl,
  isObjectUpdated,
  sortStringsAscending,
};
