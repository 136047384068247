import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  styled,
} from '@mui/joy';

import Image from '../../../commons/components/Image';
import { regexForNumber } from '../../../commons/constants';
import { getRatingUrl, getSdgTargetUrl } from '../../../services/axios/endpoints';

import ProjectVsImpactScoreGraph from './ProjectVsImpactScoreGraph';
import Rating from './Rating';

const StyledAccordion = styled(Accordion)(() => ({
  '.MuiAccordionSummary-button': {
    '&:hover': { backgroundColor: 'transparent !important' },
  }, // this apply to Summary
}));

const SectionDetail = ({
  type,
  data,
}: {
  type: 'confirmed' | 'rated' | 'insufficient';
  data: any;
}) => {
  const url = getRatingUrl(`E-WEB-Goal-SDG_${data.sdg}.svg`);

  if (type !== 'confirmed')
    return (
      <Box
        sx={{
          width: '100px',
          height: '100px',
          borderRadius: 4,
          overflow: 'hidden',
          display: 'inline-block',
          mr: 2,
        }}
      >
        <Image style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={url} alt='' />
      </Box>
    );

  return (
    <Card
      sx={{
        mb: (theme) => theme.spacing(4),
        background: (theme) => theme.palette.primary.outlinedColor,
      }}
    >
      <Stack gap={3}>
        <Typography level='h4'>{data.data[0].section_name}</Typography>
        <Grid container>
          <Grid xs={6.5} lg={8.5}>
            <Stack direction={'row'} gap={3}>
              <Box
                sx={{
                  width: '100px',
                  height: '100px',
                  borderRadius: 4,
                  overflow: 'hidden',
                  flex: 'none',
                }}
              >
                <Image
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  src={url}
                  alt=''
                />
              </Box>
              <Rating
                score={data.score}
                percentage={data.percentage}
                sections={data?.data.map((section) => section?.target_action)}
              />
            </Stack>
          </Grid>
          <Grid xs={5} lg={3.5} alignItems={'flex-start'} justifyContent={'center'}>
            <ProjectVsImpactScoreGraph rating={data.score} data={data.graph} />
          </Grid>
        </Grid>
        <AccordionGroup
          disableDivider
          variant='plain'
          sx={{
            background: 'transparent',
          }}
        >
          <StyledAccordion>
            <AccordionDetails>
              {data?.data.map((section) => {
                const id = section.target_action.match(regexForNumber)[0]?.toUpperCase() || '';
                const targetUrl = getSdgTargetUrl(id);
                if (!section.summary) return null;
                return (
                  <Stack gap={3} sx={{ mb: 4 }}>
                    <Typography level='h3' fontWeight={'xl'}>
                      {section?.project_activity || ''}
                    </Typography>
                    <Grid container>
                      <Grid xs={10} container>
                        <Grid xs={2.8} lg={1.8} alignContent={'center'}>
                          <Box
                            sx={{
                              width: '100px',
                              height: 'auto',
                              borderRadius: 4,
                              overflow: 'hidden',
                            }}
                          >
                            <Image
                              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                              src={targetUrl}
                            />
                          </Box>
                        </Grid>
                        <Grid xs={8} lg={10}>
                          <Stack direction={'column'} gap={2}>
                            <Typography level='body-lg' fontWeight={'xl'}>
                              {section?.target_action}
                            </Typography>
                            <Typography level='body-md' color='neutral'>
                              {section.summary}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Grid xs={2}>
                        <Box>
                          <Rating
                            score={data.score}
                            percentage={data.percentage}
                            tag={section.claim}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                );
              })}
            </AccordionDetails>
            <Stack direction='row' justifyContent={'center'}>
              <AccordionSummary variant='plain'>
                View SDG target-level contributions
              </AccordionSummary>
            </Stack>
          </StyledAccordion>
        </AccordionGroup>
      </Stack>
    </Card>
  );
};

const SdgSection = ({
  type,
  title,
  description,
  data,
}: {
  type: 'confirmed' | 'rated' | 'insufficient';
  title: string;
  description: string;
  data: any;
}) => {
  if (data.length === 0) return null;
  return (
    <Stack gap={3}>
      <Typography level='h3' fontWeight={'sm'}>
        {title}
      </Typography>
      <Typography level='body-md'>{description}</Typography>
      {type === 'confirmed' ? (
        <Box>
          {data.map((section) => (
            <SectionDetail type={type} data={section} />
          ))}
        </Box>
      ) : (
        <Card
          sx={{
            display: 'inline',
            gap: 2,
            mb: 2,
            background: (theme) => theme.palette.primary.outlinedColor,
          }}
        >
          {data.map((section) => (
            <SectionDetail type={type} data={section} />
          ))}
        </Card>
      )}
    </Stack>
  );
};

export default SdgSection;
