export const getDateSuffix = (d: number) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function dateFormat(dateStr: string) {
  const formatedDate = new Date(dateStr);
  const day = days[formatedDate?.getDay()];
  const month = months[formatedDate?.getMonth()];
  const date = formatedDate?.getDate();
  const suffix = getDateSuffix(formatedDate?.getDate());
  const year = formatedDate?.getFullYear();

  const utcMonth = months[formatedDate?.getUTCMonth()];
  const utcYear = formatedDate?.getUTCFullYear();

  return {
    day,
    month,
    date,
    suffix,
    year,
    utcYear,
    utcMonth,
    mmddyyyy: `${month} ${date}${suffix}, ${year}`,
    mmyyyy: `${utcMonth} ${utcYear}`,
    dayddmmyyyy: `${day}, ${date} ${month} ${year}`,
  };
}
