import { useState } from 'react';

import { Button, FormControl, FormLabel, Input, Stack, Typography } from '@mui/joy';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { string, InferType } from 'yup';

import { ErrorBox, ErrorBoxMessageTypes } from '../../../../commons/components/ErrorBox';
import { makePostRequest } from '../../../../services/axios';
import { forgotPassword } from '../../../../services/axios/endpoints';

const emailSchema = string().required('Please enter your registered email to reset your password');

type EmailType = InferType<typeof emailSchema>;

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [useremail, setEmail] = useState<EmailType>();
  const [error, setError] = useState<ErrorBoxMessageTypes>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const handleCloseError = () => {
    setError(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value?.trim());
  };

  const handleReturnToLogin = () => {
    navigate('/login');
  };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    try {
      setLoading(true);
      setError(null);
      await emailSchema.validate(useremail);
      await makePostRequest(forgotPassword, {
        useremail,
      });
      setIsEmailSent(true);
    } catch (err: any) {
      setError({
        title: !!err?.code
          ? Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message?.join('\n')
            : err?.response?.data?.message
          : err?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack width={'500px'} spacing={3}>
      <Stack>
        {!isEmailSent ? (
          <Button
            startDecorator={<IoIosArrowBack />}
            variant='plain'
            sx={{ width: 'max-content', margin: 0, padding: 0 }}
            onClick={handleReturnToLogin}
          >
            <Typography fontWeight={'sm'} fontSize={'sm'}>
              Return to login
            </Typography>
          </Button>
        ) : null}
        <Typography level='h1' fontSize={28} fontWeight={'sm'}>
          {isEmailSent ? 'Reset password email sent' : 'Reset your password'}
        </Typography>
        {isEmailSent ? (
          <Typography>
            An email has been sent to your email address{' '}
            <Typography fontWeight={'xl'}>{useremail}</Typography>. Follow the directions in the
            email to reset your password.
          </Typography>
        ) : (
          <Typography>
            Enter your email address that you use with your account to continue.
          </Typography>
        )}
      </Stack>
      <ErrorBox handleClose={handleCloseError} error={error} />
      {!isEmailSent ? (
        <>
          <FormControl>
            <form onSubmit={handleForgotPassword}>
              <FormLabel>Email</FormLabel>
              <Input
                variant='outlined'
                fullWidth
                sx={{ padding: 'spacing(0 ,1)', height: '42px' }}
                type='email'
                name='useremail'
                value={useremail}
                onChange={handleInputChange}
              />
              <Button
                size='lg'
                sx={{ width: 'max-content', marginTop: 3 }}
                loading={loading}
                type='submit'
              >
                Continue
              </Button>
            </form>
          </FormControl>
        </>
      ) : null}
      {isEmailSent ? (
        <Button
          size='lg'
          sx={{ width: 'max-content' }}
          onClick={handleReturnToLogin}
          loading={loading}
        >
          Return to login
        </Button>
      ) : null}
    </Stack>
  );
};

export { ForgotPasswordForm };
