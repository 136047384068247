import React, { useState } from 'react';

import { Button, FormControl, FormLabel, Stack, Typography } from '@mui/joy';
import queryString from 'query-string';
import { IoIosArrowBack } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import { object, string, InferType } from 'yup';

import { ErrorBox, ErrorBoxMessageTypes } from '../../../../commons/components/ErrorBox';
import { PasswordInput } from '../../../../commons/components/PasswordInput';
import { makePostRequest } from '../../../../services/axios';
import { resetPassword } from '../../../../services/axios/endpoints';

const resetPasswordFormSchema = object({
  password: string().required('Please enter a new password'),
  confirmPassword: string().required('Please enter a new password'),
});

type FormDataTypes = InferType<typeof resetPasswordFormSchema>;

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  const [formData, setFormData] = useState<FormDataTypes>({ password: '', confirmPassword: '' });
  const [error, setError] = useState<ErrorBoxMessageTypes>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasPasswordReset, setHasPasswordReset] = useState<boolean>(false);

  const handleCloseError = () => {
    setError(null);
  };

  const handleFormDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value?.trim() };
    });
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if (formData.password !== formData.confirmPassword) {
      setError({
        title: 'Passwords do not match',
        description: 'Please make sure the entered passwords are same.',
      });
      return;
    }
    try {
      setLoading(true);
      setError(null);
      const payload = await resetPasswordFormSchema.validate(formData);
      await makePostRequest(resetPassword, {
        resetPasswordToken: token,
        newPassword: payload.password,
      });
      setHasPasswordReset(true);
    } catch (err: any) {
      setError({
        title: !!err?.code
          ? Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message?.join('\n')
            : err?.response?.data?.message
          : err?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReturnToLogin = () => {
    navigate('/login');
  };

  return (
    <Stack width={'500px'} spacing={3}>
      <Stack>
        {!hasPasswordReset ? (
          <Button
            startDecorator={<IoIosArrowBack />}
            variant='plain'
            sx={{ width: 'max-content', margin: 0, padding: 0 }}
            onClick={handleReturnToLogin}
          >
            <Typography fontWeight={'sm'} fontSize={14}>
              Return to login
            </Typography>
          </Button>
        ) : null}
        <Typography level='h1' fontSize={28} fontWeight={'sm'}>
          {hasPasswordReset ? 'Your password has been reset successfully' : 'Reset your password'}
        </Typography>
        <Typography>
          {hasPasswordReset
            ? 'Please return to the login page to sign in to your account using your new password.'
            : 'Please choose and enter a new password'}
        </Typography>
      </Stack>
      <ErrorBox handleClose={handleCloseError} error={error} />
      {!hasPasswordReset ? (
        <FormControl>
          <form onSubmit={handleResetPassword}>
            <FormLabel>Enter New Password</FormLabel>
            <PasswordInput
              value={formData.password}
              onChangeHandler={handleFormDataChange}
              name='password'
            />
            <FormLabel sx={{ marginTop: 3 }}>Confirm New Password</FormLabel>
            <PasswordInput
              value={formData.confirmPassword}
              onChangeHandler={handleFormDataChange}
              name='confirmPassword'
            />

            <Button
              size='lg'
              sx={{ width: 'max-content', marginTop: 3 }}
              loading={loading}
              disabled={!formData.password || !formData.confirmPassword}
              type='submit'
            >
              Continue
            </Button>
          </form>
        </FormControl>
      ) : null}
      {hasPasswordReset ? (
        <Button size='lg' sx={{ width: 'max-content', marginTop: 3 }} onClick={handleReturnToLogin}>
          Return to login
        </Button>
      ) : null}
    </Stack>
  );
};

export { ResetPasswordForm };
