import React from 'react';

import { Stack, Card, Typography, Button, styled, useTheme, Chip } from '@mui/joy';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { capitalizeFirstLetter } from '../../../../commons/components/Captialize';
import Image from '../../../../commons/components/Image';
import { dateFormat } from '../../../../commons/utils/dateFormat';
import { getAssetURL } from '../../../../services/axios/endpoints';

interface CardType {
  id: string;
  title: string;
  link: string;
  post: string;
  access_level: string;
  date: string;
  author: string;
  visual: string;
  state: string;
  tags: { tag_info: { id: string; tag: string } }[];
}
interface props {
  data: CardType;
  type?: string;
  page?: string;
}
const StyledImage = styled(Image)(() => ({
  width: '100%',
  objectFit: 'contain',
}));
const CardComponent = ({ data, type, page }: props) => {
  const { title, visual, date, tags, id } = data;
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <React.Fragment>
      <Card
        sx={{
          padding: 0,
          height: '100%',
          cursor: 'pointer',
          borderRadius: 'none',
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
          overflow: 'hidden',
        }}
        onClick={() => navigate(`/${page}?tag=${id}`)}
      >
        <Stack
          sx={{
            height: '100%',
          }}
        >
          <Stack gap={0}>
            <StyledImage src={getAssetURL(visual, type)} alt='' />
          </Stack>
          <Stack
            sx={{
              px: 2,
              py: 3,
            }}
            gap={4}
            direction={'column'}
            justifyContent={'space-between'}
            height={'100%'}
          >
            <Stack gap={1}>
              <Stack direction={'row'} gap={1}>
                {tags?.map((tag, index) => {
                  return (
                    <Chip
                      variant='outlined'
                      color='neutral'
                      sx={{
                        borderRadius: '3rem',
                        width: 'max-content',
                        padding: '0px 8px',
                      }}
                      key={index}
                    >
                      <Typography fontWeight={'md'} fontSize={'sm'}>
                        {capitalizeFirstLetter(tag?.tag_info?.tag)}
                      </Typography>
                    </Chip>
                  );
                })}
              </Stack>
              <Stack gap={1}>
                <Typography level='h3' fontWeight={'sm'}>
                  {title}
                </Typography>
                <Typography level='title-sm' fontSize={'sm'} fontWeight={'lg'} color='primary'>
                  {dateFormat(date).mmddyyyy}
                </Typography>
              </Stack>
            </Stack>
            <Stack gap={3} justifyContent={'flex-end'} direction={'row'}>
              <Button
                variant='outlined'
                size='sm'
                endDecorator={<FiArrowRight style={{ fontSize: theme.vars.fontSize.xl2 }} />}
              >
                Show more
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </React.Fragment>
  );
};

export default CardComponent;
