import _extends from "@babel/runtime/helpers/esm/extends";
import _toPropertyKey from "@babel/runtime/helpers/esm/toPropertyKey";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["getSelector"],
  _excluded2 = ["colorSchemes", "components", "defaultColorScheme"];
import deepmerge from '@mui/utils/deepmerge';
import cssVarsParser from './cssVarsParser';
function prepareCssVars(theme, _ref = {}) {
  let {
      getSelector
    } = _ref,
    parserConfig = _objectWithoutPropertiesLoose(_ref, _excluded);
  // @ts-ignore - ignore components do not exist
  const {
      colorSchemes = {},
      defaultColorScheme = 'light'
    } = theme,
    otherTheme = _objectWithoutPropertiesLoose(theme, _excluded2);
  const {
    vars: rootVars,
    css: rootCss,
    varsWithDefaults: rootVarsWithDefaults
  } = cssVarsParser(otherTheme, parserConfig);
  let themeVars = rootVarsWithDefaults;
  const colorSchemesMap = {};
  const {
      [defaultColorScheme]: defaultScheme
    } = colorSchemes,
    otherColorSchemes = _objectWithoutPropertiesLoose(colorSchemes, [defaultColorScheme].map(_toPropertyKey));
  Object.entries(otherColorSchemes || {}).forEach(([key, scheme]) => {
    const {
      vars,
      css,
      varsWithDefaults
    } = cssVarsParser(scheme, parserConfig);
    themeVars = deepmerge(themeVars, varsWithDefaults);
    colorSchemesMap[key] = {
      css,
      vars
    };
  });
  if (defaultScheme) {
    // default color scheme vars should be merged last to set as default
    const {
      css,
      vars,
      varsWithDefaults
    } = cssVarsParser(defaultScheme, parserConfig);
    themeVars = deepmerge(themeVars, varsWithDefaults);
    colorSchemesMap[defaultColorScheme] = {
      css,
      vars
    };
  }
  const generateThemeVars = () => {
    let vars = _extends({}, rootVars);
    Object.entries(colorSchemesMap).forEach(([, {
      vars: schemeVars
    }]) => {
      vars = deepmerge(vars, schemeVars);
    });
    return vars;
  };
  const generateStyleSheets = () => {
    const stylesheets = [];
    const colorScheme = theme.defaultColorScheme || 'light';
    function insertStyleSheet(selector, css) {
      if (Object.keys(css).length) {
        stylesheets.push(typeof selector === 'string' ? {
          [selector]: _extends({}, css)
        } : selector);
      }
    }
    insertStyleSheet(getSelector?.(undefined, _extends({}, rootCss)) || ':root', rootCss);
    const {
        [colorScheme]: defaultSchemeVal
      } = colorSchemesMap,
      rest = _objectWithoutPropertiesLoose(colorSchemesMap, [colorScheme].map(_toPropertyKey));
    if (defaultSchemeVal) {
      // default color scheme has to come before other color schemes
      const {
        css
      } = defaultSchemeVal;
      insertStyleSheet(getSelector?.(colorScheme, _extends({}, css)) || `[${theme.attribute || 'data-color-scheme'}="${colorScheme}"]`, css);
    }
    Object.entries(rest).forEach(([key, {
      css
    }]) => {
      insertStyleSheet(getSelector?.(key, _extends({}, css)) || `[${theme.attribute || 'data-color-scheme'}="${key}"]`, css);
    });
    return stylesheets;
  };
  return {
    vars: themeVars,
    generateThemeVars,
    generateStyleSheets
  };
}
export default prepareCssVars;