import axios from 'axios';
import { getRecoil, setRecoil } from 'recoil-nexus';

import { appState } from '../../commons/stores/app';
import { authState, userState } from '../../commons/stores/auth';

import { refreshToken } from './functions';

export const axiosInstance = axios.create({ baseURL: process.env.BASE_URL });
export const authenticatedAxiosInstance = axios.create({ baseURL: process.env.BASE_URL });

authenticatedAxiosInstance.interceptors.request.use((req) => {
  const authStateValues = getRecoil(authState);
  const userStateValues = getRecoil(userState);
  const appStateValues = getRecoil(appState);
  const authHeader = `Bearer ${authStateValues?.access_token}`;
  req.headers.Authorization = authHeader;
  if (userStateValues?.calyx_team && appStateValues.isStaging) {
    req.headers.projectWorkflowState = 'staging';
  }
  return req;
});

authenticatedAxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401 && !error.config._retry) {
      error.config._retry = true;

      const authStateValues = getRecoil(authState);
      const authHeader = `Bearer ${authStateValues?.refresh_token}`;
      const data = await refreshToken(authHeader);
      setRecoil(authState, data);

      authenticatedAxiosInstance.defaults.headers.common['Authorization'] =
        `Bearer ${data.access_token}`;

      return authenticatedAxiosInstance(error?.config);
    }
    return Promise.reject(error);
  }
);

export const makeGetRequest = axiosInstance.get;
export const makePostRequest = axiosInstance.post;
export const makePutRequest = axiosInstance.put;
export const makeDeleteRequest = axiosInstance.delete;

export const makeAuthenticatedGetRequest = authenticatedAxiosInstance.get;
export const makeAuthenticatedPostRequest = authenticatedAxiosInstance.post;
export const makeAuthenticatedPutRequest = authenticatedAxiosInstance.put;
export const makeAuthenticatedDeleteRequest = authenticatedAxiosInstance.delete;
