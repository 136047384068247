import React, { useState } from 'react';

import { Box, Button, Input, Typography } from '@mui/joy';
import { useMutation, MutationFunction } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';

import { snackbarState } from '../../commons/stores/snackerbar';
import { makeAuthenticatedPostRequest } from '../../services/axios';
import { changePassword } from '../../services/axios/endpoints';

const ChangePassword: React.FC = () => {
  const [currentPass, setCurrentPass] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const setSnackbar = useSetRecoilState(snackbarState);

  const changePasswordCall: MutationFunction<unknown> = async () => {
    try {
      const { data } = await makeAuthenticatedPostRequest<unknown>(changePassword, {
        oldPassword: currentPass,
        newPassword: newPassword,
      });
      return data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error.message);
    }
  };

  const { mutate: changePasswordMutate, isPending } = useMutation<unknown, Error>({
    mutationFn: changePasswordCall,
    onSuccess: () => {
      setSnackbar({ message: 'Password changed successfully !', color: 'success' });
      setCurrentPass('');
      setNewPassword('');
      setConfirmPassword('');
    },
    onError: (error) => {
      setSnackbar({ message: error.message || 'Something went wrong', color: 'danger' });
    },
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const { value } = e.target;
    setter(value);
  };

  const handlePassChange = () => {
    if (newPassword !== confirmPassword) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: "New password and confirmation don't match",
      });
      return;
    } else {
      changePasswordMutate();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', gap: 2 }}>
      <Typography level='h4'>Change Password</Typography>
      <Input
        type='password'
        placeholder='Current Password'
        value={currentPass}
        onChange={(e) => handleOnChange(e, setCurrentPass)}
        sx={{ flex: 1 }}
      />
      <Input
        type='password'
        placeholder='New Password'
        value={newPassword}
        onChange={(e) => handleOnChange(e, setNewPassword)}
        sx={{ flex: 1 }}
      />
      <Input
        type='password'
        placeholder='Confirm Password'
        value={confirmPassword}
        onChange={(e) => handleOnChange(e, setConfirmPassword)}
        sx={{ flex: 1 }}
      />
      <Button disabled={isPending} loading={isPending} onClick={handlePassChange}>
        Change Password
      </Button>
    </Box>
  );
};

export default ChangePassword;
