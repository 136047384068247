import { Cell } from '../../commons/components/Table/types';
import { capitalizeString } from '../../commons/utils/arrays';
import { formatNumberWithCommas } from '../../commons/utils/misc';

export const columns = [
  [
    {
      key: 'retiree',
      name: 'Company',
      isSortable: true,
      cellStyle: {
        width: '240px',
      },
    },
    {
      display_name: '<=2020',
      key: 'v_year_minus_4',
      isSortable: true,
      isLoading: true,
      cellStyle: {
        flex: 1,
      },
      contentStyle: {
        justifyContent: 'end',
      },
    },
    {
      display_name: '2021',
      key: 'v_year_minus_3',
      isSortable: true,
      isLoading: true,
      cellStyle: {
        flex: 1,
      },

      contentStyle: {
        justifyContent: 'end',
      },
    },
    {
      display_name: '2022',
      key: 'v_year_minus_2',
      isSortable: true,
      isLoading: true,
      cellStyle: {
        flex: 1,
      },
      contentStyle: {
        justifyContent: 'end',
      },
    },
    {
      display_name: '2023',
      key: 'v_year_minus_1',
      isSortable: true,
      isLoading: true,
      cellStyle: {
        flex: 1,
      },
      contentStyle: {
        justifyContent: 'end',
      },
    },
    {
      display_name: '2024 YTD',
      key: 'v_current_year',
      isSortable: true,
      isLoading: true,
      cellStyle: {
        flex: 1,
      },
      contentStyle: {
        justifyContent: 'end',
      },
    },
    {
      key: 'total_volume',
      name: 'All Years',
      isSortable: true,
      cellStyle: {
        width: '160px',
      },
      contentStyle: {
        justifyContent: 'end',
      },
    },
  ],
] as Cell[][];

export const cellRenderOrder = [
  'retiree',
  'v_year_minus_4',
  'v_year_minus_3',
  'v_year_minus_2',
  'v_year_minus_1',
  'v_current_year',
  'total_volume',
];

export const footerCellOrder = [
  'v_year_minus_4',
  'v_year_minus_3',
  'v_year_minus_2',
  'v_year_minus_1',
  'v_current_year',
  'total_volume',
];

export const defaultFilters = {
  limit: 400,
  sort: {
    total_volume: 'DESC',
  },
};

export const footerCells = [
  {
    display_name: 'Total',
    key: 'total',
    isLoading: true,
    cellStyle: { width: '240px' },
  },
  {
    key: 'v_year_minus_4',
    isLoading: true,
    cellStyle: { flex: 1 },
    contentStyle: {
      justifyContent: 'end',
    },
  },
  {
    key: 'v_year_minus_3',
    isLoading: true,
    cellStyle: { flex: 1 },
    contentStyle: {
      justifyContent: 'end',
    },
  },
  {
    key: 'v_year_minus_2',
    isLoading: true,
    cellStyle: { flex: 1 },
    contentStyle: {
      justifyContent: 'end',
    },
  },
  {
    key: 'v_year_minus_1',
    isLoading: true,
    cellStyle: { flex: 1 },
    contentStyle: {
      justifyContent: 'end',
    },
  },
  {
    key: 'v_current_year',
    isLoading: true,
    cellStyle: { flex: 1 },
    contentStyle: {
      justifyContent: 'end',
    },
  },
  {
    key: 'total_volume',
    cellStyle: { width: '160px' },
    isLoading: true,
    contentStyle: {
      justifyContent: 'end',
    },
  },
] as Cell[];

export const transformFilterData = (data) => {
  const keys = Object.keys(data);
  const addFiltersKey = (data) => {
    if ('project_type' in data || 'project_locations' in data) {
      return {
        ...data,
        filters: transformChildKey([...(data['project_type'] || data['project_locations'])]),
      };
    } else {
      return {
        ...data,
        filters: [],
      };
    }
  };
  const transformChildKey = (arr: any[]) => {
    return arr.map((data) => {
      return addFiltersKey(data);
    });
  };
  return keys.map((key) => ({
    name: key,
    display_name: capitalizeString(key),
    filters: transformChildKey(data[key]),
  }));
};

export const getColumnDetails = (customColumns: { name: string; display_name: string }[]) => {
  const customOrder: string[] = [];
  const updatedColumns = [...columns];
  const transformedCustomColumns: Cell[] = customColumns.map((column) => {
    customOrder.push(column.name);
    return {
      key: column.name,
      name: column.display_name,
      isSortable: true,
      contentStyle: {
        justifyContent: 'end',
      },
      cellStyle: columns?.[0]?.find(({ key }) => key === column?.name)?.cellStyle,
    };
  });
  return {
    columns: [
      [
        updatedColumns[0][0],
        ...transformedCustomColumns,
        updatedColumns[0][updatedColumns[0].length - 1],
      ],
    ],
    order: ['retiree', ...customOrder, 'total_volume'],
  };
};

export const getFooterCells = (data: { [key: string]: string }): Cell[] => {
  if (!data) return [];

  return [
    { key: 'total', name: 'Total', colSpan: 1 },
    ...footerCellOrder.map((key) => ({
      key: key,
      name: formatNumberWithCommas(Number(data[key])),
      colSpan: 1,
      contentStyle: {
        justifyContent: 'end',
      },
    })),
  ]?.map((config, i) => {
    return {
      ...config,
      cellStyle: footerCells?.[i]?.cellStyle,
    };
  });
};
