import { useEffect, useMemo, useRef, useState } from 'react';

import { Card, Skeleton, Stack, Tab, tabClasses, TabList, Tabs, Typography } from '@mui/joy';
import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { IoInformationCircle } from 'react-icons/io5';
import { useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';

import { RatingCircle } from '../../../commons/components/RatingCircle';
import { projectState } from '../../../commons/stores/project';
import { useExecuteCallbackOnIntersection } from '../../../commons/utils/hooks/useExecuteCallbackOnIntersection';
import { useFetchUserLevel } from '../../../commons/utils/user/useFetchUserLevel';
import { track } from '../../../services/analytics';
import {
  EVENT_GHG_SUMMARY_PAGE,
  EVNTAPP_ghg_section_clicked,
  EVNTAPP_tab_additionality,
  EVNTAPP_tab_overcrediting,
  EVNTAPP_tab_overview,
  EVNTAPP_tab_permanence,
  EVNTAPP_view_deep_dive_click,
  PGV_ghg_summary,
  PGVAPP_deep_dive_content_viewed,
  PGVAPP_upgrade_modal_viewed,
} from '../../../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { projectGHGRatingData } from '../../../services/axios/endpoints';

import { HTMLRenderer } from './components/HTMLRenderer';
import { Paragraph } from './components/Paragraph/Paragraph';
import { ReadMoreSection } from './components/ReadMoreSection/ReadMoreSection';
import { Table } from './components/Table/Table';
import { ghgSummaryTransformer } from './constants';

const GhgRating = () => {
  const location = useLocation();
  const [selectedTabIndex, setSelectedTabIndex] = useState<string | number | null>(0);
  const { standard, id, cp_no } = queryString.parse(location.search);
  const { projectData, projectCreditingData } = useRecoilValue(projectState);
  const readMoreSectionRef = useRef<HTMLDivElement>(null);
  const { userLevel } = useFetchUserLevel();

  const hasPermissionForReadMore = userLevel === 3;

  const { data, isLoading } = useQuery({
    queryKey: ['ghg-rating', cp_no],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(projectGHGRatingData, {
        params: { project_id: id, crediting_period_id: cp_no, ghg_standard_code: standard },
      }),
    select: (data) => ghgSummaryTransformer(data?.data?.data),
  });

  const selectedTab = useMemo(
    () => data?.ratingSections?.[selectedTabIndex ?? 0],
    [selectedTabIndex, data]
  );

  const handleTab = (_event, value) => {
    let event = '';
    switch (data?.ratingSections?.[value ?? 0]?.type) {
      case 'overview':
        event = EVNTAPP_tab_overview;
        break;
      case 'additionality':
        event = EVNTAPP_tab_additionality;
        break;
      case 'over_crediting':
        event = EVNTAPP_tab_overcrediting;
        break;
      case 'permanence':
        event = EVNTAPP_tab_permanence;
        break;
      case 'overlapping_claims':
        event = EVNTAPP_tab_overview;
        break;
    }
    track(event, EVENT_GHG_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      page_url: `${location.pathname}${location.search}`,
    });
    track(EVNTAPP_ghg_section_clicked, EVENT_GHG_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      page_url: `${location.pathname}${location.search}`,
      project_name: projectData?.title,
      project_section: selectedTab?.title,
      ghg_rating: projectData?.ghgRatingDetail?.grade,
      project_category: projectData?.projectCategory,
      project_type: projectData?.projectType,
      issuance_size: projectCreditingData?.issuanceSizeCategory,
    });
    setSelectedTabIndex(value);
  };

  const isReadMoreDataAvailable = selectedTab?.readMoreContent?.length > 0;

  const scrollReadMoreIntoView = () => {
    track(EVNTAPP_view_deep_dive_click, EVENT_GHG_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      project_tab: 'GHG',
      project_name: projectData?.title,
      project_section: selectedTab?.title,
      ghg_rating: projectData?.ghgRatingDetail?.grade,
      project_category: projectData?.projectCategory,
      project_type: projectData?.projectType,
      issuance_size: projectCreditingData?.issuanceSizeCategory,
    });
    readMoreSectionRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  useExecuteCallbackOnIntersection(
    readMoreSectionRef,
    () => {
      track(
        hasPermissionForReadMore ? PGVAPP_deep_dive_content_viewed : PGVAPP_upgrade_modal_viewed,
        EVENT_GHG_SUMMARY_PAGE,
        {
          project_id: projectData?.projectId,
          page_url: `${location.pathname}${location.search}`,
          project_tab: 'GHG',
          project_name: projectData?.title,
          project_section: selectedTab?.title,
          ghg_rating: projectData?.ghgRatingDetail?.grade,
          project_category: projectData?.projectCategory,
          project_type: projectData?.projectType,
          issuance_size: projectCreditingData?.issuanceSizeCategory,
        }
      );
    },
    selectedTabIndex,
    '-200px'
  );

  useEffect(() => {
    if (projectData && !!projectCreditingData?.issuanceSizeCategory) {
      track(PGV_ghg_summary, EVENT_GHG_SUMMARY_PAGE, {
        project_id: projectData?.projectId,
        project_name: projectData?.title,
        project_category: projectData?.projectCategory,
        project_type: projectData?.projectType,
        issuance_size: projectCreditingData?.issuanceSizeCategory,
        ghgRating: projectData?.ghgRatingDetail?.grade,
        sdgRating: projectData?.sdgRatingDetail?.grade,
      });
    }
  }, [projectData, projectCreditingData]);

  return isLoading ? (
    <Box paddingBottom={20}>
      <Stack maxWidth={'lg'} margin='auto' spacing={5}>
        <Card sx={{ padding: 3 }}>
          <Box display={'grid'} gridTemplateColumns={'1fr 7fr'} alignItems={'center'} gap={3}>
            <Skeleton variant='circular' width={120} height={120} animation='wave' />
            <Stack spacing={1.5}>
              {Array(5)
                .fill('')
                .map(() => {
                  return (
                    <Skeleton
                      variant='rectangular'
                      width={'100%'}
                      height={'1em'}
                      animation='wave'
                    />
                  );
                })}
            </Stack>
          </Box>
        </Card>
        <Card>
          <Box
            display={'grid'}
            alignItems={'center'}
            gridTemplateColumns={'repeat(5, 1fr)'}
            gap={3}
            marginBottom={2}
          >
            {Array(5)
              .fill('')
              .map(() => {
                return (
                  <Skeleton variant='rectangular' width={'100%'} height={'3em'} animation='wave' />
                );
              })}
          </Box>
          <Skeleton variant='rectangular' width={'100%'} height={'16em'} animation='wave' />
        </Card>
      </Stack>
    </Box>
  ) : (
    <Box paddingBottom={20}>
      <Stack maxWidth={'lg'} margin='auto' spacing={3}>
        <Card>
          <Typography fontSize={'36px'} color='primary' fontWeight={'xl'}>
            GHG Rating
          </Typography>
          <Stack
            marginTop={1}
            direction={'row'}
            alignItems={'center'}
            spacing={data?.isDetailedDataAvailable ? 3 : 6}
          >
            <Stack minWidth={'230px'} alignItems={'flex-start'}>
              <Stack alignItems={'center'} spacing={3}>
                <RatingCircle
                  type='ghg'
                  percentage={projectData?.ghgRatingDetail?.percentage}
                  rating={projectData?.ghgRatingDetail?.grade}
                  size='lg'
                />
                <Typography fontSize={'md'} fontWeight={'lg'} color='primary'>
                  Date of rating : {projectData?.dateOfRating}
                </Typography>
              </Stack>
            </Stack>
            <Typography fontSize={'md'} fontWeight={'md'} color='neutral'>
              <HTMLRenderer
                dangerouslySetInnerHTML={{ __html: data?.ghgSummaryParagraph }}
                colorType='secondary'
              />
            </Typography>
          </Stack>
        </Card>
        <Card>
          {data?.isDetailedDataAvailable ? (
            <>
              <Tabs aria-label='Pipeline' value={selectedTabIndex} onChange={handleTab}>
                <TabList
                  sx={() => {
                    return {
                      pt: 1,
                      justifyContent: 'space-between',
                      [`&& .${tabClasses.root}`]: {
                        flex: 'initial',
                        bgcolor: 'transparent',
                        '&:hover': {
                          bgcolor: 'transparent',
                        },
                      },
                    };
                  }}
                >
                  {data?.ratingSections?.map(({ title }: any, index: number) => {
                    return (
                      <Tab indicatorInset value={index} key={index}>
                        <Typography fontSize={'lg'} paddingX={3} paddingY={1} fontWeight={'xl'}>
                          {title}
                        </Typography>
                      </Tab>
                    );
                  })}
                </TabList>
              </Tabs>
              <Stack spacing={3} padding={3}>
                {selectedTab?.content?.map((item: any, index) => {
                  const type = item?.type;
                  if (type === 'paragraph') {
                    return (
                      <Paragraph
                        key={`${index}-${type}`}
                        textContent={item?.content}
                        sectionRatings={{
                          additionality: projectData?.projectRatings?.additionality,
                          overlapping_claims: projectData?.projectRatings?.overlapping_claims,
                          permanence: projectData?.projectRatings?.permanence,
                          over_crediting: projectData?.projectRatings?.overcrediting_score,
                        }}
                        selectedTab={selectedTab?.type}
                        isReadMoreDataAvailable={isReadMoreDataAvailable}
                        scrollReadMoreIntoView={scrollReadMoreIntoView}
                      />
                    );
                  }
                  if (type === 'table') {
                    return (
                      <Table
                        key={`${index}-${type}`}
                        content={item?.content}
                        tableType={item?.tableType}
                        caption={item?.caption}
                      />
                    );
                  }
                })}
              </Stack>
            </>
          ) : (
            <Stack spacing={2}>
              <Typography level='h2' fontWeight={'lg'}>
                Key Criteria Impacting the GHG Rating
              </Typography>
              <Stack direction={'row'} spacing={5}>
                <Typography>
                  <Typography fontSize={'md'} color='neutral'>
                    <HTMLRenderer
                      colorType='secondary'
                      dangerouslySetInnerHTML={{ __html: data?.description }}
                    />
                  </Typography>
                </Typography>
                <Box minWidth={'400px'}>
                  <Paragraph
                    onlyShowSectionRatings
                    sectionRatings={{
                      additionality: projectData?.projectRatings?.additionality,
                      overlapping_claims: projectData?.projectRatings?.overlapping_claims,
                      permanence: projectData?.projectRatings?.permanence,
                      over_crediting: projectData?.projectRatings?.overcrediting_score,
                    }}
                    selectedTab='overview'
                    isNoDetailedData
                  />
                </Box>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                <Typography
                  marginTop={2}
                  fontSize={'md'}
                  fontWeight={'xl'}
                  startDecorator={<IoInformationCircle />}
                >
                  Additional content will be added soon.
                </Typography>
              </Stack>
            </Stack>
          )}
        </Card>
        {isReadMoreDataAvailable ? (
          <Card sx={{ padding: '0px', overflow: 'hidden' }} ref={readMoreSectionRef}>
            <ReadMoreSection selectedTab={selectedTab} />
          </Card>
        ) : null}
      </Stack>
    </Box>
  );
};

export default GhgRating;
