import { dark } from '../../../../theme/dark';
import { light } from '../../../../theme/light';

export const GHG = 'ghg';
export const SDG = 'sdg';

export const getProjectTypeColor = (projectType: string, isDarkMode: boolean) => {
  const projectTypeColorsDictionary = {
    forest_land: isDarkMode ? dark.graph.forestLand : light.graph.forestLand,
    households_community: isDarkMode
      ? dark.graph.householdCommunity
      : light.graph.householdCommunity,
    manufacturing_industry: isDarkMode
      ? dark.graph.manufacturingIndustry
      : light.graph.manufacturingIndustry,
    renewable_electricity: isDarkMode ? dark.graph.renewableEnergy : light.graph.renewableEnergy,
    renewable_energy: isDarkMode ? dark.graph.renewableEnergy : light.graph.renewableEnergy,
    waste: isDarkMode ? dark.graph.waste : light.graph.waste,
  };
  return projectTypeColorsDictionary?.[projectType];
};

export const getProjectGroupDetails = (data) => {
  if (!data) return { groups: [], details: {} };
  const details = {};
  data.map((d) => {
    d.data.map(({ name, key }) => {
      if (key in details) return;
      details[key] = name;
    });
  });
  const orderedDetails = Object.keys(details)
    .sort()
    .reduce((obj, key) => {
      obj[key] = details[key];
      return obj;
    }, {});
  return {
    groups: Object.keys(orderedDetails),
    details,
  };
};
