export const ghgSummaryTransformer = (data: any) => {
  if (!data?.detailed_data_available) {
    return {
      isDetailedDataAvailable: false,
      description: data?.ghg_key_blurbs,
    };
  }
  const getFormatedTitle = (title) => {
    const text = title.toLowerCase();
    switch (text) {
      case 'overcrediting':
        return 'Over-crediting';
      case 'overlapping claims':
        return 'Overlapping Claims';
      default:
        return title;
    }
  };
  const ghgSummaryParagraph = data?.project_crediting_periods?.[0]?.rating_blurbs
    ?.find(({ blurb_type }) => blurb_type === 'ghg')
    ?.rating_blurb_sections?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim();
  const ratingSections = data?.project_crediting_periods?.[0]?.rating_blurbs
    ?.find(({ blurb_type }) => blurb_type === 'ghg')
    ?.children?.sort((a: any, b: any) => {
      return a?.order - b?.order;
    })
    ?.map((section: any) => {
      return {
        type: section?.blurb_type,
        title: getFormatedTitle(section?.title),
        content: section?.rating_blurb_sections?.map((blurb: any) => {
          const type = blurb?.type;
          if (type === 'paragraph') {
            return {
              type,
              content:
                blurb?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                blurb?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
              title: blurb?.rating_blurb_section_paragraph?.title,
            };
          }
          if (type === 'table') {
            return {
              type,
              content: blurb?.rating_blurb_section_table?.rating_table_attributes
                ?.find(({ name }) => name === 'type')
                ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                ?.map((item: any, index: number) => {
                  return {
                    type: item?.value,
                    status: blurb?.rating_blurb_section_table?.rating_table_attributes
                      ?.find(({ name }) => name === 'status')
                      ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)?.[index]
                      ?.value,
                    description:
                      blurb?.rating_blurb_section_table?.rating_table_attributes
                        ?.find(({ name }) => name === 'description')
                        ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                        ?.[index]?.ckeditor_content_doc?.content?.trim() ??
                      blurb?.rating_blurb_section_table?.rating_table_attributes
                        ?.find(({ name }) => name === 'description')
                        ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                        ?.[index]?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
                  };
                }),
              tableType: blurb?.section_type,
              caption:
                blurb?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                blurb?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
            };
          }
        }),
        readMoreContent: section?.children?.[0]?.rating_blurb_sections
          ?.sort((a: any, b: any) => a?.order - b?.order)
          ?.map((section: any) => {
            const type = section?.type;
            if (type === 'paragraph') {
              return {
                type,
                content:
                  section?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                  section?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
                title: section?.rating_blurb_section_paragraph?.title,
              };
            }
            if (type === 'timeline_table') {
              const timelineDescription =
                section?.children
                  ?.find(({ type }) => type === 'paragraph')
                  ?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                section?.children
                  ?.find(({ type }) => type === 'paragraph')
                  ?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim();
              const timelineArray = section?.rating_blurb_section_table?.rating_table_attributes
                ?.find(({ name }) => name === 'timeline_date')
                ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                ?.map((item: any, index: number) => {
                  return {
                    date: item?.value,
                    description:
                      section?.rating_blurb_section_table?.rating_table_attributes
                        ?.find(({ name }) => name === 'description')
                        ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                        ?.[index]?.ckeditor_content_doc?.content?.trim() ??
                      section?.rating_blurb_section_table?.rating_table_attributes
                        ?.find(({ name }) => name === 'description')
                        ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                        ?.[index]?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
                  };
                })
                ?.sort((a: any, b: any) => {
                  return (
                    new Date(a?.date?.split(':|:')?.[2]?.split(',')?.[0]).getTime() -
                    new Date(b?.date?.split(':|:')?.[2]?.split(',')?.[0]).getTime()
                  );
                });
              return {
                timelineDescription,
                timelineArray,
                type,
              };
            }
            if (type === 'table') {
              return {
                type,
                content: section?.rating_blurb_section_table?.rating_table_attributes
                  ?.find(({ name }) => name === 'type')
                  ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                  ?.map((item: any, index: number) => {
                    return {
                      type: item?.value,
                      status: section?.rating_blurb_section_table?.rating_table_attributes
                        ?.find(({ name }) => name === 'status')
                        ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)?.[
                        index
                      ]?.value,
                      description:
                        section?.rating_blurb_section_table?.rating_table_attributes
                          ?.find(({ name }) => name === 'description')
                          ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                          ?.[index]?.ckeditor_content_doc?.content?.trim() ??
                        section?.rating_blurb_section_table?.rating_table_attributes
                          ?.find(({ name }) => name === 'description')
                          ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                          ?.[
                            index
                          ]?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
                    };
                  }),
                tableType: section?.section_type,
                caption:
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
              };
            }
            if (type === 'title') {
              return {
                type,
                content: section?.rating_blurb_section_title?.title,
                titleType: section?.rating_blurb_section_title?.title_type,
              };
            }
            if (type === 'image') {
              return {
                type,
                imageUrl: section?.rating_blurb_section_image?.image_url,
                imageAltText: section?.rating_blurb_section_image?.alttext,
                description:
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
              };
            }
            if (type === 'map') {
              return {
                type,
                maptilerLayers: section?.rating_blurb_section_map?.maptiler_layers,
                maptilerViewState: section?.rating_blurb_section_map?.maptiler_viewstate,
                maptilerLink: section?.rating_blurb_section_map?.maptiler_link,
                description:
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
              };
            }
            if (type === 'chart') {
              return {
                type,
                description:
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
                chartConfig: section?.rating_blurb_section_chart?.rating_chart_config?.config,
                chartData: section?.rating_blurb_section_chart?.rating_chart_data,
                isHidden: section?.rating_blurb_section_chart?.is_hidden,
                chartType: section?.rating_blurb_section_chart?.rating_chart_config?.chart_type,
                title: section?.rating_blurb_section_chart?.title,
                uniqueIdentifier: section?.rating_blurb_section_chart?.rating_blurb_section_id,
              };
            }
            if (type === 'html') {
              return {
                type,
                htmlContent: section?.rating_blurb_section_html?.html,
                title: section?.rating_blurb_section_html?.title,
              };
            }
          }),
      };
    });

  return {
    ghgSummaryParagraph,
    ratingSections,
    isDetailedDataAvailable: true,
  };
};
