import { atom } from 'recoil';

import { identify } from '../../../services/analytics';
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_INFO } from '../../constants/localStorage';
import { getLocalStorageObject, setLocalStorageObject } from '../../utils/localStorage';

type AuthStateTypes = { refresh_token: string | null; access_token: string | null } | null;
export const AUTH_STATE_KEY = 'authState';
export const authState = atom<AuthStateTypes>({
  key: AUTH_STATE_KEY,
  default: {
    refresh_token: localStorage.getItem(REFRESH_TOKEN),
    access_token: localStorage.getItem(ACCESS_TOKEN),
  },
  effects: [
    ({ onSet }) => {
      onSet((authState) => {
        localStorage.setItem(ACCESS_TOKEN, `${authState?.access_token}`);
        localStorage.setItem(REFRESH_TOKEN, `${authState?.refresh_token}`);
      });
    },
  ],
});

export type UserStateTypes = {
  company: string;
  country: string;
  mfa: string;
  name: string;
  profile_image: string;
  surname: string;
  user_id: string;
  email: string;
  calyx_team: boolean;
  project_access_type: string;
} | null;
export const USER_STATE_KEY = 'user_state';

export const userState = atom<UserStateTypes>({
  key: USER_STATE_KEY,
  default: getLocalStorageObject(USER_INFO),
  effects: [
    ({ onSet }) => {
      onSet((userState) => {
        setLocalStorageObject(USER_INFO, userState);
        identify(`${userState?.email}`, userState);
      });
    },
  ],
});

export const REDIRECTION_URL_STATE_KEY = 'redirection_url_state_key';
export const redirectionURLState = atom<string>({
  key: REDIRECTION_URL_STATE_KEY,
  default: '/',
});
