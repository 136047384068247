import { useSetRecoilState } from 'recoil';

import { authState, userState } from '../../../stores/auth';

const useLogout = () => {
  const setUserInfo = useSetRecoilState(userState);
  const setAuthState = useSetRecoilState(authState);
  const logout = () => {
    setUserInfo(null);
    setAuthState(null);
  };
  return logout;
};

export { useLogout };
