import React, { useEffect, useState } from 'react';

import { Button, Stack, Typography, styled } from '@mui/joy';
import { IoIosArrowBack } from 'react-icons/io';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { InferType, string } from 'yup';

import { ErrorBox, ErrorBoxMessageTypes } from '../../../../commons/components/ErrorBox';
import { MFA_EMAIL, MFA_NEEDED, MFA_USER_ID } from '../../../../commons/constants/localStorage';
import { authState, redirectionURLState } from '../../../../commons/stores/auth';
import { snackbarState } from '../../../../commons/stores/snackerbar';
import { useCountdown } from '../../../../commons/utils/hooks/useCountdown';
import { makePostRequest } from '../../../../services/axios';
import { resendMFA, verifyMFA } from '../../../../services/axios/endpoints';

const MFAInput = styled('input')(({ theme }) => ({
  maxWidth: '46px',
  minWidth: '46px',
  aspectRatio: '1.1/1',
  borderRadius: theme.vars.radius.md,
  border: `1px solid ${theme.vars.palette.neutral.outlinedBorder}`,
  background: theme.vars.palette.background.surface,
  boxShadow: theme.vars.shadow.xs,
  marginRight: theme.spacing(2),
  color: theme.vars.palette.text.tertiary,
  fontFamily: theme.vars.fontFamily.body,
  fontSize: theme.vars.fontSize.md,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  '&:focus': {
    outline: `2px solid ${theme.vars.palette.primary.outlinedColor}`,
    border: 'none',
  },
}));

const mfaCodeSchema = string()
  .length(6, 'Please enter a valid 6 digit verification code sent to your email')
  .required('Please enter a valid 6 digit verification code sent to your email');

type MFACodeType = InferType<typeof mfaCodeSchema>;

const clearMFARelatedLocalStorage = () => {
  localStorage.removeItem(MFA_NEEDED);
  localStorage.removeItem(MFA_EMAIL);
  localStorage.removeItem(MFA_USER_ID);
};

interface PropTypes {
  handleShowLoginForm: () => void;
}

const MFAForm = ({ handleShowLoginForm }: PropTypes) => {
  const redirectionUrl = useRecoilValue(redirectionURLState);
  const setAuth = useSetRecoilState(authState);
  const navigate = useNavigate();
  const [mfaCode, setMFACode] = useState<MFACodeType>('');
  const [error, setError] = useState<ErrorBoxMessageTypes>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isMFAResent, setIsMFAResent] = useState<boolean>(false);
  const [mfaTimer, setMFATimer] = useState<number>(new Date().getTime());
  const setSnackbar = useSetRecoilState(snackbarState);

  const handleCloseError = () => {
    setError(null);
  };

  const handleReturnToLogin = () => {
    clearMFARelatedLocalStorage();
    handleShowLoginForm();
  };

  const handleMFA = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    try {
      setLoading(true);
      setError(null);
      const verification_code = await mfaCodeSchema.validate(mfaCode);
      const apiResponse = await makePostRequest(verifyMFA, {
        user_id: localStorage.getItem(MFA_USER_ID),
        verification_code: Number(verification_code),
      });
      const access_token = apiResponse?.data?.response?.access_token;
      const refresh_token = apiResponse?.data?.response?.refresh_token;
      if (!(!!access_token && !!refresh_token)) {
        setError({
          title: apiResponse?.data?.response?.message ?? 'Something went wrong. Please try again.',
        });
        return;
      }
      setAuth({
        access_token,
        refresh_token,
      });
      clearMFARelatedLocalStorage();
      navigate(redirectionUrl);
    } catch (err: any) {
      setError({
        title: !!err?.code
          ? Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message?.join('\n')
            : err?.response?.data?.message
          : err?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendNewCode = async () => {
    if (isMFAResent) return;
    setIsMFAResent(true);
    setMFATimer(new Date().getTime() + 120000);
    try {
      setError(null);
      await makePostRequest(resendMFA, {
        user_id: localStorage.getItem(MFA_USER_ID),
      });
      setSnackbar({ message: 'MFA Code sent to your email!', color: 'success' });
    } catch (err: any) {
      setError({
        title: !!err?.code
          ? Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message?.join('\n')
            : err?.response?.data?.message
          : err?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const countdownTime = useCountdown(mfaTimer);

  useEffect(() => {
    if (countdownTime[3] <= 0 && countdownTime[2] <= 0) {
      setIsMFAResent(false);
    } else {
      setIsMFAResent(true);
    }
  }, [countdownTime, setIsMFAResent]);

  return (
    <Stack width={'500px'} spacing={3}>
      <Stack>
        <Button
          startDecorator={<IoIosArrowBack />}
          variant='plain'
          sx={{ width: 'max-content', padding: 0, margin: 0 }}
          onClick={handleReturnToLogin}
        >
          <Typography fontWeight={'sm'} fontSize={'sm'}>
            Return to login
          </Typography>
        </Button>
        <Typography level='h1' fontSize={28} fontWeight={'sm'}>
          Check your inbox for a code
        </Typography>
        <Typography>
          Please type in the code we just sent to{' '}
          <Typography fontWeight={'xl'}>{localStorage.getItem(MFA_EMAIL)}</Typography>.
        </Typography>
      </Stack>
      <ErrorBox handleClose={handleCloseError} error={error} />
      <form onSubmit={handleMFA}>
        <OtpInput
          value={mfaCode}
          onChange={setMFACode}
          numInputs={6}
          renderInput={(props) => <MFAInput {...props} />}
        />
        <Button
          size='lg'
          sx={{ width: 'max-content', marginTop: 3 }}
          type='submit'
          loading={loading}
        >
          Verify
        </Button>
      </form>
      <Stack direction={'column'} spacing={1}>
        <Typography>Please check your spam folder. The code will expire in 10 minutes.</Typography>
        {isMFAResent ? (
          <Typography>
            Try again in {`${countdownTime[2]}`.padStart(2, '0')}:
            {`${countdownTime[3]}`.padStart(2, '0')}{' '}
            <Button
              variant='plain'
              sx={{ margin: 0, padding: 0 }}
              onClick={handleSendNewCode}
              disabled={isMFAResent}
            >
              <Typography sx={{ textDecoration: 'underline' }}>Send a new code</Typography>
            </Button>
          </Typography>
        ) : (
          <Typography>
            Didn’t receive the code?{' '}
            <Button variant='plain' sx={{ margin: 0, padding: 0 }} onClick={handleSendNewCode}>
              <Typography sx={{ textDecoration: 'underline' }}>Send a new code</Typography>
            </Button>
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export { MFAForm };
