function areObjectsEqual(x: any, y: any) {
  return x && y && typeof x === 'object' && typeof y === 'object'
    ? Object.keys(x).length === Object.keys(y).length &&
        Object.keys(x).reduce(function (isEqual, key) {
          return isEqual && areObjectsEqual(x[key], y[key]);
        }, true)
    : x === y;
}

export const areArraysEqual = (a1: any, a2: any) =>
  a1.length === a2.length && a1.every((o: any, idx: any) => areObjectsEqual(o, a2[idx]));

export const groupObjectsByCommonKey = (dataArray: any, groupByKey: string) => {
  const itemsGroupedByKey: any = {};
  dataArray?.forEach((item: any) => {
    if (itemsGroupedByKey?.hasOwnProperty(item?.[groupByKey])) {
      itemsGroupedByKey?.[item?.[groupByKey]]?.push(item);
    } else {
      Object.defineProperty(itemsGroupedByKey, item?.[groupByKey], {
        value: [item],
        writable: true,
        enumerable: true,
      });
    }
  });
  return itemsGroupedByKey;
};

export const sortByKey = (array: any[], key: string) => {
  return array.sort(function (a, b) {
    const x = a[key];
    const y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const getArrayDepth = <T>(arr: T[], childrenKey: keyof T): number => {
  let maxDepth = 0;

  const findDepth = (array: T[], currentDepth: number): void => {
    array.forEach((item) => {
      maxDepth = Math.max(maxDepth, currentDepth);
      if (item[childrenKey] && Array.isArray(item[childrenKey])) {
        findDepth(item[childrenKey] as unknown as T[], currentDepth + 1);
      }
    });
  };

  findDepth(arr, 1);
  return maxDepth;
};

export const capitalizeString = (str: string): string => {
  return str
    .split('_') // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(' '); // Join the words with a space
};
