import React, { useMemo, useState } from 'react';

import { Box, IconButton, Input, Stack, styled } from '@mui/joy';

import useDebouncedInput from '../../utils/hooks/useDebouncedInput';

import { AnimatedArrow, AnimatedBox, StyledCheckBox } from './styles';
import { Filter, FilterObject, FilterType, Section } from './types';

export const ArrowIconHoverContainer = styled(Box)(({ theme }) => ({
  height: '20px',
  width: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '0.1s ease',
  ':hover': {
    background: theme.palette.primary.outlinedHoverBg,
  },
}));

type FilterListProps = {
  sections: Section[];
  parentFilter: Filter;
  updateFilter: (
    parentFilter: Filter,
    valueFilter: Filter,
    isChecked?: boolean | undefined
  ) => boolean | undefined;
  isSelected: (parent: Filter, value: Filter) => boolean;
  isAnyChildFilterChecked: (parent: Filter, value?: Filter) => boolean;
  onChange: (filter: FilterObject) => void;
  clearFilter: (parentKey?: string | undefined) => void;
  getFilterValue: (parentKey: string) => string | number | null;
  updateTextFilter: (key: string, value: string) => boolean;
  getTotalFilterCount: (parentKey: string) => number;
};

type FilterProps = {
  data: Filter[];
  parentFilter: Filter;
  isSelected: (parent: Filter, value: Filter) => boolean;
  isAnyChildFilterChecked: (parent: Filter, value?: Filter) => boolean;
  getTotalFilterCount: (parentKey: string) => number;
  updateFilter: (
    parentFilter: Filter,
    valueFilter: Filter,
    isChecked?: boolean | undefined
  ) => boolean | undefined;
};

const Filter: React.FC<FilterProps> = ({
  data,
  parentFilter,
  updateFilter,
  isSelected,
  isAnyChildFilterChecked,
  getTotalFilterCount,
}) => {
  const [showFilters, setShowFilters] = useState(new Set());

  const handleShowFilter = (key: string) => {
    setShowFilters((prevSet) => {
      const newSet = new Set(prevSet);
      if (newSet.has(key)) {
        newSet.delete(key);
      } else {
        newSet.add(key);
      }
      return newSet;
    });
  };

  const handleOnChange = (valueFilter: Filter) => {
    updateFilter(parentFilter, valueFilter);
  };

  return (
    <Stack sx={{ ml: 3, mt: 1 }}>
      {data.map((filter) => (
        <>
          <Stack
            direction={'row'}
            alignItems={'center'}
            key={filter.name}
            gap={1}
            sx={{
              background: (theme) =>
                showFilters.has(filter.display_name) && filter.filters.length > 0
                  ? theme.palette.primary.outlinedHoverBg
                  : '',
            }}
          >
            <IconButton
              variant='plain'
              onClick={() => handleShowFilter(filter.display_name)}
              sx={{ visibility: filter.filters.length > 0 ? 'visible' : 'hidden' }}
            >
              <AnimatedArrow
                isOpen={showFilters.has(filter.display_name) && filter.filters.length > 0}
              />
            </IconButton>
            <StyledCheckBox
              color='primary'
              checked={isSelected(parentFilter, filter)}
              label={`${filter.display_name} ${filter.filters.length > 0 ? `(${getTotalFilterCount(filter.name)})` : ''}`}
              indeterminate={
                filter.filters.length > 0
                  ? isAnyChildFilterChecked(parentFilter, filter) &&
                    !isSelected(parentFilter, filter)
                  : false
              }
              onChange={() => handleOnChange(filter)}
            />
          </Stack>
          <AnimatedBox isOpen={showFilters.has(filter.display_name) && filter.filters.length > 0}>
            <Filter
              isSelected={isSelected}
              data={filter.filters}
              parentFilter={parentFilter}
              updateFilter={updateFilter}
              isAnyChildFilterChecked={isAnyChildFilterChecked}
              getTotalFilterCount={getTotalFilterCount}
            />
          </AnimatedBox>
        </>
      ))}
    </Stack>
  );
};

const FilterList: React.FC<FilterListProps> = ({
  sections,
  parentFilter,
  updateFilter,
  isSelected,
  isAnyChildFilterChecked,
  getFilterValue,
  updateTextFilter,
  getTotalFilterCount,
}) => {
  const [input, handleInputChange] = useDebouncedInput(getFilterValue, (value) =>
    updateTextFilter(parentFilter.name, value)
  );
  const type = useMemo(() => parentFilter.type, [parentFilter]);
  const [showFilters, setShowFilters] = useState(new Set());

  const handleShowFilter = (key: string) => {
    setShowFilters((prevSet) => {
      const newSet = new Set(prevSet);
      if (newSet.has(key)) {
        newSet.delete(key);
      } else {
        newSet.add(key);
      }
      return newSet;
    });
  };

  return (
    <Stack sx={{ overflowY: 'auto', overflowX: 'hidden', pt: 2 }}>
      {type === FilterType.TEXT ? (
        <Stack direction={'row'} alignItems={'center'} sx={{ pt: 1, px: 1 }}>
          <Input type='text' placeholder='Search' value={input} onChange={handleInputChange} />
        </Stack>
      ) : (
        <>
          <StyledCheckBox
            color='primary'
            indeterminate={
              sections.length > 0
                ? isAnyChildFilterChecked(parentFilter) && !isSelected(parentFilter, parentFilter)
                : false
            }
            checked={isSelected(parentFilter, parentFilter)}
            label={'(Select all)'}
            onChange={(e) => updateFilter(parentFilter, parentFilter, e.target.checked)}
            sx={{ mb: 1 }}
          />
          {(sections || []).map((section) => (
            <React.Fragment key={section.name}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{
                  background: 'transparent',
                }}
              >
                <IconButton
                  variant='plain'
                  onClick={() => handleShowFilter(section.display_name)}
                  sx={{
                    visibility: section.filters.length > 0 ? 'visible' : 'hidden',
                  }}
                >
                  <ArrowIconHoverContainer>
                    <AnimatedArrow
                      isOpen={showFilters.has(section.display_name) && section.filters.length > 0}
                    />
                  </ArrowIconHoverContainer>
                </IconButton>
                <StyledCheckBox
                  color='primary'
                  label={`${section.display_name} ${section.filters.length > 0 ? `(${getTotalFilterCount(section.name)})` : ''}`}
                  checked={isSelected(parentFilter, section)}
                  indeterminate={
                    section.filters.length > 0
                      ? isAnyChildFilterChecked(parentFilter, section) &&
                        !isSelected(parentFilter, section)
                      : false
                  }
                  onChange={(e) => updateFilter(parentFilter, section, e.target.checked)}
                />
              </Stack>
              <AnimatedBox
                isOpen={showFilters.has(section.display_name) && section.filters.length > 0}
              >
                <Filter
                  data={section.filters}
                  parentFilter={parentFilter}
                  isSelected={isSelected}
                  updateFilter={updateFilter}
                  isAnyChildFilterChecked={isAnyChildFilterChecked}
                  getTotalFilterCount={getTotalFilterCount}
                />
              </AnimatedBox>
            </React.Fragment>
          ))}
        </>
      )}
    </Stack>
  );
};

export default FilterList;
