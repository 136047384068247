import { useState } from 'react';

import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  Grid,
  Option,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/joy';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useParams } from 'react-router-dom';

import { track } from '../../../../services/analytics';
import {
  EVENT_RETIREMENT_INSIGHTS_COMPANY_DRILL_DOWN_PAGE,
  EVNT_retirement_company_breakdown_toggled,
  EVNT_retirement_company_chart_breakdown_selected,
} from '../../../../services/analytics/events';

import BarChart from './components/BarChart';
import DoughnutChart from './components/DoughnutChart';

export type BreakdownByTypes =
  | 'ghg_rating'
  | 'sdg_rating'
  | 'region'
  | 'country'
  | 'attributes'
  | 'project_category'
  | 'project_type';

export type ChartTypes = 'credits' | 'projects';

interface BreakdownByTypeOption {
  key: BreakdownByTypes;
  label: string;
}

const breakdownByTypes: Array<BreakdownByTypeOption> = [
  {
    key: 'project_category',
    label: 'Project Category',
  },
  {
    key: 'project_type',
    label: 'Project Type',
  },
  {
    key: 'region',
    label: 'Region',
  },
  {
    key: 'country',
    label: 'Country',
  },
  {
    key: 'ghg_rating',
    label: 'GHG Rating',
  },
  {
    key: 'sdg_rating',
    label: 'SDG Rating',
  },
  {
    key: 'attributes',
    label: 'Project Attributes',
  },
];

interface ChartOption {
  key: ChartTypes;
  label: string;
}

const chartTypes: Array<ChartOption> = [
  {
    key: 'projects',
    label: 'Projects',
  },
  {
    key: 'credits',
    label: 'Credits',
  },
];

export const BreakdownCharts = () => {
  const { retiree } = useParams();
  const [selectedBreakdownByType, setSelectedBreakdownByType] = useState<BreakdownByTypeOption>(
    breakdownByTypes[0]
  );
  const [selectedChartType, setSelectedChartType] = useState<ChartOption>(chartTypes[1]);
  const [includeNonRatedProjects, setIncludeNonRatedProjects] = useState<boolean>(true);

  const handleBreakdownByTypeChange = (breakdownBy: BreakdownByTypeOption) => {
    track(
      EVNT_retirement_company_chart_breakdown_selected,
      EVENT_RETIREMENT_INSIGHTS_COMPANY_DRILL_DOWN_PAGE,
      {
        retirement_company: retiree,
        data_type: breakdownBy?.label,
      }
    );
    setSelectedBreakdownByType(breakdownBy);
  };

  const handleSelectChartType = (rating: ChartOption) => {
    track(
      EVNT_retirement_company_breakdown_toggled,
      EVENT_RETIREMENT_INSIGHTS_COMPANY_DRILL_DOWN_PAGE,
      {
        retirement_company: retiree,
        toggle_applied: rating?.label,
        data_type: selectedBreakdownByType?.label,
      }
    );
    setSelectedChartType(rating);
  };

  const handleNonRatedToggle = (value: boolean) => {
    track(
      EVNT_retirement_company_breakdown_toggled,
      EVENT_RETIREMENT_INSIGHTS_COMPANY_DRILL_DOWN_PAGE,
      {
        retirement_company: retiree,
        toggle_applied: `${value ? 'Include' : 'Exclude'} ron-rated projects`,
        data_type: selectedBreakdownByType?.label,
      }
    );
    setIncludeNonRatedProjects(value);
  };

  return (
    <Card>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Stack gap={3} direction={'row'} alignItems={'center'}>
          <Typography fontWeight={'xl'} fontSize={'xl'} color='primary'>
            Show breakdown by
          </Typography>
          <Select
            defaultValue={'ghg_rating'}
            endDecorator={<ChevronDownIcon />}
            value={selectedBreakdownByType.key}
          >
            {breakdownByTypes?.map((breakdownBy) => {
              const { key, label } = breakdownBy;
              return (
                <Option
                  key={key}
                  value={key}
                  onClick={() => handleBreakdownByTypeChange(breakdownBy)}
                >
                  {label}
                </Option>
              );
            })}
          </Select>
        </Stack>
        <Stack alignItems={'center'} direction={'row'} gap={2}>
          <Stack alignItems={'center'} direction={'row'} gap={1}>
            <Typography fontSize={'sm'} color='primary'>
              Include non-rated projects
            </Typography>
            <Switch
              checked={includeNonRatedProjects}
              onChange={(event) => handleNonRatedToggle(event.target.checked)}
            />
          </Stack>
          <Divider orientation='vertical' />
          <ButtonGroup color='primary'>
            {chartTypes.map((rating) => {
              const { key, label } = rating;
              const isSelected = selectedChartType.key === key;
              return (
                <Button
                  key={key}
                  variant={isSelected ? 'solid' : 'outlined'}
                  sx={{ width: 'max-content' }}
                  onClick={() => handleSelectChartType(rating)}
                >
                  {label}
                </Button>
              );
            })}
          </ButtonGroup>
        </Stack>
      </Stack>
      <Grid container width={'100%'} justifyContent={'space-between'} mt={2}>
        <Grid xs={12} lg={5} p={0} pr={{ xs: 0, lg: 4 }}>
          <Card variant='soft'>
            <Typography fontSize={'xl'} fontWeight={700} color={'primary'}>
              Credits Retired by {selectedBreakdownByType?.label}
            </Typography>
            <DoughnutChart
              breakdown_type={selectedBreakdownByType.key}
              chartType={selectedChartType.key}
              include_non_rated_projects={includeNonRatedProjects}
            />
          </Card>
        </Grid>
        <Grid xs={12} lg={7} p={0} mt={{ xs: 2, lg: 0 }}>
          <Card variant='soft'>
            <Typography fontSize={'xl'} fontWeight={700} color={'primary'}>
              Credits Retired by Year and {selectedBreakdownByType?.label}
            </Typography>
            <BarChart
              breakdown_type={selectedBreakdownByType.key}
              chartType={selectedChartType.key}
              include_non_rated_projects={includeNonRatedProjects}
            />
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
};
