import { Box, Chip } from '@mui/joy';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';

import { theme } from '../../../../../theme';
import { dark } from '../../../../../theme/dark';
import { light } from '../../../../../theme/light';

export const getChipInfoFromStatusNumber = (status: number, mode: string) => {
  let icon: React.ReactNode | null = <></>;
  switch (status) {
    case 0:
      icon = (
        <Chip
          variant='outlined'
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${mode === 'dark' ? dark.SDG[5] : light.SDG[5]}`,
              color: theme.palette.success.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          Low Risk
        </Chip>
      );
      break;
    case 1:
      icon = (
        <Chip
          variant='outlined'
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${mode === 'dark' ? dark.SDG[5] : light.SDG[5]}`,
              color: theme.palette.success.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          Low-Medium Risk
        </Chip>
      );
      break;
    case 2:
      icon = (
        <Chip
          variant='outlined'
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${theme.palette.warning.outlinedBorder}`,
              color: theme.palette.warning.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          Medium Risk
        </Chip>
      );
      break;
    case 3:
      icon = (
        <Chip
          variant='outlined'
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${theme.palette.warning.outlinedBorder}`,
              color: theme.palette.warning.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          Medium-High Risk
        </Chip>
      );
      break;
    case 4:
      icon = (
        <Chip
          variant='outlined'
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${theme.palette.danger.outlinedBorder}`,
              color: theme.palette.danger.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          High Risk
        </Chip>
      );
      break;
    case 5:
      icon = (
        <Box position={'relative'} top={4}>
          <FaCheckCircle color={theme.palette.primary.outlinedActiveBg} size={24} />
        </Box>
      );
      break;
    case 6:
      icon = (
        <Box position={'relative'} top={4}>
          <FaMinusCircle color={mode === 'dark' ? dark.GHG['C+'] : dark.GHG['C+']} size={24} />
        </Box>
      );
      break;
    case 7:
      icon = (
        <Box position={'relative'} top={4}>
          <IoMdCloseCircle color={mode === 'dark' ? dark.GHG['E'] : dark.GHG['E']} size={24} />
        </Box>
      );
      break;
    case 8:
      icon = (
        <Chip
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${theme.palette.neutral.outlinedBorder}`,
              color: theme.palette.neutral.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          N/A
        </Chip>
      );
      break;

    default:
      icon = null;
      break;
  }
  return { icon };
};
