import { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
  useColorScheme,
} from '@mui/joy';
import getSymbolFromCurrency from 'currency-symbol-map';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IoInformationCircle } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import Image from '../../../../commons/components/Image';
import { RatingCircle } from '../../../../commons/components/RatingCircle';
import { dateFormat } from '../../../../commons/utils/dateFormat';
import { formatNumberWithCommas, getProjectAttributes } from '../../../../commons/utils/misc';
import { track } from '../../../../services/analytics';
import {
  EVENT_WHERE_TO_BUY_PAGE,
  EVNTAPP_bundle_project_name,
  EVNTAPP_portfolio_bundle_dropdown,
  EVNTAPP_project_name,
  EVNTAPP_register_interest,
  EVNTAPP_view_more_on_seller_website,
} from '../../../../services/analytics/events';
import { assetBaseUrl, getVendorLogoURL } from '../../../../services/axios/endpoints';
import { theme } from '../../../../theme';
import { NormaliseCSSWrapper } from '../../../project/ghg_rating/components/HTMLRenderer';
import { RegisterInterestModal } from '../RegisterInterestModal';

interface PropTypes {
  data: any;
  parentData?: any;
  isChild: boolean;
  index: number;
}

const VendorImage = styled(Image)(() => ({
  width: '80px',
  height: '80px',
  objectFit: 'contain',
}));

const WhereToBuyCard = (props: PropTypes) => {
  const type = props?.data?.meta_data?.[0]?.type;
  const title = props?.data?.project_name;
  const description = props?.data?.project_description;
  const ghgRiskRating = props?.data?.ghg_risk_rating;
  const updatedGhgRating = props?.data?.updated_ghg_rating;
  const ghgRiskRatingPercentage = props?.data?.ghg_risk_rating_percentage;
  const sdgImpactRating = props?.data?.sdg_impact_rating;
  const sdgImpactRatingPercentage = props?.data?.sdg_impact_rating_percentage;
  const projectType = props?.data?.project_types?.[0]?.project_type?.display_name;
  const projectId = props?.data?.project_id;
  const ghgStandard = props?.data?.ghg_standard;
  const projectVintageYear = props?.data?.project_vintage;
  const location = props?.data?.locations?.[0]?.location?.display_name;
  const vendorLogo = props?.data?.vendor?.logo;
  const vendorName = props?.data?.vendor?.name;
  const parentVendorName = props?.parentData?.vendor?.name;
  const updatedAt = props?.data?.updated_at;
  const availableTonnes = props?.data?.inventory_info?.[0]?.volume;
  const price = props?.data?.price_info?.[0]?.price;
  const priceCurrency = props?.data?.price_info?.[0]?.currency;
  const minimumBuyVolume = props?.data?.inventory_info?.[0]?.min_purchase_vol;
  const isInventoryAvailable = props?.data?.inventory_info?.[0]?.available_inventory === 1;
  const isInventoryAccessible = props?.data?.inventory_info?.[0]?.access_to_inventory === 1;
  const childProjects = props?.data?.child_projects;
  const isChild = props?.isChild;
  const isRightsizingDone = props?.data?.rightsizing_done;
  const creditingPeriodId = props?.data?.crediting_period_id;
  const ctaType = props?.data?.meta_data?.[0]?.cta;
  const ctaWebsite = props?.data?.meta_data?.[0]?.website;
  const updatedGHGRatingPercentage = props?.data?.updated_ghg_rating_percentage;
  const projectLogo = props?.data?.project_types?.[0]?.project_type?.logo;
  const projectAttributes = getProjectAttributes(props?.data?.project_attributes).attributeNames;
  const projectUUID = props?.data?.id;
  const { mode } = useColorScheme();

  const isBundle = type === 'portfolio' && !isChild;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isRegisterInterestModalOpen, setIsRegisterInterestModalOpen] = useState<boolean>(false);

  const toggleExpand = () => {
    track(EVNTAPP_portfolio_bundle_dropdown, EVENT_WHERE_TO_BUY_PAGE);
    setIsExpanded((prev) => !prev);
  };

  const handleRegisterInterest = () => {
    track(EVNTAPP_register_interest, EVENT_WHERE_TO_BUY_PAGE);
    setIsRegisterInterestModalOpen(true);
  };

  const ghgRatingText = useMemo(
    () => (isBundle ? updatedGhgRating : ghgRiskRating),
    [isBundle, updatedGhgRating, ghgRiskRating]
  );

  const ghgRatingPercentage = useMemo(
    () => (isBundle ? updatedGHGRatingPercentage : ghgRiskRatingPercentage),
    [isBundle, updatedGHGRatingPercentage, ghgRiskRatingPercentage]
  );

  const handleViewMoreOnSellerWebsite = () => {
    track(EVNTAPP_view_more_on_seller_website, EVENT_WHERE_TO_BUY_PAGE);
  };

  const handleProjectNameClick = () => {
    track(isBundle ? EVNTAPP_bundle_project_name : EVNTAPP_project_name, EVENT_WHERE_TO_BUY_PAGE);
  };

  const logoStyles = (url: string) => {
    const logo = url.toLowerCase();
    // add a black background fort these two logos in light mode
    if (
      (logo.endsWith('cloverly-logo.png') || logo.endsWith('tradewater_new_logo.png')) &&
      mode === 'light'
    )
      return {
        backgroundColor: theme.palette.common.black,
        borderRadius: theme.radius.sm,
        padding: theme.spacing(1),
      };
    return {};
  };

  return (
    <Card
      sx={{
        marginY: isChild ? 2 : 4,
        padding: isChild ? 0 : 3,
        position: 'relative',
        borderRight: isChild ? 'none' : null,
        borderLeft: isChild ? 'none' : null,
        borderBottom: isChild ? 'none' : null,
        borderRadius: isChild ? '0px' : null,
        minHeight: isChild ? '160px' : null,
        pb: projectAttributes?.length > 0 ? 8 : isChild ? 0 : 3,
      }}
    >
      <RegisterInterestModal
        open={isRegisterInterestModalOpen}
        handleClose={() => setIsRegisterInterestModalOpen(false)}
        projectName={title}
        projectId={projectUUID}
      />
      <Grid container gap={1} justifyContent={'space-between'} alignItems={'center'}>
        <Grid xs={12} lg={7} pt={3}>
          {isChild ? null : (
            <Chip
              color='primary'
              size='lg'
              variant='outlined'
              sx={{ position: 'absolute', top: 32, left: 32, padding: '0px 8px' }}
            >
              <Typography
                color='primary'
                fontSize={'sm'}
                fontWeight={'lg'}
                textTransform={'capitalize'}
              >
                {isBundle ? 'Portfolio/Bundle' : type}
              </Typography>
            </Chip>
          )}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={1}
            mt={2}
          >
            <Stack direction={'row'} alignItems={'center'} spacing={2} width={'65%'}>
              <Box sx={{ width: '60px', height: '60px' }}>
                {projectLogo || isBundle ? (
                  <Image
                    src={
                      isBundle
                        ? getVendorLogoURL('portfolio.svg')
                        : `${assetBaseUrl}/${projectLogo}`
                    }
                    alt=''
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                ) : null}
              </Box>
              <Stack width={'70%'} spacing={1}>
                {!isBundle ? (
                  <Typography
                    fontSize={'sm'}
                    color='neutral'
                    sx={(theme) => {
                      return { color: theme.palette.text.secondary };
                    }}
                  >
                    {ghgStandard}
                    {projectId}
                  </Typography>
                ) : null}
                {!!projectId && !!ghgStandard ? (
                  <Link
                    to={`/executive_summary?standard=${ghgStandard}&id=${projectId}&cp_no=${creditingPeriodId}`}
                    onClick={handleProjectNameClick}
                  >
                    <Typography
                      fontSize={'lg'}
                      sx={(theme) => {
                        return { color: theme.palette.text.primary };
                      }}
                      color='primary'
                    >
                      {title}
                    </Typography>
                  </Link>
                ) : (
                  <Typography
                    fontSize={'lg'}
                    sx={(theme) => {
                      return { color: theme.palette.text.primary };
                    }}
                    color='primary'
                  >
                    {title}
                  </Typography>
                )}
                {!isBundle ? (
                  <Typography
                    fontSize={'sm'}
                    color='neutral'
                    sx={(theme) => {
                      return { color: theme.palette.text.secondary };
                    }}
                  >
                    Vintage {projectVintageYear}, {location}
                  </Typography>
                ) : null}
                {!isBundle ? (
                  <Typography
                    fontSize={'16px'}
                    color='primary'
                    sx={(theme) => {
                      return { color: theme.palette.text.primary };
                    }}
                  >
                    {projectType}
                  </Typography>
                ) : null}
                {isBundle ? (
                  <Typography
                    fontWeight={'sm'}
                    fontSize={'md'}
                    color='neutral'
                    sx={(theme) => {
                      return { color: theme.palette.text.secondary };
                    }}
                  >
                    <NormaliseCSSWrapper>
                      <span dangerouslySetInnerHTML={{ __html: description }} />
                    </NormaliseCSSWrapper>
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
            <Box
              display={'grid'}
              gridTemplateColumns={'repeat(2, 1fr)'}
              alignItems={'center'}
              gap={1}
              width={'35%'}
            >
              {!isRightsizingDone && (!!ghgRiskRating || (isBundle && !!updatedGhgRating)) ? (
                <Stack
                  justifyContent={'center'}
                  paddingY={3}
                  spacing={2}
                  height={'100%'}
                  alignItems={'center'}
                  width={'120px'}
                >
                  <RatingCircle
                    type='ghg'
                    percentage={ghgRatingPercentage}
                    rating={ghgRatingText}
                    size={isChild ? 'md' : 'lg'}
                  />
                  <Typography
                    fontSize={'md'}
                    fontWeight={'sm'}
                    color='primary'
                    sx={(theme) => {
                      return { color: theme.palette.text.primary };
                    }}
                  >
                    GHG Rating
                  </Typography>
                </Stack>
              ) : (
                <div />
              )}
              {!!sdgImpactRating ? (
                <Stack
                  justifyContent={'center'}
                  paddingY={3}
                  spacing={2}
                  height={'100%'}
                  alignItems={'center'}
                  width={'120px'}
                >
                  <RatingCircle
                    type='sdg'
                    percentage={sdgImpactRatingPercentage}
                    rating={sdgImpactRating}
                    size={isChild ? 'md' : 'lg'}
                  />
                  <Typography
                    fontSize={'md'}
                    fontWeight={'md'}
                    color='primary'
                    sx={(theme) => {
                      return { color: theme.palette.text.primary };
                    }}
                  >
                    SDG Rating
                  </Typography>
                </Stack>
              ) : null}
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} lg={4}>
          {isChild ? (
            isRightsizingDone ? (
              <Card sx={{ mt: 2 }}>
                <Typography
                  fontWeight={'xl'}
                  fontSize={'sm'}
                  color='neutral'
                  sx={(theme) => {
                    return { color: theme.palette.text.secondary };
                  }}
                >
                  As noted above, {parentVendorName} has canceled a specific amount of credits for
                  this project, normally rated an {ghgRiskRating}, to create an {updatedGhgRating}{' '}
                  portfolio. For more information, see{' '}
                  <Link to='https://calyxglobal.com/resource-post?q=14' target='_blank'>
                    Rightsizing Carbon Credits
                  </Link>
                  .
                </Typography>
              </Card>
            ) : null
          ) : (
            <Card>
              <Stack spacing={3} justifyContent={'space-between'} height={'100%'}>
                <Box display={'flex'} gap={3}>
                  <Stack alignItems={'center'} width={'100px'} spacing={1}>
                    <VendorImage
                      src={getVendorLogoURL(vendorLogo)}
                      style={logoStyles(vendorLogo)}
                      alt=''
                    />
                    <Typography fontSize={'xs'} fontWeight={'xl'}>
                      {vendorName}
                    </Typography>
                  </Stack>
                  <Stack spacing={0.5}>
                    <Typography
                      fontSize={'xs'}
                      fontWeight={'xl'}
                      color='neutral'
                      sx={(theme) => {
                        return { color: theme.palette.text.secondary };
                      }}
                    >
                      Information Updated : {dateFormat(updatedAt).mmddyyyy}
                    </Typography>
                    <Typography
                      fontSize={'md'}
                      fontWeight={'xl'}
                      sx={(theme) => {
                        return { color: theme.palette.text.primary };
                      }}
                    >
                      {availableTonnes} tonnes available
                    </Typography>
                    <Typography
                      fontSize={'md'}
                      fontWeight={'sm'}
                      color='neutral'
                      sx={(theme) => {
                        return { color: theme.palette.text.secondary };
                      }}
                    >
                      {getSymbolFromCurrency(priceCurrency)}
                      {price} {priceCurrency}/tonne
                    </Typography>
                    <Box>
                      {isInventoryAvailable ? (
                        <Chip variant='outlined' color='primary' size='md' sx={{ mt: 1 }}>
                          <Stack direction={'row'} alignItems={'center'} gap={1}>
                            <Typography
                              fontWeight={'xl'}
                              fontSize={'xs'}
                              sx={(theme) => ({
                                color: theme.palette.text.primary,
                              })}
                            >
                              Available inventory
                            </Typography>
                            <Tooltip
                              title='The seller is able to fill an order based on existing inventory or callable volumes.'
                              variant='solid'
                              placement='top'
                              arrow
                            >
                              <Stack
                                padding={0}
                                margin={0}
                                alignItems={'center'}
                                justifyContent={'center'}
                                sx={{ cursor: 'pointer' }}
                              >
                                <IoInformationCircle size={16} />
                              </Stack>
                            </Tooltip>
                          </Stack>
                        </Chip>
                      ) : null}
                      {isInventoryAccessible ? (
                        <Chip variant='outlined' color='primary' size='md' sx={{ mt: 1 }}>
                          <Stack direction={'row'} alignItems={'center'} gap={1}>
                            <Typography
                              fontWeight={'xl'}
                              fontSize={'xs'}
                              sx={(theme) => ({
                                color: theme.palette.text.primary,
                              })}
                            >
                              Accessible inventory
                            </Typography>
                            <Tooltip
                              title='The seller has unconfirmed access to the credits.'
                              variant='solid'
                              placement='top'
                              arrow
                            >
                              <Stack
                                padding={0}
                                margin={0}
                                alignItems={'center'}
                                justifyContent={'center'}
                                sx={{ cursor: 'pointer' }}
                              >
                                <IoInformationCircle size={16} />
                              </Stack>
                            </Tooltip>
                          </Stack>
                        </Chip>
                      ) : null}
                      <Chip variant='outlined' color='primary' size='md' sx={{ mt: 1 }}>
                        <Typography
                          fontWeight={'xl'}
                          fontSize={'xs'}
                          sx={(theme) => ({
                            color: theme.palette.text.primary,
                          })}
                        >
                          {minimumBuyVolume > 0
                            ? `Minimum buy volume - ${formatNumberWithCommas(Number(minimumBuyVolume))} tonnes`
                            : 'No minimum buy volume'}
                        </Typography>
                      </Chip>
                    </Box>
                  </Stack>
                </Box>
                {ctaType === 'website' ? (
                  <Link
                    to={`${ctaWebsite}`}
                    target='_blank'
                    onClick={handleViewMoreOnSellerWebsite}
                  >
                    <Button fullWidth variant='outlined'>
                      View more details on seller’s website
                    </Button>
                  </Link>
                ) : null}
                {ctaType === 'contact' ? (
                  <Button fullWidth variant='outlined' onClick={handleRegisterInterest}>
                    Register interest
                  </Button>
                ) : null}
              </Stack>
            </Card>
          )}
        </Grid>
      </Grid>
      <Box overflow={'hidden'}>
        <Stack
          overflow={'hidden'}
          sx={{ transition: '1s ease' }}
          marginBottom={isExpanded ? 0 : '-180%'}
        >
          {childProjects?.map((item: any, index: number) => {
            return (
              <WhereToBuyCard
                key={item?.project_id}
                data={item}
                parentData={props?.data}
                index={index}
                isChild={true}
              />
            );
          })}
        </Stack>
      </Box>
      {!isChild && childProjects?.length > 0 ? (
        <Typography
          onClick={toggleExpand}
          fontWeight={'xl'}
          margin={'auto'}
          endDecorator={isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          sx={{ cursor: 'pointer' }}
        >
          {childProjects?.length} individual projects included
        </Typography>
      ) : null}
      <Box position={'absolute'} bottom={32} left={32}>
        {projectAttributes.map((attribute) => {
          return (
            <Chip variant='outlined' sx={{ mr: 1 }}>
              {attribute}
            </Chip>
          );
        })}
      </Box>
    </Card>
  );
};

export { WhereToBuyCard };
