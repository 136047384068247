import { Alert, Button, Stack, Typography } from '@mui/joy';

interface PropTypes {
  handleClose: () => void;
  error: ErrorBoxMessageTypes;
}

type ErrorBoxMessageTypes = {
  title?: string;
  description?: string;
} | null;

const ErrorBox = ({ handleClose, error }: PropTypes) => {
  return error ? (
    <Alert color='danger'>
      <Stack
        justifyContent={'space-between'}
        direction={'row'}
        width={'100%'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Stack>
          {error?.title ? (
            <Typography color='danger' fontSize={'md'} fontWeight={'xl'}>
              {error?.title}
            </Typography>
          ) : null}
          {error?.description ? (
            <Typography color='danger' fontSize={'sm'} fontWeight={'md'}>
              {error?.description}
            </Typography>
          ) : null}
        </Stack>
        <Button
          variant='plain'
          color='danger'
          sx={{ color: 'danger.700', fontWeight: 'sm', padding: 0, marginTop: '-4px' }}
          size='sm'
          onClick={handleClose}
        >
          CLOSE
        </Button>
      </Stack>
    </Alert>
  ) : null;
};

export { ErrorBox };
export type { ErrorBoxMessageTypes };
