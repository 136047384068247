import { useEffect, useRef } from 'react';

import { Box, Chip, Grid, Stack, Tooltip, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';

import { dateFormat } from '../../commons/utils/dateFormat';
import useFilters from '../../commons/utils/hooks/useFilters';
import { track } from '../../services/analytics';
import {
  EVENT_RETIREMENT_INSIGHTS_COMPANY_DRILL_DOWN_PAGE,
  PGV_retirement_company_drilldown,
} from '../../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../../services/axios';
import { retireeAggregateData } from '../../services/axios/endpoints';

import AggregateStats from './components/AggregateStats';
import { BreakdownCharts } from './components/BreakdownCharts';
import ProjectPortfolio from './components/ProjectPortfolio';
import RetiredProjectsTable from './components/RetiredProjectsTable';

export default function RetireeInsightsDrillDown() {
  const { retiree } = useParams();
  const filterDetails = useFilters({ limit: 50, sort: { total_volume: 'DESC' } }, true, true);

  const { data: latestUpdate, isLoading } = useQuery({
    queryKey: ['retiree-aggregate-data', retiree],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(retireeAggregateData, {
        params: {
          retiree_name: retiree,
        },
      }),
    select: (data) => data?.data?.data?.latestUpdate,
  });

  const retireTableRef = useRef<HTMLDivElement>(null);

  const scrollRetireeTableIntoView = ({
    ghg_rating,
    sdg_rating,
  }: {
    ghg_rating: string;
    sdg_rating: string;
  }) => {
    filterDetails.setter({ ghg_rating: [ghg_rating], sdg_rating: [sdg_rating] });
    setTimeout(() => {
      retireTableRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }, 500);
  };

  useEffect(() => {
    track(PGV_retirement_company_drilldown, EVENT_RETIREMENT_INSIGHTS_COMPANY_DRILL_DOWN_PAGE, {
      retirement_company: retiree,
    });
  }, []);

  return (
    <Box height={1} width={1} position={'relative'}>
      <Stack
        sx={{
          pl: 5,
          pt: 2,
          pr: 5,
          pb: 20,
          height: 1,
          overflowY: 'scroll',
          position: 'fixed',
          mb: 4,
          width: '100%',
        }}
        direction={'column'}
        gap={2}
      >
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography fontSize={'xl2'} fontWeight={700} color='primary'>
            Retirements by {retiree}
          </Typography>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography
              fontWeight={'lg'}
              level='body-xs'
              sx={(theme) => {
                return { color: theme.palette.text.secondary };
              }}
            >
              Information Updated:{' '}
              {!!latestUpdate
                ? dateFormat(latestUpdate).mmddyyyy
                : !isLoading
                  ? 'N/A'
                  : 'Loading...'}
            </Typography>
            <Tooltip
              title='Beta Access: This feature will be available to select subscription plans post-beta'
              variant='solid'
              placement='bottom'
              sx={{ maxWidth: '340px' }}
              arrow
            >
              <Chip
                variant='outlined'
                color='primary'
                size='sm'
                endDecorator={<IoInformationCircleOutline size={20} />}
              >
                Beta
              </Chip>
            </Tooltip>
          </Stack>
        </Stack>
        <AggregateStats />
        <BreakdownCharts />
        <Box ref={retireTableRef}>
          <RetiredProjectsTable
            filterDetails={filterDetails}
            paginationState={filterDetails.paginationState}
          />
        </Box>
        <Grid container justifyContent={'space-between'} spacing={2}>
          <Grid xs={12} lg={6}>
            <ProjectPortfolio scrollRetireeTableIntoView={scrollRetireeTableIntoView} />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
