import {
  Box,
  Button,
  Divider,
  Grid,
  Option,
  Select,
  Stack,
  Typography,
  styled,
  useColorScheme,
} from '@mui/joy';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { FaChevronDown } from 'react-icons/fa';
import { IoMdArrowBack } from 'react-icons/io';
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { MdCalendarToday } from 'react-icons/md';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Image from '../../../commons/components/Image';
import { projectState } from '../../../commons/stores/project';
import { snackbarState } from '../../../commons/stores/snackerbar';
import { track } from '../../../services/analytics';
import {
  EVENT_EXECUTIVE_SUMMARY_PAGE,
  EVNTAPP_Back_button_click,
  EVNTAPP_Follow_click,
  EVNTAPP_UnFollow_click,
} from '../../../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { assetBaseUrl, projectFollow, projectUnfollow } from '../../../services/axios/endpoints';
import queryClient from '../../../services/react-query';
import { FOLLOWING } from '../../home/components/viewRatings/constants';
import { getGHGratingColor, getSDGRatingColor } from '../../search/constants';

const StatusCircle = styled(Box)<{ color: string }>(({ theme, color }) => ({
  border: '1px solid',
  borderColor: theme.palette.background.level1,
  background: color || theme.palette.background.level1,
  width: 15,
  height: 15,
  borderRadius: '50%',
}));

const ProjectHeader: React.FC = () => {
  const { mode } = useColorScheme();
  const { search, pathname } = useLocation();
  const { projectData } = useRecoilValue(projectState);
  const setSnackbar = useSetRecoilState(snackbarState);
  const setProjectState = useSetRecoilState(projectState);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const ghg_standard_code = params.get('standard');
  const crediting_period_id = params.get('cp_no');
  const project_id = params.get('id');

  const followUnFollowCall: MutationFunction<unknown> = async () => {
    const isFollowing = !!projectData?.isFollowing;
    try {
      const { data } = await makeAuthenticatedGetRequest<unknown>(
        isFollowing ? projectUnfollow : projectFollow,
        {
          params: {
            ghg_standard_code,
            crediting_period_id,
            project_id,
          },
        }
      );
      return data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error.message);
    }
  };

  const { mutate: followMutate, isPending } = useMutation<unknown, Error>({
    mutationFn: followUnFollowCall,
    onSuccess: () => {
      const isFollowing = !!projectData?.isFollowing;
      setSnackbar({
        message: `${isFollowing ? 'Unfollowed' : 'Followed'} successfully !`,
        color: 'success',
      });
      setProjectState((value) => ({
        ...value,
        projectData: { ...projectData, isFollowing: !projectData?.isFollowing },
      }));
      queryClient.invalidateQueries({ queryKey: [`dashboard-ratings-${FOLLOWING}`] });
    },
    onError: (error) => {
      setSnackbar({ message: error.message || 'Something went wrong', color: 'danger' });
    },
  });

  const handleFollow = () => {
    if (isPending) return;
    const isFollowing = !!projectData?.isFollowing;
    track(
      isFollowing ? EVNTAPP_UnFollow_click : EVNTAPP_Follow_click,
      EVENT_EXECUTIVE_SUMMARY_PAGE,
      {
        project_id: projectData?.projectId,
        page_url: `${pathname}${search}`,
      }
    );
    followMutate();
  };

  const handleCreditingPeriodChange = (_, value) => {
    if (crediting_period_id === value || value === null) return;
    params.set('cp_no', value);
    setParams(params);
  };

  const handleBack = () => {
    track(EVNTAPP_Back_button_click, EVENT_EXECUTIVE_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      page_url: `${pathname}${search}`,
    });
    navigate('/search');
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '50px',
          px: 2,
          width: 1,
          background: (theme) => theme.palette.background.surface,
          borderBottom: (theme) => `1px solid ${theme.palette.primary.outlinedBorder}`,
        }}
      >
        <Grid container alignItems={'center'} width={'100vw'} justifyContent={'space-between'}>
          <Grid xs={8}>
            <Stack direction={'row'} alignItems={'center'} gap={2}>
              <Button
                startDecorator={<IoMdArrowBack size={20} />}
                onClick={handleBack}
                variant='outlined'
                size='sm'
                color='primary'
                sx={{ textWrap: 'nowrap' }}
              >
                Search Page
              </Button>
              <Grid
                container
                alignItems={'center'}
                rowSpacing={1}
                minWidth={'6rem'}
                width={'fit-content'}
                gap={1}
              >
                <Grid>
                  <Box
                    sx={{
                      width: '24px',
                      objectFit: 'contain',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {projectData?.logo && (
                      <Image
                        src={`${assetBaseUrl}/${projectData?.logo}`}
                        style={{ width: '100%', height: '100%' }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid>
                  <Typography>{projectData?.code}</Typography>
                </Grid>
              </Grid>
              <Divider
                orientation='vertical'
                sx={{
                  height: (theme) => theme.spacing(3),
                  width: '1px',
                  ml: -1,
                  my: 'auto',
                }}
              />

              <Typography
                level='body-md'
                noWrap
                sx={{ maxWidth: { xs: '5%', sm: '10%', lg: '50%' } }}
              >
                {projectData?.title}
              </Typography>
              {(projectData?.creditingPeriods || []).length > 1 && (
                <Select
                  defaultValue={crediting_period_id}
                  startDecorator={<MdCalendarToday />}
                  indicator={<FaChevronDown />}
                  onChange={handleCreditingPeriodChange}
                  variant='outlined'
                  sx={{ width: 'fit-content' }}
                >
                  {(projectData?.creditingPeriods || []).map((period) => (
                    <Option value={`${period.crediting_period_id}`}>
                      {`${period.start_date} - ${period.end_date}`}
                    </Option>
                  ))}
                </Select>
              )}
            </Stack>
          </Grid>
          <Grid xs={4}>
            <Stack
              direction={'row'}
              gap={2}
              alignItems={'center'}
              sx={{ justifyContent: 'flex-end' }}
            >
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
                <StatusCircle
                  color={
                    getGHGratingColor(projectData?.ghgRatingDetail?.grade, mode === 'dark') ?? ''
                  }
                />
                <Typography noWrap>
                  GHG Rating: {projectData?.ghgRatingDetail?.grade || ''}
                </Typography>
              </Box>
              <Divider
                orientation='vertical'
                sx={{ height: (theme) => theme.spacing(3), width: '1px', mx: 1, my: 'auto' }}
              />
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
                <StatusCircle
                  color={
                    getSDGRatingColor(projectData?.sdgRatingDetail?.grade, mode === 'dark') ?? ''
                  }
                />
                <Typography noWrap>
                  SDG Rating: {projectData?.sdgRatingDetail?.grade || ''}
                </Typography>
              </Box>
              <Divider
                orientation='vertical'
                sx={{ height: (theme) => theme.spacing(3), width: '1px', mx: 1, my: 'auto' }}
              />
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  gap: 1,
                  cursor: isPending ? 'not-allowed' : 'pointer',
                  opacity: isPending ? 0.5 : 1,
                  minWidth: (theme) => theme.spacing(14),
                }}
                onClick={handleFollow}
              >
                {projectData?.isFollowing ? (
                  <>
                    <Button
                      startDecorator={<IoRemoveCircleOutline size={20} />}
                      size='sm'
                      variant='outlined'
                    >
                      Unfollow
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      startDecorator={<IoAddCircleOutline size={20} />}
                      size='sm'
                      variant='outlined'
                    >
                      Follow
                    </Button>
                  </>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProjectHeader;
