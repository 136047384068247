import { Box, Card, Chip, Grid, Skeleton, Stack, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { RatingCircle } from '../../../commons/components/RatingCircle';
import { projectState } from '../../../commons/stores/project';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { projectSdgData } from '../../../services/axios/endpoints';
import { getProjectSdgData } from '../constants';

import SdgDetail from './SdgDetail';

const emptyItems = Array(3).fill('');
const SDGRating = () => {
  const { projectData } = useRecoilValue(projectState);
  const [params] = useSearchParams();
  const ghg_standard_code = params.get('standard');
  const crediting_period_id = params.get('cp_no');
  const project_id = params.get('id');
  const { data, isFetching } = useQuery({
    queryKey: [`sdg-rating-detail-data`, crediting_period_id],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectSdgData, {
        params: { project_id, crediting_period_id, ghg_standard_code },
      }),
    select: (data) => getProjectSdgData(data?.data?.data),
  });

  if (isFetching)
    return (
      <Stack sx={{ mx: 'auto', mb: 3, maxWidth: 'lg' }}>
        <Card sx={{ padding: 3 }}>
          <Box display={'grid'} gridTemplateColumns={'1fr 7fr'} alignItems={'center'} gap={3}>
            <Skeleton variant='circular' width={120} height={120} animation='wave' />
            <Stack spacing={1.5}>
              {emptyItems.map(() => {
                return (
                  <Skeleton variant='rectangular' width={'100%'} height={'1em'} animation='wave' />
                );
              })}
            </Stack>
          </Box>
        </Card>
        <Card sx={{ mt: 3 }}>
          <Grid container sx={{ p: 3 }}>
            <Grid xs={6} sx={{ pr: 3 }}>
              {emptyItems.map(() => {
                return (
                  <Skeleton variant='rectangular' width={'100%'} height={'1em'} animation='wave' />
                );
              })}
            </Grid>
            <Grid xs={6}>
              {emptyItems.map(() => {
                return (
                  <Skeleton variant='rectangular' width={'100%'} height={'5em'} animation='wave' />
                );
              })}
            </Grid>
          </Grid>
        </Card>
      </Stack>
    );

  return (
    <Stack sx={{ mx: 'auto', mb: 3, maxWidth: 'lg' }} gap={3}>
      <Card sx={{ p: 3 }}>
        <Typography level='h2'>SDG Rating</Typography>
        <Grid container sx={{ pt: 2 }}>
          <Grid alignContent={'center'} xs={2} sm={3}>
            <Stack direction={'column'} justifyContent={'center'} alignItems={'start'} gap={2}>
              <Stack direction={'row'} alignItems={'center'} gap={2}>
                <RatingCircle
                  type='sdg'
                  percentage={projectData?.sdgRatingDetail?.percentage || 0}
                  rating={projectData?.sdgRatingDetail?.grade || ''}
                  size='lg'
                />
                {projectData?.sdgImpact && (
                  <Typography fontSize={'md'} fontWeight={'md'}>
                    {projectData?.sdgImpact || ''}
                  </Typography>
                )}
              </Stack>
              <Chip variant='outlined' color='success'>
                {data?.certificate} Certified
              </Chip>
              <Typography level='body-sm' color='primary'>
                Date of rating: {data?.date}
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={10} sm={9} sx={{ px: 2 }} alignContent={'center'}>
            <Typography level='body-md'>{data?.description}</Typography>
          </Grid>
        </Grid>
      </Card>
      {data?.hasDetailedView && <SdgDetail data={data} />}
    </Stack>
  );
};

export default SDGRating;
