import { assetBaseUrl } from '../../../services/axios/endpoints';
import { hasBaseUrl } from '../index.js';

const ADMIN_BASE = '://admin.calyxglobal.com';
const ADMIN_CALYX_BASE = '://admin.calyxstaging.com';
const CALYX_STAGING_BASE = '://calyxstaging.com';
const CALYX_BASE = '://calyxglobal.com';

const updateImageBaseUrlInHTML = (htmlString: string): string => {
  // Parse the HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Get all img elements
  const images = doc.querySelectorAll('img');

  images.forEach((img) => {
    const url = img.getAttribute('src');
    if (url) {
      const src = url.startsWith('demo')
        ? url.replace('demo', 'assets/images')
        : url.replace('../demo', '/assets/images');
      if (hasBaseUrl(src)) {
        const url = new URL(src);
        // Check if the image src starts with the old base URL
        if (
          src.includes(ADMIN_BASE) ||
          src.includes(ADMIN_CALYX_BASE) ||
          src.includes(CALYX_STAGING_BASE) ||
          src.includes(CALYX_BASE)
        ) {
          const newSrc = new URL(url.pathname, assetBaseUrl).href;
          img.setAttribute('src', newSrc);
        }
      } else {
        const url = new URL(src, assetBaseUrl);
        const newSrc = new URL(url.pathname, assetBaseUrl).href;
        img.setAttribute('src', newSrc);
      }
    }
  });

  // Serialize the HTML back to a string
  return doc.documentElement.outerHTML;
};

export default updateImageBaseUrlInHTML;
