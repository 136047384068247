import React from 'react';

import { Grid } from '@mui/joy';

import { track } from '../../../../services/analytics';
import {
  EVENT_BLOG_PAGE,
  EVNTAPP_blog_detail_click,
  EVNTAPP_pinned_post_click,
} from '../../../../services/analytics/events';
import CardComponent from '../../components/card';
import CardLoader from '../Skeletons/Card';
interface List {
  id: string;
  title: string;
  link: string;
  post: string;
  access_level: string;
  date: string;
  author: string;
  visual: string;
  state: string;
  page?: string;
  type?: string;
  tags: { tag_info: { id: string; tag: string } }[];
}
interface props {
  data: List[];
  isLoading: boolean;
  page: string;
  type?: string;
  selectedTag?: string | null;
}
const ListItem = ({ data, isLoading, type, page, selectedTag }: props) => {
  const handleBlogDetailTrack = () => {
    track(EVNTAPP_blog_detail_click, EVENT_BLOG_PAGE);
  };

  const handlePinnedPostTrack = () => {
    track(EVNTAPP_pinned_post_click, EVENT_BLOG_PAGE);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {isLoading ? (
          Array(6)
            .fill('')
            .map((item, index) => {
              return <CardLoader key={index + item} />;
            })
        ) : (
          <>
            {(selectedTag === null || selectedTag === '7' || selectedTag === '1') &&
            page === 'blog-detail' ? (
              <Grid xs={12} sm={6} md={4} onClick={handlePinnedPostTrack}>
                <CardComponent
                  data={{
                    isPinned: true,
                    title: 'Current Calyx Risk Ratings Distribution',
                    date: new Date().toDateString(),
                    visual: 'howrisky.jpg',
                    pinnedPostLink: '/blog_detail_dist',
                  }}
                  type={type}
                  page={page}
                />
              </Grid>
            ) : null}
            {data?.map((item: List, index) => {
              return (
                <Grid xs={12} sm={6} md={4} onClick={handleBlogDetailTrack}>
                  <CardComponent
                    data={item}
                    key={index}
                    type={item?.type || type}
                    page={item?.page || page}
                  />
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
    </React.Fragment>
  );
};
export default ListItem;
