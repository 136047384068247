import { getDateSuffix, months } from '../../../../../commons/utils/dateFormat';

export const formatTimelineDate = (dateString: string) => {
  const formatDateType = (dateString: string) =>
    `${Number(dateString?.split('-')?.[2])}${getDateSuffix(Number(dateString?.split('-')?.[2]))} ${months[Number(dateString?.split('-')?.[1]) - 1]} ${dateString?.split('-')?.[0]}`;

  const formatMonthType = (dateString: string) =>
    `${months[Number(dateString?.split('-')?.[1]) - 1]} ${dateString?.split('-')?.[0]}`;

  const formatYearType = (dateString: string) => `${dateString?.split('-')?.[0]}`;

  const partArray = dateString?.split(':|:');
  if (partArray?.[1] === 'single') {
    if (partArray?.[0] === 'date') {
      return formatDateType(partArray?.[2]);
    }
    if (partArray?.[0] === 'month') {
      return formatMonthType(partArray?.[2]);
    }
    if (partArray?.[0] === 'year') {
      return formatYearType(partArray?.[2]);
    }
  }
  if (partArray?.[1] === 'period') {
    const start = partArray?.[2]?.split(',')?.[0];
    const end = partArray?.[2]?.split(',')?.[1];
    if (partArray?.[0] === 'date') {
      return `${formatDateType(start)} - ${formatDateType(end)}`;
    }
    if (partArray?.[0] === 'month') {
      return `${formatMonthType(start)} - ${formatMonthType(end)}`;
    }
    if (partArray?.[0] === 'year') {
      return `${formatYearType(start)} - ${formatYearType(end)}`;
    }
  }
};
