import React from 'react';

import { Grid } from '@mui/joy';
import { useLocation } from 'react-router-dom';

import { track } from '../../../../services/analytics';
import { EVENT_RESOURCE_PAGE, EVNTAPP_resource_click } from '../../../../services/analytics/events';
import CardComponent from '../../components/card/resource';
import CardLoader from '../Skeletons/Card';
interface List {
  id: string;
  title: string;
  link: string;
  post: string;
  access_level: string;
  date: string;
  author: string;
  visual: string;
  state: string;
  tags: { tag_info: { id: string; tag: string } }[];
}
interface props {
  data: List[];
  isLoading: boolean;
  page: string;
  type?: string;
}
const ListItem = ({ data, isLoading, type, page }: props) => {
  const location = useLocation();
  const handleResourceClick = () => {
    track(EVNTAPP_resource_click, EVENT_RESOURCE_PAGE, {
      page_url: `${location.pathname}${location.search}`,
    });
  };
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {isLoading
          ? Array(5)
              .fill('')
              .map((item, index) => {
                return <CardLoader key={index + item} />;
              })
          : data?.map((Item: List, index) => {
              return (
                <Grid xs={12} sm={6} md={4} onClick={handleResourceClick}>
                  <CardComponent data={Item} key={index} type={type} page={page} />
                </Grid>
              );
            })}
      </Grid>
    </React.Fragment>
  );
};
export default ListItem;
