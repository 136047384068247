import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Input,
  Stack,
  styled,
  Typography,
  useColorScheme,
} from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { FilterModel } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import queryString from 'query-string';
import { IoMdSearch } from 'react-icons/io';
import { IoFilterSharp, IoSearch } from 'react-icons/io5';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { AGGridUIWrapper } from '../../commons/components/AGGridTableUIWrapper';
import { snackbarState } from '../../commons/stores/snackerbar';
import { areArraysEqual } from '../../commons/utils/arrays';
import { track } from '../../services/analytics';
import {
  EVENT_SEARCH_PAGE,
  EVNTAPP_chart_btn_click,
  EVNTAPP_filter_attributes,
  EVNTAPP_filter_btn_click,
  EVNTAPP_filter_confirmedSDGs,
  EVNTAPP_filter_GHGRating,
  EVNTAPP_filter_location,
  EVNTAPP_filter_SDGCertificate,
  EVNTAPP_filter_SDGRating,
  EVNTAPP_filter_type,
  EVNTAPP_list_btn_click,
  EVNTAPP_search_change_pageSize,
} from '../../services/analytics/events';
import { makeAuthenticatedGetRequest, makeAuthenticatedPostRequest } from '../../services/axios';
import { searchPageFilters, searchPageProjects } from '../../services/axios/endpoints';

import { AppliedFilterChip } from './components/appliedFilterChip/AppliedFilterChip';
import { DistributionDoughnut } from './components/charts/DistributionDoughnut';
import { DistributionGraph } from './components/charts/DistributionGraph';
import { DistributionScatter } from './components/charts/DistributionScatter';
import {
  AG_GRID_FILTER_TOOLPANEL_ID,
  agGridColumnDefs,
  agGridDataTransformer,
  sideBarConfig,
  ViewTypes,
} from './constants';
import 'ag-grid-enterprise';

const ChartsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  zIndex: '9',
  display: 'grid',
  gridTemplateColumns: 'repeat(2,1fr)',
  gap: theme.spacing(3),
  paddingBottom: '100px',
}));

export const LIST_VIEW = 'LIST_VIEW';
export const CHART_VIEW = 'CHART_VIEW';

const buttonsConfig: Array<ViewTypes> = [
  {
    label: 'List',
    value: LIST_VIEW,
    event: EVNTAPP_list_btn_click,
  },
  {
    label: 'Chart',
    value: CHART_VIEW,
    event: EVNTAPP_chart_btn_click,
  },
];

const AG_GRID_GLOBAL_SEARCH_INPUT_ID = 'AG_GRID_GLOBAL_SEARCH_INPUT_ID';

const VIEW_TYPE_URL_SEARCH_PARAM_KEY = 'view';

export const APPLIED_FILTERS_URL_KEY = 'filters';

const SearchPage = () => {
  const { mode } = useColorScheme();
  const setSnackbar = useSetRecoilState(snackbarState);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedView, setSelectedView] = useState<ViewTypes>(buttonsConfig[0]);
  const agGridRef = useRef<AgGridReact>(null);
  const [filteredAGRows, setFilteredAGRows] = useState<any[]>([]);
  const filteredAGRowsRef = useRef<any>(filteredAGRows);
  const [allAGRows, setAllAGRows] = useState<any[]>([]);
  const allAGRowsRef = useRef<any>(allAGRows);
  const [rowDataForCharts, setRowDataForCharts] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [appliedFilters, setAppliedFilters] = useState<FilterModel>({});
  const [isAppliedFiltersCollapsed, setIsAppliedFiltersCollapsed] = useState<boolean>(true);
  const [isSearchResultBlank, setIsSearchResultBlank] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['search-page-projects'],
    queryFn: async () => await makeAuthenticatedGetRequest(searchPageProjects),
    select: (data) => agGridDataTransformer(data?.data?.projects, data?.data?.filters),
  });

  useEffect(() => {
    filteredAGRowsRef.current = filteredAGRows;
  });
  useEffect(() => {
    allAGRowsRef.current = allAGRows;
  });

  useEffect(() => {
    if (filteredAGRows?.length > 0) {
      setRowDataForCharts(filteredAGRows);
    } else {
      setRowDataForCharts(allAGRows);
    }
  }, [allAGRows, filteredAGRows, setRowDataForCharts]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleOpenFilter = () => {
    track(EVNTAPP_filter_btn_click, EVENT_SEARCH_PAGE);
    agGridRef.current?.api.isToolPanelShowing()
      ? agGridRef.current?.api.closeToolPanel()
      : agGridRef.current?.api.openToolPanel(AG_GRID_FILTER_TOOLPANEL_ID);
  };

  const isCharViewSelected = selectedView.value === CHART_VIEW;

  const handleFollowingFilter = () => {
    const followingFilterObj = {
      isFollowedByCurrentUser: {
        values: ['true'],
        filterType: 'set',
      },
    };
    setAppliedFilters(followingFilterObj);
    agGridRef.current?.api?.setFilterModel(followingFilterObj);
  };

  const handleOnGridReady = () => {
    agGridRef.current?.api?.setGridOption('paginationPageSize', data?.filters?.pageSize ?? 100);
    if (!!queryString.parse(location.search)?.q) {
      setInputValue(`${queryString.parse(location.search)?.q}`);
    }
    if (searchParams.get('following') === 'true') {
      handleFollowingFilter();
      return;
    }
    if ((searchParams.get(APPLIED_FILTERS_URL_KEY) as string)?.length > 0) {
      const filterStateFromUrl =
        JSON.parse(decodeURIComponent(searchParams.get(APPLIED_FILTERS_URL_KEY) ?? '{}')) ?? '{}';
      setAppliedFilters(filterStateFromUrl);
      agGridRef.current?.api?.setFilterModel(filterStateFromUrl);
      return;
    } else {
      setAppliedFilters(data?.filters?.savedFilter);
      agGridRef.current?.api?.setFilterModel(data?.filters?.savedFilter);
    }
  };

  useEffect(() => {
    if (searchParams.get('following') === 'true') {
      handleFollowingFilter();
    }
  }, [data, location.search]);

  if (window.innerWidth > 2000) {
    agGridRef.current?.api.sizeColumnsToFit();
  }

  const handleViewTypeSelect = (rating: ViewTypes) => {
    track(rating.event, EVENT_SEARCH_PAGE);
    setSearchParams(`?${new URLSearchParams({ [VIEW_TYPE_URL_SEARCH_PARAM_KEY]: rating.value })}`);
    setSelectedView(rating);
  };

  useEffect(() => {
    const selectedViewFromUrl = searchParams.get(VIEW_TYPE_URL_SEARCH_PARAM_KEY);
    if (!!selectedViewFromUrl) {
      setSelectedView(
        buttonsConfig.find(
          ({ value }) => value?.toLowerCase() === selectedViewFromUrl?.toLowerCase()
        ) ?? buttonsConfig[0]
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedView.value === CHART_VIEW) {
      setInputValue('');
    }
  }, [selectedView]);

  const checkIfNoFiltersAreActive = (filterState: FilterModel) => {
    return Object.values(filterState ?? {})?.every(({ values }) => {
      return values?.length === 0;
    });
  };

  const saveFilterToDB = (appliedFilters: FilterModel) => {
    makeAuthenticatedPostRequest(searchPageFilters, {
      savedFilter: appliedFilters,
    })
      .then(() => refetch())
      .catch(() => {
        setSnackbar({ message: 'Something went wrong while saving your filters', color: 'danger' });
      });
  };

  const handleRemoveIndividualFilter = (filter: string, value: string) => {
    setAppliedFilters((prev) => {
      const newState = {
        ...prev,
        [filter]: {
          ...prev?.[filter],
          values: prev?.[filter]?.values?.filter((item: string) => item !== value),
        },
      };
      agGridRef.current?.api.setFilterModel(checkIfNoFiltersAreActive(newState) ? null : newState);
      if (checkIfNoFiltersAreActive(newState)) {
        saveFilterToDB({});
      } else {
        saveFilterToDB(newState);
      }
      return newState;
    });
  };

  const handleClearAllFilters = () => {
    setSearchParams(
      `?${new URLSearchParams({ [VIEW_TYPE_URL_SEARCH_PARAM_KEY]: selectedView?.value })}`
    );
    agGridRef.current?.api?.setFilterModel(null);
    setAppliedFilters({});
    saveFilterToDB({});
    setIsAppliedFiltersCollapsed(true);
    refetch();
  };

  useEffect(() => {
    if (!checkIfNoFiltersAreActive(appliedFilters)) {
      saveFilterToDB(appliedFilters);
    }
    let event = '';
    const mostRecentFilter = Object.keys(appliedFilters ?? {})?.[
      Object.keys(appliedFilters ?? {})?.length - 1
    ];
    switch (mostRecentFilter) {
      case 'projectTypesHierarchy':
        event = EVNTAPP_filter_type;
        break;
      case 'locationsHierarchy':
        event = EVNTAPP_filter_location;
        break;
      case 'ghgRatingNumber':
        event = EVNTAPP_filter_GHGRating;
        break;
      case 'sdgRatingNumber':
        event = EVNTAPP_filter_SDGRating;
        break;
      case 'confirmedSDGs':
        event = EVNTAPP_filter_confirmedSDGs;
        break;
      case 'projectAttributes':
        event = EVNTAPP_filter_attributes;
        break;
      case 'sdgCertificate':
        event = EVNTAPP_filter_SDGCertificate;
        break;
    }
    if (event) {
      track(event, EVENT_SEARCH_PAGE);
    }
  }, [appliedFilters]);

  const handleOnFilterModified = (e) => {
    setTimeout(() => {
      const { duration, ...filtersWithoutDuration } = e.api.getFilterModel();
      setAppliedFilters(filtersWithoutDuration);
    }, 0);
  };

  const handleOnRowDataUpdated = (e) => {
    const allRows: any[] = [];
    e.api.forEachNode((row: any) => {
      allRows.push(row?.data);
    });
    if (!areArraysEqual(allAGRowsRef.current, allRows)) {
      setAllAGRows(allRows);
    }
  };

  const handleOnRowClicked = (row: any) => {
    navigate({
      pathname: '/executive_summary',
      search: new URLSearchParams({
        standard: row?.data?.ghgStandardCode || '',
        id: row?.data?.projectId || '',
        cp_no: row?.data?.creditingPeriodId,
      }).toString(),
    });
  };

  const handleOnFilterChanged = (e) => {
    const allRows: any[] = [];
    e.api.forEachNodeAfterFilter((row: any) => {
      allRows.push(row?.data);
    });
    if (!areArraysEqual(filteredAGRowsRef.current, allRows)) {
      setFilteredAGRows(allRows);
    }
  };

  const handleOnPaginationChanged = (e) => {
    if (!data?.filters?.pageSize) return;
    makeAuthenticatedPostRequest(searchPageFilters, {
      pageSize: e.api.paginationGetPageSize(),
    }).catch(() => {
      setSnackbar({
        message: 'Something went wrong while saving your preferred page size',
        color: 'danger',
      });
    });
    if (e.api.paginationGetCurrentPage() !== 0 || e.api.paginationGetPageSize() !== 100) {
      track(EVNTAPP_search_change_pageSize, EVENT_SEARCH_PAGE);
    }
  };

  const handleExpandAppliedFilterChips = () => setIsAppliedFiltersCollapsed(false);

  const appliedFilterChips = useMemo(
    () =>
      Object.entries(appliedFilters ?? {})
        ?.map(([filter, { values }]) => {
          return values?.map((value: any) => {
            return {
              value,
              filter,
            };
          });
        })
        .flat(),
    [appliedFilters]
  );

  useEffect(() => {
    let timer: NodeJS.Timeout | string | number | undefined = undefined;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setIsSearchResultBlank(agGridRef.current?.api?.getDisplayedRowCount() === 0 && !!data?.data);
    }, 10);
    return () => clearTimeout(timer);
  });

  return (
    <Box sx={{ margin: 3 }}>
      <Stack
        direction={'row'}
        alignItems={'baseline'}
        justifyContent={'space-between'}
        width={'100%'}
        mb={2}
      >
        {!isCharViewSelected ? (
          <Input
            endDecorator={<IoSearch />}
            size='lg'
            color='neutral'
            placeholder='Search for projects, project types, vintages'
            sx={{ width: '30%', mb: 1 }}
            onChange={handleSearch}
            id={AG_GRID_GLOBAL_SEARCH_INPUT_ID}
            value={inputValue}
          />
        ) : (
          <Typography fontWeight={'md'} fontSize={'sm'}>
            Insights from <Typography fontWeight={'xl'}>{rowDataForCharts?.length}</Typography> out
            out of <Typography fontWeight={'xl'}>{allAGRows?.length} ratings</Typography> by Calyx
            Calyx Global
          </Typography>
        )}
        <Stack direction={'row'} alignItems={'center'} spacing={2} position={'relative'} zIndex={2}>
          <ButtonGroup color='primary' size='lg'>
            {buttonsConfig.map((ratingType) => {
              const { label, value } = ratingType;
              const isSelected = selectedView.value === value;
              return (
                <Button
                  variant={isSelected ? 'solid' : 'outlined'}
                  onClick={() => handleViewTypeSelect(ratingType)}
                  sx={{ width: '80px' }}
                >
                  {label}
                </Button>
              );
            })}
          </ButtonGroup>
          <Button
            variant='outlined'
            size='lg'
            endDecorator={<IoFilterSharp />}
            onClick={handleOpenFilter}
            sx={{ zIndex: 1 }}
          >
            Filter
          </Button>
        </Stack>
      </Stack>
      {checkIfNoFiltersAreActive(appliedFilters) ? null : (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={1}
          marginBottom={2}
          flexWrap={'wrap'}
          position={'relative'}
        >
          <>
            {appliedFilterChips
              ?.slice(0, isAppliedFiltersCollapsed ? 3 : appliedFilterChips?.length)
              ?.map(({ filter, value }) => {
                return (
                  <AppliedFilterChip
                    key={`${filter}-${value}`}
                    onClickHandler={() => handleRemoveIndividualFilter(filter, value)}
                    filter={filter}
                    value={value}
                  />
                );
              })}
          </>
          {isAppliedFiltersCollapsed && appliedFilterChips?.length > 3 ? (
            <Button variant='plain' color='primary' onClick={handleExpandAppliedFilterChips}>
              <Typography
                sx={(theme) => {
                  return { color: theme.palette.text.primary, textDecoration: 'underline' };
                }}
              >
                +{appliedFilterChips?.length - 3}
              </Typography>
            </Button>
          ) : null}
          <Button variant='plain' color='primary' onClick={handleClearAllFilters}>
            <Typography
              sx={(theme) => {
                return { color: theme.palette.text.primary, textDecoration: 'underline' };
              }}
            >
              Clear all filters
            </Typography>
          </Button>
        </Stack>
      )}
      <div>
        <AGGridUIWrapper
          style={{
            height: `calc(100vh - ${appliedFilterChips?.length > 0 ? `220px` : `180px`})`,
            width: '100%',
          }}
          className={mode === 'dark' ? 'ag-theme-alpine-dark ' : 'ag-theme-alpine'}
          isDarkModeOn={mode === 'dark'}
          selectedView={selectedView}
          isOnSearchPage
        >
          <AgGridReact
            ref={agGridRef}
            rowData={data?.data}
            columnDefs={agGridColumnDefs}
            pagination
            paginationPageSize={100}
            paginationPageSizeSelector={[10, 20, 30, 50, 100, 500, 1000]}
            sideBar={sideBarConfig}
            reactiveCustomComponents
            onFirstDataRendered={handleOnGridReady}
            quickFilterText={inputValue}
            onFilterModified={handleOnFilterModified}
            onRowDataUpdated={handleOnRowDataUpdated}
            onRowClicked={handleOnRowClicked}
            onFilterChanged={handleOnFilterChanged}
            onPaginationChanged={handleOnPaginationChanged}
            suppressContextMenu
            suppressCellFocus
            rowHeight={90}
          />
          {selectedView.value === CHART_VIEW ? (
            <Box position={'absolute'} top={0} right={'0px'} zIndex={0} width={'100%'}>
              <ChartsContainer>
                <DistributionGraph rowData={rowDataForCharts} type='ghg' isLoading={isLoading} />
                <DistributionGraph rowData={rowDataForCharts} type='sdg' isLoading={isLoading} />
                <DistributionScatter rowData={rowDataForCharts} isLoading={isLoading} />
                <DistributionDoughnut rowData={rowDataForCharts} isLoading={isLoading} />
              </ChartsContainer>
            </Box>
          ) : null}
          {isSearchResultBlank && selectedView.value === LIST_VIEW ? (
            <Box
              position={'absolute'}
              zIndex={9}
              left={'50%'}
              top={'50%'}
              sx={{ transform: 'translate(-50%,-50%)' }}
            >
              <Stack gap={1} direction={'column'} alignItems={'center'}>
                <IoMdSearch size={100} />
                <Typography color='primary' level='h4'>
                  No results found
                </Typography>
                <Typography>Adjust your filters and try again.</Typography>
              </Stack>
            </Box>
          ) : null}
        </AGGridUIWrapper>
      </div>
    </Box>
  );
};

export { SearchPage, AG_GRID_GLOBAL_SEARCH_INPUT_ID };
