import React from 'react';

import { Chip } from '@mui/joy';

export type FilterSelctedCountProps = {
  getFilterCount: (parentKey: string) => number;
  keyName: string;
  postFix?: string;
};

const FilterSelctedCount: React.FC<FilterSelctedCountProps> = ({
  getFilterCount,
  keyName,
  postFix = '',
}) => {
  const count = getFilterCount(keyName);
  if (count === 0) return null;
  return (
    <Chip
      variant='outlined'
      sx={{
        background: (theme) => theme.palette.primary.outlinedHoverBg,
        borderColor: (theme) => theme.palette.primary.outlinedActiveBg,
        color: (theme) => theme.palette.text.icon,
      }}
    >
      {count} {postFix}
    </Chip>
  );
};

export default FilterSelctedCount;
