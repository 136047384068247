import { Button, Card, List, ListItem, Typography } from '@mui/joy';
import { Link } from 'react-router-dom';

import { track } from '../../services/analytics';
import { EVENT_SAFEGUARDS_PAGE, EVNTAPP_search_btn_click } from '../../services/analytics/events';

const InherentRisksSections = () => {
  const handleSearchClick = () => {
    track(EVNTAPP_search_btn_click, EVENT_SAFEGUARDS_PAGE);
  };
  return (
    <Card sx={{ mt: 3 }}>
      <Typography level='h2' fontSize={'xl2'}>
        Inherent Risks
      </Typography>
      <Typography level='body-md'>
        All project types carry different kinds of inherent environmental and social risks. Inherent
        risks encompass those naturally associated with an activity or process and exist regardless
        of efforts made to control or reduce them through management or mitigation strategies.
      </Typography>
      <Typography level='body-md'>
        Calyx Global conducts a screening of these risks based on a literature review and findings
        of similar projects. For each project type, we provide information on the areas where we
        identified key inherent environmental and social risks. Additionally, we highlight areas in
        which we have found broad challenges that transcend specific project activities and are
        applicable across carbon projects.
      </Typography>
      <Typography level='h4' fontSize={'xl'}>
        Information on the inherent risks of the following project types is accessible at all
        subscription levels from any project summary page:
      </Typography>
      <List sx={{ listStyleType: 'disc' }}>
        <ListItem>
          <Typography level='body-md' sx={{ display: 'list-item' }}>
            Afforestation / Reforestation
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-md' sx={{ display: 'list-item' }}>
            Avoided Deforestation (REDD)
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-md' sx={{ display: 'list-item' }}>
            Blue Carbon
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-md' sx={{ display: 'list-item' }}>
            Grassland Management
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-md' sx={{ display: 'list-item' }}>
            Improved Forest Management
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-md' sx={{ display: 'list-item' }}>
            Biodigesters
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-md' sx={{ display: 'list-item' }}>
            Landfill Gas
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-md' sx={{ display: 'list-item' }}>
            Cookstoves
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-md' sx={{ display: 'list-item' }}>
            Large-scale Wind Renewable
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-md' sx={{ display: 'list-item' }}>
            Large-scale Solar Renewable
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-md' sx={{ display: 'list-item' }}>
            Hydropower
          </Typography>
        </ListItem>
      </List>
      <Link to='/search' onClick={handleSearchClick}>
        <Button variant='solid' sx={{ width: 'fit-content' }}>
          Search for a project
        </Button>
      </Link>
    </Card>
  );
};

export default InherentRisksSections;
