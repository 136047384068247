import { setRecoil } from 'recoil-nexus';

import { authState, userState } from '../../commons/stores/auth';

import { getRefreshToken } from './endpoints';

import { axiosInstance } from './index';

const MAX_REFRESH_RETRIES = 2;

export const logout = () => {
  setRecoil(authState, null);
  setRecoil(userState, null);
};

export const refreshToken = async (authHeader: string) => {
  let attempt = 0;

  while (attempt < MAX_REFRESH_RETRIES) {
    try {
      const response = await axiosInstance.get(getRefreshToken, {
        headers: {
          Authorization: authHeader,
        },
      });
      return response.data;
    } catch (e) {
      attempt++;
      if (attempt >= MAX_REFRESH_RETRIES) {
        logout();
        throw e; // Throw the error after max retries
      }
    }
  }
};
