import { Box, Divider, Typography } from '@mui/joy';

import StyledHTML from '../../../../commons/components/StyledHTML';

type AttributeDetailsProps = {
  attribute_extra_content: string | null;
  display_name: string;
  long_description: string;
};

const AttributeDetails: React.FC<AttributeDetailsProps> = ({
  display_name,
  long_description,
  attribute_extra_content,
}) => {
  return (
    <>
      <Typography level='h4' color='primary'>
        {display_name}
      </Typography>
      <Divider
        sx={{ width: 1, mx: 'auto', background: (theme) => theme.palette['border']['border'] }}
      />
      <Box sx={{ overflowY: 'auto' }}>
        <StyledHTML
          dangerouslySetInnerHTML={{
            __html: long_description,
          }}
        />
        {attribute_extra_content && (
          <Typography level='body-md' color='primary' sx={{ mt: 3 }}>
            {attribute_extra_content}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default AttributeDetails;
