import { useState } from 'react';

import { Input, styled } from '@mui/joy';
import { IoEye, IoEyeOff } from 'react-icons/io5';

const HideIconContainer = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(0, 0.5),
  background: 'none',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

interface PropTypes {
  value: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
}

const PasswordInput = ({ value, onChangeHandler, name }: PropTypes) => {
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const handleToggleHidePassword = () => setHidePassword((prev) => !prev);

  return (
    <Input
      variant='outlined'
      fullWidth
      sx={{ padding: 'spacing(0 ,1)', height: '42px' }}
      type={hidePassword ? 'password' : 'text'}
      endDecorator={
        <HideIconContainer type='button' onClick={handleToggleHidePassword}>
          {hidePassword ? <IoEye size={20} /> : <IoEyeOff size={20} />}
        </HideIconContainer>
      }
      name={name}
      value={value}
      onChange={onChangeHandler}
    />
  );
};

export { PasswordInput };
