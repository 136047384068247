import { Card, IconButton, Skeleton, Stack, Typography } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { RatingCircle } from '../../../commons/components/RatingCircle';
import { projectState } from '../../../commons/stores/project';
import { track } from '../../../services/analytics';
import {
  EVENT_EXECUTIVE_SUMMARY_PAGE,
  EVNTAPP_GHG_rating_click,
  EVNTAPP_SDG_rating_click,
} from '../../../services/analytics/events';

const RatingCard = ({
  title,
  percentage,
  rating,
  path,
  type,
  sdgImpact,
  ...sxProps
}: {
  title: string;
  percentage: number;
  rating: string;
  path: string;
  type: 'sdg' | 'ghg';
  sdgImpact?: string;
} & SxProps) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { isLoading, projectData } = useRecoilValue(projectState);

  if (isLoading)
    return (
      <Card sx={{ position: 'relative', minHeight: '80px', p: 2, mb: 3 }}>
        <Skeleton width={'90%'} height={'60%'} />
      </Card>
    );

  const handleWidgetClick = () => {
    let event = '';
    if (type === 'ghg') {
      event = EVNTAPP_GHG_rating_click;
    }
    if (type === 'sdg') {
      event = EVNTAPP_SDG_rating_click;
    }
    track(event, EVENT_EXECUTIVE_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      page_url: `${pathname}${search}`,
    });
    navigate({ pathname: path, search });
  };

  return (
    <Card sx={{ mb: 3, gap: 0, p: (theme) => theme.spacing(3), ...sxProps }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} gap={2}>
          <RatingCircle size='md' percentage={percentage} rating={rating} type={type} />
          <Stack justifyContent={'center'}>
            <Typography
              sx={{ width: 'fit-content' }}
              fontSize={20}
              fontWeight={'xl'}
              color='primary'
              component={'div'}
            >
              {title}
            </Typography>
            {sdgImpact && (
              <Typography
                fontSize={'md'}
                fontWeight={'md'}
                sx={{ color: (theme) => theme.palette.text.secondary }}
                noWrap
              >
                {sdgImpact}
              </Typography>
            )}
          </Stack>
        </Stack>
        <IconButton variant='outlined' onClick={handleWidgetClick}>
          <ChevronRightIcon />
        </IconButton>
      </Stack>
    </Card>
  );
};
export default RatingCard;
