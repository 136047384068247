export const addBarBorderRadius = (optionParam: any) => {
  const option = { ...optionParam };
  for (let dataIndex = 0, moreValue = true; moreValue; dataIndex++) {
    moreValue = false;
    for (let i = option?.series?.length - 1; i >= 0; i--) {
      const data = option.series[i].data;
      if (dataIndex < data.length) {
        moreValue = true;
        const val = data[dataIndex];
        if (!isNaN(val) && val != null) {
          data[dataIndex] = {
            value: val,
            itemStyle: {
              barBorderRadius: [4, 4, 0, 0],
            },
          };
          break;
        }
      }
    }
  }
  return option;
};
