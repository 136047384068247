import { useMemo } from 'react';

import { Box, Skeleton, Stack, Typography, useColorScheme } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import ReactEcharts from 'echarts-for-react';
import { useParams } from 'react-router-dom';

import { BreakdownByTypes, ChartTypes } from '../..';
import { makeAuthenticatedGetRequest } from '../../../../../../services/axios';
import { retireeBreakdownPieChart } from '../../../../../../services/axios/endpoints';
import { getChartDataItemColor } from '../../utils';
import { sortChartItems } from '../utils';

export default function DoughnutChart({
  breakdown_type,
  chartType,
  include_non_rated_projects,
}: {
  breakdown_type: BreakdownByTypes;
  chartType: ChartTypes;
  include_non_rated_projects: boolean;
}) {
  const { retiree } = useParams();
  const { mode } = useColorScheme();

  const { data, isLoading } = useQuery({
    queryKey: [
      'retiree-breakdown-stats-pie-chart-data',
      retiree,
      breakdown_type,
      include_non_rated_projects,
    ],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(retireeBreakdownPieChart, {
        params: {
          retiree_name: retiree,
          breakdown_type,
          include_non_rated_projects,
        },
      }),
    select: (data) => data?.data?.data,
  });

  const dataForChart = useMemo(() => {
    const shouldUseOthersCategory = ['country', 'project_type'].includes(breakdown_type);
    const shouldOrderByLabel = ['ghg_rating', 'sdg_rating'].includes(breakdown_type);
    const formattedData =
      data
        ?.map(({ label, credits, projects, key }) => {
          return {
            name: label,
            value: Number(chartType === 'credits' ? credits : projects),
            key,
          };
        })
        .sort((a, b) => a?.key - b?.key)
        ?.map((item, index) => {
          return {
            ...item,
            color: getChartDataItemColor(breakdown_type, item?.key, mode === 'dark', index),
          };
        })
        .sort(sortChartItems(shouldOrderByLabel, breakdown_type)) || [];

    const top5 = formattedData
      ?.slice(0, shouldUseOthersCategory ? 5 : formattedData?.length)
      ?.filter((item) => Number(item?.value) > 0)
      ?.map((item) => {
        return { ...item, allData: formattedData };
      });

    const others = formattedData?.slice(5);

    const othersValue = others.reduce((sum, item) => sum + item.value, 0);

    if (others?.length > 0 && shouldUseOthersCategory) {
      if (others?.length === 1) {
        top5?.push(others?.[0]);
      } else {
        top5.push({
          name: 'Others',
          value: othersValue,
          allData: formattedData,
          color: '#9541BC',
        });
      }
    }
    return top5;
  }, [data, chartType, breakdown_type, mode]);

  const option = useMemo(() => {
    return {
      title: {},
      backgroundColor: 'transparent',
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        formatter: function (params) {
          const constituents = params.data.allData;
          const tooltipContent = constituents
            .map(
              (item) =>
                `<span style="display:inline-block;width:10px;height:10px;background-color:${item.color};margin-right:5px;border-radius:100%;"></span>${item.name} : <b>${Number(item.value).toLocaleString()}</b>`
            )
            .join('<br/>');
          return `${tooltipContent}`;
        },
      },
      series: [
        {
          color: dataForChart?.map(({ color }) => color),
          type: 'pie',
          radius: '50%',
          data: dataForChart,
          emphasis: {
            scale: false,
          },
          label: {
            width: '42px',
            overflow: 'breakAll',
            formatter: function (params: any) {
              const maxLength = 10;
              const rows: string[] = [];
              let currentLine = '';
              const words: string[] = params?.data?.name?.split(' ');

              for (let i = 0; i < words.length; i++) {
                if (
                  currentLine.length + words[i].length + (currentLine.length > 0 ? 1 : 0) <=
                  maxLength
                ) {
                  currentLine += (currentLine.length > 0 ? ' ' : '') + words[i];
                } else {
                  if (currentLine.length > 0) {
                    rows.push(currentLine);
                  }
                  currentLine = words[i];
                }
              }
              rows.push(currentLine);

              return rows.join('\n');
            },
          },
          labelLine: {
            length: 36,
          },
        },
      ],
    };
  }, [data, chartType, mode, breakdown_type, include_non_rated_projects]);

  return (
    <Box>
      {isLoading ? (
        <Stack height={`400px`} alignItems={'center'} flexDirection={'column'}>
          <Skeleton height={400} width={'95%'} />
        </Stack>
      ) : (
        <ReactEcharts option={option} style={{ height: `400px` }} theme={mode} />
      )}
      <Typography
        fontSize={'sm'}
        fontWeight={500}
        color={'primary'}
        textAlign={'center'}
        mt={'-24px'}
      >
        {chartType === 'credits' ? 'Total Credits Retired (tCO2e)' : 'Number of projects retired'}
      </Typography>
    </Box>
  );
}
