import { useMemo } from 'react';

import { Box, Divider, Stack, Typography, useColorScheme } from '@mui/joy';
import ReactEcharts from 'echarts-for-react';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../../../commons/stores/project';
import useIsLargeScreen from '../../../../../commons/utils/hooks/useIsLargeScreen';
import { HTMLRenderer } from '../HTMLRenderer';

import { getChartTypeCustomizations, getSeriesData, getTableData, getXAxis } from './constants';

interface PropTypes {
  chartConfigProp: any;
  chartData: any;
  isHidden: boolean;
  chartType: string;
  description: string;
  title: string;
  uniqueIdentifier: number;
}

export const Chart = ({
  chartConfigProp,
  chartData,
  isHidden = false,
  chartType,
  description,
  title,
  uniqueIdentifier,
}: PropTypes) => {
  const { mode } = useColorScheme();

  const { projectData } = useRecoilValue(projectState);

  const chartConfig = chartConfigProp?.chart_config;
  const tableConfig = chartConfig?.table_config;
  const tableData = chartData?.data || [];

  const rowData = useMemo(
    () => Object.assign(tableConfig?.rowData || [], tableData),
    [tableConfig, tableData]
  );

  const isLg = useIsLargeScreen();

  const option = useMemo(() => {
    return Object.assign({}, chartConfig || {}, {
      backgroundColor: 'transparent',
      xAxis: getXAxis(chartConfig, rowData, chartType),
      dataset: getTableData(rowData, chartType),
      series: getSeriesData(
        chartConfig,
        rowData,
        chartType,
        projectData?.projectCountry,
        projectData?.projectState
      ),
      ...getChartTypeCustomizations(chartType, isLg),
    });
  }, [chartConfig, rowData, projectData, chartType, isLg]);

  if (isHidden) return null;
  return (
    <Stack>
      <Typography level='h4' mt={2}>
        {title}
      </Typography>
      <Box display={'grid'} gridTemplateColumns={isLg ? '4fr 0fr 2fr' : '1fr'} gap={isLg ? 6 : 2}>
        <ReactEcharts
          option={option}
          style={{ height: '360px' }}
          theme={mode}
          key={uniqueIdentifier}
        />
        <Divider orientation='vertical' />
        <Typography fontSize={'md'} color='neutral'>
          <HTMLRenderer colorType='secondary' dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      </Box>
    </Stack>
  );
};
