import { BreakdownByTypes } from '..';

export const sortChartItems = (shouldOrderByLabel: boolean, breakdown_type: BreakdownByTypes) => {
  return (a, b) => {
    if (shouldOrderByLabel) {
      if (breakdown_type === 'sdg_rating') {
        return Number(b?.name?.split('+')?.[1]) - Number(a?.name?.split('+')?.[1]);
      }
      if (breakdown_type === 'ghg_rating') {
        const val1 = a?.name?.split('+');
        const val2 = b?.name?.split('+');
        const baseOrder = val1[0].localeCompare(val2[0]);
        if (baseOrder === 0) {
          return val2.length - val1.length;
        }
        return baseOrder;
      }
      return b?.name - a?.name;
    } else {
      return Number(b?.value) - Number(a?.value);
    }
  };
};
