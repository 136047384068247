import { extendTheme } from '@mui/joy';

import { borderRadius } from './borderRadius';
import { brand } from './brand';
import { dark } from './dark';
import { light } from './light';
import { typography } from './typography';

export const theme = extendTheme({
  colorSchemes: {
    light: { palette: light },
    dark: { palette: dark },
  },
  fontFamily: {
    body: 'Lato',
  },
  fontWeight: {
    xs: 300,
    sm: 400,
  },
  typography,
  radius: borderRadius,
  components: {
    JoyTypography: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          fontSize: (() => {
            switch (ownerState.component) {
              case 'h1':
                return '40px';
              case 'h2':
                return '36px';
              case 'h4':
                return '24px';
                return;
            }
          })(),
          fontWeight: (() => {
            switch (ownerState.level) {
              case 'body-xs':
                return 400;
            }
          })(),
          color: ownerState.color === 'primary' ? theme.palette.text.primary : '',
        }),
      },
    },
    JoyAvatar: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: ownerState.variant === 'outlined' ? theme.palette.text.secondary : 'inherit',
          borderColor: ownerState.variant === 'outlined' ? theme.palette.text.icon : 'inherit',
        }),
      },
    },
    JoySelect: {
      defaultProps: {
        indicator: null,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ':hover': {
            background:
              ownerState.variant === 'outlined' ? theme.palette.primary.outlinedColor : 'inherit',
            borderColor:
              ownerState.variant === 'outlined'
                ? theme.palette.primary.outlinedHoverBorder
                : 'inherit',
          },
        }),
      },
    },
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          boxShadow: 'none',
          color: ownerState.variant === 'outlined' ? theme.palette.text.secondary : 'inherit',
          '--Input-focusedHighlight':
            ownerState.variant === 'outlined'
              ? `${brand.primary.dark['800']} !important`
              : 'inherit',
          '&.Mui-focused': {
            color: ownerState.variant === 'outlined' ? theme.palette.text.primary : 'inherit',
          },
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          userSelect: 'none',
          fontWeight: ownerState.variant === 'outlined' ? theme.fontWeight.sm : 'inherit',
          color:
            ownerState.variant === 'solid'
              ? theme.palette.common.white
              : theme.palette.text.primary,
          ...(ownerState.variant === 'plain' && {
            '&:hover': {
              color: theme.palette.text.primary,
              background: 'transparent',
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            [theme.getColorSchemeSelector('dark')]: {
              background: theme.palette.neutral[800],
            },
            [theme.getColorSchemeSelector('light')]: {
              background: theme.palette.neutral[50],
            },
          }),
        }),
      },
    },
    JoyMenuButton: {
      styleOverrides: {
        root: () => ({
          '&:hover': {
            background: 'transparent',
          },
        }),
      },
    },
    JoyChip: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          color: (() => {
            if (ownerState.variant === 'outlined') {
              return theme.palette.text.primary;
            }
            if (ownerState.variant === 'solid') {
              return theme.palette.primary.solidColor;
            }
            return 'inherit';
          })(),
        }),
      },
    },
    JoyTab: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '&:hover': {
            color: theme.palette.text.secondary,
          },
          '&:active, &:focus': {
            color: theme.palette.text.primary,
          },
          '::after': {
            background: ownerState.selected
              ? `${theme.palette.primary.outlinedActiveBg} !important`
              : null,
          },
          '& > p': {
            color: `${ownerState.selected ? theme.palette.text.primary : theme.palette.text.secondary} !important`,
            fontWeight: theme.fontWeight.md,
          },
        }),
      },
    },
    JoySkeleton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '::before': {
            background: `${theme.palette.background.level2} !important`,
          },
        }),
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          color: ownerState.variant === 'outlined' ? theme.palette.text.primary : 'inherit',
          '&:hover': {
            background: ownerState.variant === 'plain' ? 'transparent' : 'inherit',
            borderColor:
              ownerState.variant === 'outlined'
                ? theme.palette.primary.outlinedHoverBorder
                : 'inherit',
            color: 'inherit',
          },
        }),
      },
    },
    JoyButtonGroup: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          color: ownerState.variant === 'outlined' ? theme.palette.text.primary : 'inherit',
          '&:active, &:focus': {
            color: ownerState.variant === 'outlined' ? theme.palette.common.white : 'inherit',
          },
        }),
      },
    },
    JoyMenu: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingY: 0.5,
          border: `1px solid ${theme.palette.primary.outlinedBorder}`,
          boxShadow: theme.shadow.md,
        }),
      },
    },
    JoyMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            background: `${theme.palette.primary.outlinedHoverBg} !important`,
          },
        }),
      },
    },
    JoyRadio: {
      styleOverrides: {
        radio: ({ theme, ownerState }) => ({
          background: ownerState.checked
            ? theme.palette.primary.outlinedActiveBg
            : theme.palette.background.surface,
        }),
        icon: ({ theme, ownerState }) => ({
          background: ownerState.checked
            ? theme.palette.common.white
            : theme.palette.background.surface,
        }),
      },
    },
    JoyAccordionSummary: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '&:hover': {
            background:
              ownerState.variant === 'plain' ? theme.palette.primary.outlinedHoverBg : 'inherit',
            borderRadius: ownerState.variant === 'plain' ? theme.radius.sm : 'inherit',
          },
          '&:active, &:focus': {
            border:
              ownerState.variant === 'plain'
                ? `1px solid ${theme.palette.primary.outlinedActiveBg}`
                : 'inherit',
          },
        }),
      },
    },
    JoyCard: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          padding: theme.spacing(3),
          borderColor: theme.palette['border']['border'],
          border:
            ownerState.variant === 'soft'
              ? `1px solid ${theme.palette.primary.outlinedBorder}`
              : '',
        }),
      },
    },
    JoyModalDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: 'none',
          borderColor: theme.palette['border']['border'],
        }),
      },
    },
  },
});
