import { Cell } from '../../../../commons/components/Table/types';

export const columns = [
  [
    {
      key: 'project_name',
      name: 'Project Name',
      isPinned: true,
      isSortable: true,
      cellStyle: {
        width: '256px',
        position: 'sticky',
        left: 0,
        zIndex: 11,
        borderRight: '2px solid var(--TableCell-borderColor)',
      },
    },
    {
      key: 'project_id',
      name: 'Project ID',
      isSortable: true,
      cellStyle: {
        width: '86px',
      },
      contentStyle: {
        justifyContent: 'center',
      },
    },
    {
      key: 'duration_vintage',
      name: 'Duration/Vintage',
      isSortable: true,
      cellStyle: {
        width: '128px',
      },
      contentStyle: {
        textAlign: 'start',
      },
    },
    {
      key: 'retirement_years',
      name: 'Retirement Years',
      isSortable: true,
      cellStyle: {
        width: '184px',
      },
      contentStyle: {
        textAlign: 'start',
      },
    },
    {
      key: 'project_category',
      name: 'Project Category',
      isSortable: true,
      cellStyle: {
        width: '176px',
      },
      contentStyle: {
        textAlign: 'start',
      },
    },
    {
      key: 'project_type',
      name: 'Project Type',
      isSortable: true,
      cellStyle: {
        width: '192px',
      },
      contentStyle: {
        textAlign: 'start',
      },
    },
    {
      key: 'region',
      name: 'Region',
      isSortable: true,
      cellStyle: {
        width: '112px',
      },
      contentStyle: {
        textAlign: 'start',
      },
    },
    {
      key: 'country',
      name: 'Country',
      isSortable: true,
      cellStyle: {
        width: '112px',
      },
      contentStyle: {
        textAlign: 'start',
      },
    },
    {
      key: 'ghg_risk_rating_grade',
      name: 'GHG Rating',
      isSortable: true,
      cellStyle: {
        width: '104px',
      },
      contentStyle: {
        textAlign: 'start',
      },
    },
    {
      key: 'sdg_impact_rating_grade',
      name: 'SDG Rating',
      isSortable: true,
      cellStyle: {
        width: '104px',
      },
      contentStyle: {
        textAlign: 'start',
      },
    },
    {
      key: 'confirmed_sdg',
      name: 'Confirmed SDGs',
      isSortable: true,
      cellStyle: {
        width: '124px',
      },
      contentStyle: {
        textAlign: 'start',
      },
    },
    {
      key: 'sdg_certificate',
      name: 'SDG Certificates',
      isSortable: true,
      cellStyle: {
        width: '184px',
      },
      contentStyle: {
        textAlign: 'start',
      },
    },
    {
      key: 'project_attribute',
      name: 'Project Attributes',
      isSortable: true,
      cellStyle: {
        width: '184px',
      },
      contentStyle: {
        textAlign: 'start',
      },
    },
    {
      key: 'total_volume',
      name: '# Credits Retired (tCO2e)',
      isSortable: true,
      cellStyle: {
        width: '184px',
        position: 'sticky',
        right: 0,
        zIndex: 11,
        paddingRight: '16px',
        borderLeft: '3px solid var(--TableCell-borderColor)',
      },
      contentStyle: {
        justifyContent: 'end',
        textAlign: 'end',
      },
      isPinned: true,
    },
  ],
] as Cell[][];

export const cellRenderOrder = [
  'project_name',
  'project_id',
  'duration_vintage',
  'retirement_years',
  'project_category',
  'project_type',
  'region',
  'country',
  'ghg_risk_rating_grade',
  'sdg_impact_rating_grade',
  'confirmed_sdg',
  'sdg_certificate',
  'project_attribute',
  'total_volume',
];

export const emptyContent = {
  duration_vintage: 'Unknown',
  project_category: 'Unknown',
  project_type: 'Unknown',
  region: 'Unknown',
  country: 'Unknown',
  ghg_risk_rating_grade: 'Not Rated',
  sdg_impact_rating_grade: 'Not Rated',
  confirmed_sdg: 'None',
  sdg_certificate: 'Unknown',
  project_attribute: 'Unknown',
  retirement_years: 'Unknown',
};
