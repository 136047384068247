import { useEffect } from 'react';

import { MdError } from 'react-icons/md';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
  useLocation,
} from 'react-router-dom';

import MessagePage from './commons/components/MessagePage';
import { ProtectedRoute } from './commons/components/ProtectedRoute';
import { Home, Login } from './pages';
import { Contact } from './pages/contact';
import Blog from './pages/insights/blog';
import BlogDescription from './pages/insights/components/description';
import PinnedBlogDescription from './pages/insights/components/description/PinnedBlogDescription';
import ResourceDescription from './pages/insights/components/description/resource';
import DonnasNotes from './pages/insights/donnaNotes';
import Resource from './pages/insights/Resources';
import { ForgotPasswordForm } from './pages/login/components/forgotPasswordForm';
import { LoginForm } from './pages/login/components/loginForm';
import { ResetPasswordForm } from './pages/login/components/resetPaswordForm';
import Profile from './pages/profile';
import Project from './pages/project';
import EnvironmentalAndSocialRisks from './pages/project/ess_rating';
import ExecutiveSummary from './pages/project/executive_summary';
import GhgRating from './pages/project/ghg_rating';
import ProjectComparisonPage from './pages/project/project_comparison';
import RelatedContent from './pages/project/related_content';
import SDGRating from './pages/project/sdg_rating';
import RetireeInsightsDrillDown from './pages/retireeDrillDown';
import { RetireeInsights } from './pages/retireeInsights';
import Safeguards from './pages/safegaurds';
import { SearchPage } from './pages/search';
import { WhereToBuy } from './pages/whereToBuy';
import { page } from './services/analytics';
import Sentry from './services/sentry';

const AppLayout = () => {
  const location = useLocation();
  useEffect(() => {
    page();
  }, [location]);

  return (
    <Sentry.ErrorBoundary
      fallback={
        <MessagePage
          title="Oops, that's our bad!"
          description="We're not exactly sure what happened, rest assured we've been notified about this issue and this will be fixed soon!"
          Icon={MdError}
          hasAction
        />
      }
    >
      <ScrollRestoration />
      <Outlet />
    </Sentry.ErrorBoundary>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: (
      <MessagePage
        title='Page not found'
        description='The page you are looking for doesn’t exist or has been moved.'
        hasAction
      />
    ),
    children: [
      {
        path: '/',
        element: <ProtectedRoute />,
        children: [
          {
            path: `/`,
            element: <Home />,
            // update error page
          },
          {
            path: 'blog_detail_dist',
            element: <PinnedBlogDescription />,
          },
          {
            path: `/`,
            children: [
              {
                path: 'blog',
                element: <Blog />,
              },
              {
                path: 'blog-detail',
                element: <BlogDescription />,
              },
              {
                path: 'resource',
                element: <Resource />,
              },
              {
                path: 'resource-detail',
                element: <ResourceDescription />,
              },
              {
                path: 'note',
                element: <DonnasNotes />,
              },
            ],
            // update error page
          },
          {
            path: `/search`,
            element: <SearchPage />,
            // update error page
          },
          {
            path: `/profile`,
            element: <Profile />,
            // update error page
          },
          {
            path: `/safeguards`,
            element: <Safeguards />,
            // update error page
          },
          {
            path: `/`,
            element: <Project />,
            children: [
              { path: 'executive_summary', element: <ExecutiveSummary /> },
              { path: 'ghg_rating', element: <GhgRating /> },
              { path: 'ess_rating', element: <EnvironmentalAndSocialRisks /> },
              { path: 'related_content', element: <RelatedContent /> },
              { path: 'project_comparison', element: <ProjectComparisonPage /> },
              { path: 'sdg_rating', element: <SDGRating /> },
            ],
          },
          {
            path: `/where_to_buy`,
            element: <WhereToBuy />,
            // update error page
          },
          {
            path: 'retirement_insights',
            element: <RetireeInsights />,
          },
          {
            path: 'retirement_insights/:retiree',
            element: <RetireeInsightsDrillDown />,
          },
          {
            path: 'contact',
            element: <Contact />,
          },
        ],
      },
      {
        path: `/login`,
        element: <Login />,
        children: [
          {
            path: '',
            element: <LoginForm />,
          },
          {
            path: 'forgot-password',
            element: <ForgotPasswordForm />,
          },
          {
            path: 'reset-password',
            element: <ResetPasswordForm />,
          },
        ],
      },
    ],
  },
]);

export const Routes = () => {
  return <RouterProvider router={router} />;
};
