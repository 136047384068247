import { Button, Card, Link, Typography } from '@mui/joy';
import { TbBulb } from 'react-icons/tb';

import { track } from '../../services/analytics';
import {
  EVENT_SAFEGUARDS_PAGE,
  EVNTAPP_esr_report_btn_click,
  EVNTAPP_ess_report_link,
  EVNTAPP_ess_sample_report_link,
  EVNTAPP_sample_report_link_click,
} from '../../services/analytics/events';
import { assetBaseUrl } from '../../services/axios/endpoints';

interface ProjectSpecificRisksSectionProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOnProjectPage?: boolean;
}

const ProjectSpecificRisksSection: React.FC<ProjectSpecificRisksSectionProps> = ({
  setIsModalOpen,
  isOnProjectPage = false,
}) => {
  const handleSampleReportClick = () => {
    track(
      isOnProjectPage ? EVNTAPP_ess_sample_report_link : EVNTAPP_sample_report_link_click,
      EVENT_SAFEGUARDS_PAGE
    );
  };
  const handleReportClick = () => {
    track(
      isOnProjectPage ? EVNTAPP_ess_report_link : EVNTAPP_esr_report_btn_click,
      EVENT_SAFEGUARDS_PAGE
    );
    setIsModalOpen(true);
  };
  return (
    <Card sx={{ mt: 3 }}>
      <Typography level='h2' fontSize={'xl2'}>
        Project-specific Risks
      </Typography>
      <Typography level='body-md'>
        Risks are not uniform and may be significantly influenced by the context in which the
        project is implemented. At Calyx Global, we work to understand the risks associated with
        carbon projects and make you aware, so you can better protect your reputation with
        high-integrity carbon credit decisions. 
      </Typography>
      <Typography level='body-md'>
        Our Project ESR Reports complement our Inherent Risk Screening by providing a deep-dive
        analysis of a particular project of your choice. When you request a Project ESR Report, you
        will receive a high-level assessment of the project-specific risks benchmarked against
        industry norms and categorized into different risk levels (i.e., low, moderate, substantial,
        and high). 
      </Typography>

      <Link
        onClick={handleSampleReportClick}
        sx={{ textDecoration: 'underline' }}
        href={`${assetBaseUrl}/public/pdf/VCS2600+Oeste+de+Caucaia+Landfill+Project_ESR+sample+report.pdf`}
        target='_blank'
      >
        View an example Environmental and Social Risk (ESR) Project Report
      </Link>

      <Button
        sx={{
          background: (theme) => theme.palette['linearGradient']['special'],
          width: 'fit-content',
        }}
        size='sm'
        startDecorator={<TbBulb />}
        onClick={handleReportClick}
      >
        Request an ESR Report
      </Button>
    </Card>
  );
};

export default ProjectSpecificRisksSection;
